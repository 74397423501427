import React, { useEffect } from "react";
import { Carousel } from "react-bootstrap";
// import { listBanners } from '../../src/Actions/ProductActions';
// import { useDispatch, useSelector } from "react-redux";
// import { IMG_GLOBAL } from "../Config/Config";
import Img1 from '../Assets/health_friendly.png';
import Img2 from '../Assets/recycle.png';
import Img3 from '../Assets/sustain_able.png';

const HCarousel = () => {
  // const bannersList = useSelector((state) => state.bannersList);
  // const { products } = bannersList;
  // const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(listBanners());
  }, []);
  
  return (
    <Carousel variant='dark' className='carousel_disable' controls={false} indicators={false} style={{width:'70%',}}>
    <Carousel.Item>
        <img
            className="d-block w-100"
              src={Img1}
            alt="First slide"
        />
    </Carousel.Item>
    <Carousel.Item>
        <img
            className="d-block w-100"
              src={Img2}
            alt="Third slide"
        />
    </Carousel.Item>
    <Carousel.Item>
        <img
            className="d-block w-100"
              src={Img3}
            alt="Third slide"
        />
    </Carousel.Item>
</Carousel>
  );
};

export default HCarousel;