import React, { useState, useEffect, useRef } from "react";
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import { GLOBAL_DOMAIN, Api_Headers, PAYTM_SCRIPT_ID } from '../../../Config/Config';
import { Card } from 'primereact/card';
import Add_icon_white from '../../../Assets/Atum_Icons/Add_icon_white.svg';
import Deduction_arrow_icon from '../../../Assets/Atum_Icons/Deduction_arrow_icon.svg';
import Credited_arrow_icon from '../../../Assets/Atum_Icons/Credited_arrow_icon.svg';

const Transaction = () => {
    const [userTranstions, setuserTransactions] = useState({});
    const [userData, setUserData] = useState('');
    const user_data = JSON.parse(window.localStorage.getItem('User_data'));

    useEffect(() => {
        window.scrollTo(0, 0)
        getUserData();
        getUserTransactions();
        //initializeTPL(PAYTM_SCRIPT_ID);
    }, []);
    const getUserData = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
        }).then((res) => {
            setUserData(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const getUserTransactions = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/usertransactions?type=list&limit=100&userId=' + user_data.data.userData.ID + '&transactionMode=ATUM_WALLET&transactionType=DEBIT',
        }).then((res) => {
            setuserTransactions(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <section className="container">
            <Row>
                <Col xs={12} md={6}>
                    <Card className='rounded bg_color_head position-relative p-2' style={{ height: '250px' }}>
                        <div className="border-bottom border-dark">
                            <p className="text-white">My Transactions</p>
                        </div>
                        <div className="position-absolute bottom-10 w-90">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <div>
                                        <p className="m-0 text-white">Total Spent</p>
                                    </div>
                                    <div>
                                        <h4 className="m-0 text-white">₹ {parseFloat(userTranstions.TOTAL_AMOUNT_SPENT).toFixed(2)}</h4>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <p className="m-0 text-white">Total Saved</p>
                                    </div>
                                    <div>
                                        <h4 className="m-0 text-white">₹ {parseFloat(userTranstions.TOTAL_AMOUNT_SAVED).toFixed(2)}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <div>
                        <div>
                            <h4>My Transaction</h4>
                        </div>
                        <hr />
                        {userTranstions && userTranstions.rows?.length > 0 ?
                        <React.Fragment>
                        {userTranstions && userTranstions?.rows?.map((items, index) => {
                            return (
                                <div className="border-last my-3">
                                    <div className="d-flex align-items-center">
                                        {items.PAYMENT_TYPE === 'CREDIT' ?
                                            <div className="me-1">
                                                <img src={Credited_arrow_icon} />
                                            </div> :
                                            <div className="me-1">
                                                <img src={Deduction_arrow_icon} />
                                            </div>
                                        }
                                        <div className="lh-1">
                                            {items.PAYMENT_TYPE === 'CREDIT' ?
                                                <p className="m-0">id #{items.TRANSACTION_ID}</p> :
                                                <p className="m-0">Purchased id #{items.TRANSACTION_ID}</p>
                                            }
                                            <small className="text-grey fs-12">{items.CREATED_AT}</small>
                                        </div>
                                    </div>
                                    <div className="ms-4 mt-3">
                                        {items.PAYMENT_TYPE === 'CREDIT' ?
                                            <p className="ps-1">Added: ₹ <span className="color_head">{items.ORDER_AMOUNT}</span></p> :
                                            <p className="ps-1">Spent: ₹ {items.ORDER_AMOUNT}</p>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div>
                                <h4 className="text-grey">No Transactions</h4>
                            </div>
                        </React.Fragment>
                    }
                    </div>
                </Col>
            </Row>
        </section>
    )
}
export default Transaction