import React, {useEffect} from 'react';
import Table from 'react-bootstrap/Table';

export default function Exchange(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div className='mt-5 pt-5 container'>
            <h3 style={{textAlign: 'center'}}>Exchanges and Returns Policy</h3>
            <p>We are responsible for what we sell. If you are not completely satisfied with your purchase, we offer easy Return & Exchange Policy.</p>
            <p>We allow Return & Exchange of the items purchased from Atum Life within the specified days from the date of delivery as mentioned below.</p>
            <p>Return & Exchange is allowed provided the product is in original condition i.e. unused, unwashed, unaltered, untampered & in saleable condition with original tags, invoice & packaging intact (For e.g. Shoes to be sent along with the brand shoe box).</p>
            <p>All products received will undergo quality check after the product has been received at our Warehouse/Atum Life Store. If the product picked does not pass the quality check, we shall ship it back to you.</p>
            <p>Please note that all exchanges are subject to stock availability.</p>
            <div style={{width: '60vw'}}>
                <Table striped bordered size="sm">
                    <thead>
                        <tr>
                            <th style={{fontWeight: 'bold', fontSize: '16px'}}>Category</th>
                            <th style={{fontWeight: 'bold', fontSize: '16px'}}>Return & Exchange Period</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Apparels (Clothing)</td>
                            <td>14 days of easy Return & Exchange from the date of delivery</td>
                        </tr>
                        <tr>
                            <td>Electronic</td>
                            <td>10 days of easy Return & Exchange from the date of delivery</td>
                        </tr>
                        <tr>
                            <td>Foot ware</td>
                            <td>3 days of easy Return & Exchange from the date of delivery</td>
                        </tr>
                        <tr>
                            <td>Beauty/Cosmetic</td>
                            <td>We do not accept Return or Exchange</td>
                        </tr>
                        <tr>
                            <td>Groceries</td>
                            <td>We do not accept Return or Exchange</td>
                        </tr>
                        <tr>
                            <td>Homeware (Décor, Dinning & Kitchen)</td>
                            <td>We do not accept Return or Exchange</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <h4>Additionally, the given below guidelines are also applicable</h4>
            <p>We do not accept any Return & Exchange of any altered garments.</p>
            <p>We do not accept any Return & Exchange GROCERIES & INSTANT EATABLES for hygiene purpose.</p>
            <p>If any product is not received in good condition, is damage or defective, or if product delivered is different, you may return the product in unused, in its original condition with original tags and original packaging (along with boxes if any. For e.g. Shoes to be sent along with the brand shoe box) within the specified days from the date of delivery as mentioned above or as per the details mentioned on the Product Description Page.</p>
            <p>Any Return & Exchange for reasons other than the above, the shipping charges if any, will not be refunded.</p>
            <h4>You may Return / Exchange Merchandise to us in the following ways :</h4>
            <h4>1. Return & Exchange through Self-Courier.</h4>
            <p>If you wish to return or exchange any product by yourself, you may return the product in unused, in its original condition with original tags and original packaging (along with boxes if any. For e.g. Shoes to be sent along with the brand shoe box) within the specified days from the date of delivery as mentioned above or as per the details mentioned on the Product Description Page.</p>
            <p>All Return/Exchange requests through either of the above modes will be initiated only after the product has been received at our Warehouse/Atum Life Store and passes the physical quality check. We may take approximately 7-10 working days to complete your request. All exchange requests are subject to stock availability.</p>
            <p>If the product picked does not pass the quality check, Return & exchange policy will not be applicable in such cases we shall ship the product back to you.</p>
            <p>For Returns, the refund is initiated as per the refund mode selected at the time of raising the request.</p>
            <div style={{width: '60vw'}}>
                <Table striped bordered size="sm">
                    <thead>
                        <tr>
                            <th style={{fontWeight: 'bold', fontSize: '16px'}}>Mode of Payment</th>
                            <th style={{fontWeight: 'bold', fontSize: '16px'}}>Credit Reflection Timeline</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Net Banking</td>
                            <td>2-10 working days from the day of refund processed</td>
                        </tr>
                        <tr>
                            <td>Wallets</td>
                            <td>0-3 working days from the day of refund processed</td>
                        </tr>
                        <tr>
                            <td>UPI</td>
                            <td>2-7 working days from the day of refund processed</td>
                        </tr>
                        <tr>
                            <td>Credit or Debit card</td>
                            <td>7-10 working days from the day of refund processed</td>
                        </tr>
                        <tr>
                            <td>Atum Life Wallet/Gift Card /E-Gift voucher</td>
                            <td>on the same day of refund processed</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <p>Refund for eligible Cash on Delivery order will be done through online transfer in the bank account as per details submitted by you at the time of initiating Return. We request you to take utmost care while submitting the bank details. You shall receive credit into your account within 2-3 working days from the day of refund processed.</p>
            <p>The credit reflection totally depends on the timeline specified by the bank. In the case of nationalized banks, you may expect further delay for credit reflection into your account.</p>
            <p>For Exchange, a new order number will be generated and you would be informed on order confirmation through separate detailed email.</p>
            <h4>2. Visit to Atum Life Store.</h4>
            <p>Most items are returnable in our stores, you may visit any Atum Life store in Hyderabad, and return the merchandise in original condition (unused, unwashed, unaltered, untampered & in saleable condition) with original tags, invoice & original packaging intact & in stipulated time & days.</p>
        </div>
    );
};