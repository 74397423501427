import React, { useEffect, useState } from "react";
import axios from "axios";
import { GLOBAL_DOMAIN, Api_Headers } from '../../Config/Config';
import { Container, Row, Col } from "react-bootstrap";
import Contact_us_image from '../../Assets/images/Contact_us.png';
import './Footer.css';
import phone_icon_black from '../../Assets/Atum_Icons/phone_icon_black.svg';
import mail_icon_black from '../../Assets/Atum_Icons/mail_icon_black.svg';

const ContactScreen = () => {

  const [Store_List, setStore_List] = useState([]);

  useEffect(() => {
    getStoredata();
    window.scrollTo(0, 0);
  }, []);

  const getStoredata = () => {
    axios({
      method: 'GET',
      headers: Api_Headers,
      url: GLOBAL_DOMAIN + '/v1/stores?type=list&limit=20'
    }).then((res) => {
      setStore_List(res.data.rows);
    });
  }
  return (
    <section className="container">
      <div>
        <div className="text-center">
          <h2 className="fw-bold">Still need help?</h2>
        </div>
        <div className="my-4">
          <Row>
            <Col sx={12} md={6}>
              <div className="text-center">
                <img src={Contact_us_image}/>
              </div>
            </Col>
            <Col sx={12} md={6} className="position-relative">
              <div className="position-absolute top-50 start-0 translate-middle-y lh-lg">
                <div><span>Contact us</span></div>
                <div className="my-2"> <img src={phone_icon_black} height={22} width={22}/> <span className="ms-2">8341122922 </span></div>
                <div><img src={mail_icon_black} height={22} width={22}/> <span className="ms-2">info@atumlife.com</span></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
    // <div className="contact-head">
    //   <h1 className="contact-h1">Contact Us</h1>
    //   <p className="contact-p m-0">Share your query and ideas with us!</p>
    //   <p className="contact-p">Mail us : info@atumlife.com</p>
    //   <Container className="contact-contain">
    //     <Row>
    //       {Store_List.map(item => {
    //         return (
    //           <React.Fragment>
    //             <Col className="contact-end contact-item" xs={12} md={2}>{item.CITY}</Col>
    //             <Col className="contact-end contact-item" xs={12} md={6}>{item.STORE_NAME}, {item.ADDRESS_LINE1}, {item.ADDRESS_LINE2}, {item.CITY}, {item.COUNTRY_NAME}, {item.PINCODE}.</Col>
    //             <Col className="contact-end contact-item" xs={12} md={2}>  Contact: (+91) {item?.CONTACT_NUMBER}
    //               <iframe width={'150px'} src = {'https://maps.google.com/maps?q='+ item.LATITUDE + ',' + item.LONGITUDE + '&embedded=true&hl=es;z=14&amp;output=embed'} frameborder="0" allowfullscreen></iframe>
    //             </Col>
    //           </React.Fragment>
    //         )
    //       })}
    //     </Row>
    //   </Container>
    // </div>
  );
};

export default ContactScreen;