import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Skeleton } from 'primereact/skeleton';
import { Carousel } from "react-bootstrap";
import { useHistory, Link } from 'react-router-dom';
import { signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import "./Login.css";
import "../../App.css";
import Img1 from '../../Assets/health_friendly.png';
import Img2 from '../../Assets/recycle.png';
import Img3 from '../../Assets/sustain_able.png';
import Logo from '../../Assets/logo.svg';
import Glogo from '../../Assets/google.png';
import Flogo from '../../Assets/facebook.png';
import { auth } from '../../firebase-config';
import { Password } from 'primereact/password';
import { GLOBAL_DOMAIN, Api_Headers } from "../../Config/Config";
import { InputText } from 'primereact/inputtext';
import OtpInput from 'react-otp-input';
import { Dialog } from 'primereact/dialog';
import Signup from '../Signup/signup';

const Login = (props) => {
    const [error, seterror] = useState('');
    const [fdata, setdata] = useState('');
    const [button, setButton] = useState(true);
    const history = useHistory();
    const [loadImg, setLImg] = useState(false);
    const [Password_status, setPassword] = useState(false);
    const [login_button, setlogin_button] = useState(true)
    const provider1 = new GoogleAuthProvider();
    const provider2 = new FacebookAuthProvider();
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [OTP_Number, setOTP_Number] = useState('')
    const [login_flag, setlogin_flag] = useState(true)
    const [Signup_flag, setSignup_flag] = useState(false)
    const validationSchema = Yup.object().shape({
        mobile_number: Yup.string()
            .required('Phone Number/ email ID is required'),
            // .max(10, 'Enter valid Phone Number')
            // .min(10, 'Enter valid Phone Number')
            // .matches(/^[6-9]\d{9}$/, 'Enter valid Phone Number'),
        // .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,'Enter valid email address'),
        user_password: Yup.string()
        .required('Password is required'),
        Otp_valid: Yup.string()
            .required('Otp is required')
            .max(6, 'Enter valid OTP')
            .min(6, 'Enter valid OTP'),
    });

    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const handlenumTextInput = (number) => {
        var mailfilter =
            /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
        if (mailfilter.test(number)) {
            setPassword(true);
            setlogin_button(true);
        } else {
            setPassword(false);
            setlogin_button(false);
        }
        // setNumber(number);
    };

    const userlogin = () => {
        axios({
            method: 'POST',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/login',
            data: {
                EMAIL: getValues('mobile_number'),
                PASSWORD: getValues('user_password')
            }
        }).then((res) => {
            // console.log('Login Data :', res.data);
            localStorage.setItem('User_data', JSON.stringify(res.data));
            seterror('');
            colse_Login();
            // history.push('/home');
            // window.location.reload();
            props.flag_Disable();
        }).catch((err) => {
            console.log(err);
            seterror(err.response.data.message);
        });
    };

    const getOtp = () => {
        axios({
            method: 'post',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/sendotp',
            data: {
                MOBILE_NUMBER: getValues('mobile_number')
            }
        }).then((res) => {
            setButton(false);
            seterror('');
            sendOTP();
        }).catch((err) => {
            console.log(err);
            seterror(err.response.data.message);
        });
    };

    const verifyOtp = () => {
        // console.log(JSON.stringify(data, null, 2));
        axios({
            method: 'post',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/verifyotp',
            data: {
                MOBILE_NUMBER: getValues('mobile_number'),
                OTP: OTP_Number
            }
        }).then((res) => {
            // console.log('Login Data :', res.data);
            localStorage.setItem('User_data', JSON.stringify(res.data));
            // sessionStorage.setItem('token', res.data.token);
            colse_Login();
            // history.push('/home');
            // window.location.reload();
            props.flag_Disable();
        }).catch((err) => {
            console.log(err);
            // seterror(err.response.data.message);
        });
    };

    const GoogleLogin = () => {
        signInWithPopup(
            auth, provider1
        ).then((res) => {
            let obj = null; const credential = GoogleAuthProvider.credentialFromResult(res);

            obj = {
                'FIRST_NAME': res._tokenResponse.firstName,
                'LAST_NAME': res._tokenResponse.lastName,
                'EMAIL': res._tokenResponse.email,
                'MOBILE_NUMBER': res.user.phoneNumber,
                'TOKEN': res._tokenResponse.idToken,
            };

            axios({
                method: 'post',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/endusers/webgmaillogin',
                data: obj,
            }).then((res) => {
                // console.log(res);
                localStorage.setItem('User_data', JSON.stringify(res.data));
                colse_Login()
                // history.push('/home');
                props.flag_Disable();
            }).catch((err) => {
                console.log(err);
            });

        }).catch((err) => {
            const errorCode = err.code,
                errorMessage = err.message, email = err.customData.email,
                credential = GoogleAuthProvider.credentialFromError(err);
            console.log('Error: ', errorCode, errorMessage, email, credential);
        });
    };

    const FacebookLogin = () => {
        signInWithPopup(
            auth, provider2
        ).then((res) => {
            let obj = null;
            const credential = FacebookAuthProvider.credentialFromResult(res);
            obj = {
                'FIRST_NAME': res._tokenResponse.firstName,
                'LAST_NAME': res._tokenResponse.lastName,
                'EMAIL': res._tokenResponse.email,
                'MOBILE_NUMBER': res.user.phoneNumber,
                'TOKEN': res._tokenResponse.idToken,
            };

            axios({
                method: 'post',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/endusers/webfacebooklogin',
                data: obj,
            }).then((res) => {
                localStorage.setItem('User_data', JSON.stringify(res.data));
                colse_Login();
                // history.push('/home');
                props.flag_Disable();
            }).catch((err) => {
                console.log(err);
            });
            //   localStorage.setItem('User_data', JSON.stringify(res.data));
            //     history.push('/home');
        }).catch((err) => {
            const errorCode = err.code,
                errorMessage = err.message, email = err.customData.email,
                credential = FacebookAuthProvider.credentialFromError(err);
            console.log('Error: ', errorCode);
        });
    };

    const settimer = () => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }

    const sendOTP = () => {
        setMinutes(2);
        setSeconds(59);
        settimer();
    };

    const resendOTP = () => {
        setMinutes(2);
        setSeconds(59);
    };

    const colse_Login = () => {
        props.login_flag_disable();
        // history.goBack();
    }

    useEffect(() => {
        setLImg(true);

        setTimeout(() => {
            setLImg(false);
        }, 1000);
    }, []);

    const signup_enable = () => {
        props.Signup_flag_enable()
    }

    // const Signup_flag_disable = useCallback(() => {
    //     setSignup_flag(false);
    //     // props.flag_Disable()
    // }
    // )
    // const sign_disable = useCallback(() => {
    //     setSignup_flag(false);
    //     props.flag_Disable()
    // })
    // const signupheader = () => {
    //     return(
    //         <div className="d-block w-100 text-center mb-3">
    //         {/* <Link to='/home'>  <img src={Logo} width="170px" /></Link> */}
    //         <h4 className='text-center'>SIGN UP</h4>
    //         <p className='text_grey fs-12'>just provide your basic information, and you'll be ready to explore.</p>
    //     </div>
    //     )
    // }
    return (
        // <Dialog visible={login_flag} style={{ width: '50vw' }} onHide={() => colse_Login()}>
        <section className=''>
            {/* <Dialog visible={Signup_flag} header={signupheader()} className='dailog_box_responsive' onHide={() => setSignup_flag(false)}><Signup Sign_disable={sign_disable} Signup_flag_disable = {Signup_flag_disable}/></Dialog> */}
            <div className=''>
                {/* <div className='col-sm-6 d-flex justify-content-center'>
                    {loadImg === false ? <Carousel variant='dark' className='carousel_disable' controls={false} indicators={false} style={{ width: '60%' }}>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Img1}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Img2}
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Img3}
                                alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel> : <Skeleton height="21rem" borderRadius="15px" />}
                </div> */}
                <div className=''>
                    <div className=''>
                        <div className=''>
                            <div className="d-block w-100 text-center mb-4]">
                                {/* <Link to='/home' >  <img src={Logo} width="170px" /></Link> */}
                                <h2 className='text-center'>LOGIN</h2>
                                <small className='color_red text-center'>{error}</small>
                            </div>
                            <div className='row justify-content-center'>
                                <div className="login-form col-sm-8">
                                    <form onSubmit={handleSubmit()}>
                                        {button &&
                                            <div className="form-group">
                                                <label className='mb-1 fs-9'>Enter your Email/Number</label>
                                                <InputText
                                                    name="mobile_number"
                                                    type="text"
                                                    {...register('mobile_number')}
                                                    placeholder={'Enter your Email/Number'}
                                                    onChange={e => handlenumTextInput(e.target.value)}
                                                    disabled={!button}
                                                    className={`w-100 login_input ${errors.mobile_number ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.mobile_number?.message}</div>
                                            </div>
                                        }
                                        {Password_status &&
                                            <div className="field w-100 mt-2 p-input-icon-right">
                                                <label className='mb-1'>Password</label>
                                                {!isRevealPwd ?
                                                    <i className="pi pi-eye mr-2 mt-1" onClick={() => setIsRevealPwd(prevState => !prevState)}></i> :
                                                    <i className="pi pi-eye-slash mr-2 mt-1" onClick={() => setIsRevealPwd(prevState => !prevState)}></i>
                                                }
                                                <InputText
                                                    name="user_password"
                                                    type={isRevealPwd ? "text" : "password"}
                                                    {...register('user_password')}
                                                    className={`w-100 login_input ${errors.user_password ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.user_password?.message}</div>
                                            </div>

                                        }

                                        {login_button ? <div className="form-group mt-4 text-center">
                                            <button type="Submit" onClick={() => userlogin()} className="btn btn_p text-white w-100 px-4">
                                                Login
                                            </button></div> :
                                            <div>
                                                {button ? <div className="form-group text-center">
                                                    <button type="Submit" onClick={() => getOtp()} className="btn btn_p text-white w-100 mt-3 px-4">
                                                        Get Otp
                                                    </button>
                                                </div> : ''}
                                                {!button ? <div className="form-group">
                                                    <label className='mb-2 fs-9'>Enter OTP to Login. OTP send to {getValues('mobile_number')}</label>
                                                    <OtpInput
                                                        value={OTP_Number}
                                                        // {...register('Otp_valid')}
                                                        className={`w-100 login_input ${errors.Otp_valid ? 'is-invalid' : ''}`}
                                                        name="Otp_valid"
                                                        onChange={setOTP_Number}
                                                        numInputs={6}
                                                        // renderSeparator={<span> </span>}
                                                        inputStyle="login_Otp_input"
                                                        renderInput={(props) => <input name="Otp_valid" {...register('Otp_valid')} onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} {...props} />}
                                                    />
                                                    {/* <InputText
                                                        name="Otp_valid"
                                                        type="text"
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        {...register('Otp_valid')}
                                                        className={`w-100 login_input ${errors.Otp_valid ? 'is-invalid' : ''}`}
                                                    /> */}
                                                    <div className="invalid-feedback">{errors.Otp_valid?.message}</div>
                                                </div> : ''}
                                                {!button ? <div><div className="form-group text-center">
                                                    <button type="submit w-100" onClick={verifyOtp} className="btn btn_p text-white w-100 mt-3 px-4">
                                                        Verify Otp
                                                    </button>
                                                </div>
                                                    {/* <div className="countdown-text">
                                                        {seconds > 0 || minutes > 0 ? (
                                                            <p>
                                                                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                                {seconds < 10 ? `0${seconds}` : seconds}
                                                            </p>
                                                        ) : (
                                                            <p>Didn't recieve code?</p>
                                                        )}

                                                        <button
                                                            disabled={seconds > 0 || minutes > 0}
                                                            style={{
                                                                color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630"
                                                            }}
                                                            onClick={resendOTP}
                                                        >
                                                            Resend OTP
                                                        </button>
                                                    </div> */}
                                                </div> : ''}
                                            </div>
                                        }
                                    </form>
                                    <hr className='mt-4 hr_line' />
                                    {/* <div className='text-center mt-3 fs-12'>
                                        <p className='m-0'><Link to='/forgot'><b>Forgot Password ?</b></Link></p>
                                    </div> */}
                                    <div className='d-flex justify-content-center flex-row'>
                                        <div className='fs-12 mx-2'>
                                            <p className=''>Login With Social Media</p>
                                        </div>

                                    </div>
                                    <div className='d-flex justify-content-center mb-2 flex-row'>
                                        <div onClick={GoogleLogin} className='mx-2'>
                                            <img src={Glogo} className='google' />
                                        </div>

                                        <div onClick={FacebookLogin} className="mx-2">
                                            <img src={Flogo} className='facebook' />
                                        </div>
                                    </div>
                                    <div className='text-center fs-12 mt-5' onClick={() => {signup_enable()}}>
                                        <p className='m-0'>Don’t have an account ? <span>Sign Up!</span></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // </Dialog>
    );
};

export default Login;