import React, { useState, useEffect, useRef } from "react";
import { Container, Form, Row, Col } from 'react-bootstrap';
import { GLOBAL_DOMAIN, Api_Headers, PAYTM_SCRIPT_ID } from '../../../Config/Config';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from 'axios';
import Header from "../../../Components/Header/Header";
import { Payment_Gateway } from "../../../Config/Config";
import { Toast } from 'primereact/toast';
import { initializeTPL } from "../../../Components/Tpl";
import { Card } from 'primereact/card';
import Add_icon_white from '../../../Assets/Atum_Icons/Add_icon_white.svg';
import Deduction_arrow_icon from '../../../Assets/Atum_Icons/Deduction_arrow_icon.svg';
import Credited_arrow_icon from '../../../Assets/Atum_Icons/Credited_arrow_icon.svg';
import Bank_card_icon from '../../../Assets/Atum_Icons/bank-card-fill.svg';
import Secure_payment_icon from '../../../Assets/Atum_Icons/secure-payment-fill.svg';
import Price_tag_icon from '../../../Assets/Atum_Icons/price-tag-3-fill.svg';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const Wallet = () => {
    const [walletTransactions, setWalletTransactions] = useState([]);
    const [userData, setUserData] = useState('');
    const [walletAmount, setWalletAmount] = useState(0);
    const [addAmount, setAddAmount] = useState(0);
    const [Addwallet_flag, setAddwallet_flag] = useState(false);
    const user_data = JSON.parse(window.localStorage.getItem('User_data'));
    const toast = useRef(null);

    useEffect(() => {
        getUserData();
        getUserTransactions();
        //initializeTPL(PAYTM_SCRIPT_ID);
    }, []);

    const getUserData = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
        }).then((res) => {
            console.log(res.data)
            setUserData(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const getUserTransactions = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/usertransactions?type=list&limit=100&userId=' + user_data.data.userData.ID,
        }).then((res) => {
            setWalletTransactions(res.data.rows);
        }).catch((err) => {
            console.log(err);
        });
    }
    const addAmountToWallet = () => {
        // console.log('at config level:userData.ID ',userData.ID);
        axios({
            method: 'POST',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/paymentgateway/generateWebToken',
            data: {
                'ORDER_TOTAL_AMOUNT': `${addAmount}`,
                'USER_ID': user_data.data.userData.ID,
            }
        }).then((res) => {
            // console.log('at config level:orderid ',res.data.data.orderInfo.ORDER_ID);
            // console.log('at config level:txnToken ',res.data.data.txnToken);
            // console.log('at config level:ORDER_TOTAL_AMOUNT ',res.data.data.orderInfo.ORDER_TOTAL_AMOUNT);
            const checksumInfo = JSON.parse(res.data.paytmchecksum);
            var config = {
                "root": "",
                "style": {
                    "bodyBackgroundColor": "#fff",
                    "bodyColor": "#333",
                    "themeBackgroundColor": "#77cab9",
                    "themeColor": "#fff",
                    "headerBackgroundColor": "#77cab9",
                    "headerColor": "#fff",
                    "errorColor": "red",
                    "successColor": "green",
                    "card": {
                        "padding": "",
                        "backgroundColor": "",
                    }
                },
                "data": {
                    "orderId": `${res.data.data.orderInfo.ORDER_ID}`,
                    "token": `${res.data.data.txnToken}`,
                    "tokenType": "TXN_TOKEN",
                    "amount": `${res.data.data.orderInfo.ORDER_TOTAL_AMOUNT}`,
                },
                "payMode": {
                    "labels": {},
                    "filter": {
                        "exclude": [
                            "WALLET",
                            "BANK_MANDATE",
                            "BANK_TRANSFER",
                            "DEBIT_CARD",
                            "EMI",
                            "EMI_DC",
                            "MGV",
                            "LOGIN",
                            "PPBL",
                            "PDC",
                            "SC",
                            "BALANCE"
                        ]
                    },
                    "order": [
                        "CARD",
                        "NB",
                        "UPI"
                    ]
                },
                "website": "WEB",
                "flow": "DEFAULT",
                "merchant": {
                    "mid": Payment_Gateway.MERCHANT_ID,
                    'name': 'ATUM Life',
                    "redirect": false,
                },

                "handler": {
                    "transactionStatus": function transactionStatus(paymentStatus) {
                        window.Paytm.CheckoutJS.close();
                        let datas = {
                            "USER_ID": userData.ID,
                            "ORDER_ID": paymentStatus.ORDERID,
                            "TRANSACTION_ID": paymentStatus.TXNID,
                            "PAYMENT_TYPE": "CREDIT",
                            "GATEWAY_NAME": paymentStatus.GATEWAYNAME,
                            "ORDER_AMOUNT": parseInt(paymentStatus.TXNAMOUNT),
                            "CREATED_BY": userData.ID,
                            "UPDATED_BY": userData.ID,
                        }; console.log('body: ', datas);
                        if (paymentStatus.STATUS == 'TXN_SUCCESS') {
                            axios({
                                method: 'post',
                                headers: Api_Headers,
                                url: GLOBAL_DOMAIN + '/v1/usertransactions',
                                data: datas,
                            }).then((res) => {
                                setAddAmount(0);
                                getUserTransactions();
                                getUserData();
                            }).catch((err) => {
                                console.log(err);
                            });
                        } else {
                            showtoast()
                        }
                    },
                    "notifyMerchant": function notifyMerchant(eventName, data) {
                        window.Paytm.CheckoutJS.close();
                    },
                },
            };

            if (window.Paytm && window.Paytm.CheckoutJS) {
                window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                    window.Paytm.CheckoutJS.invoke();
                }).catch(function onError(err) {
                    console.log("Error => ", err);
                });
            };
        }).catch((err) => {
            window.Paytm.CheckoutJS.invoke();
        });
    };

    const amtBody = (row) => {
        return `₹ ${row.ORDER_AMOUNT}`
    };

    const dateBody = (row) => {
        var d = new Date(row.CREATED_AT);
        return d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear();
    };

    const ptBody = (row) => {
        return (
            row.PAYMENT_TYPE === 'CREDIT' ? <div style={{ color: 'forestgreen', fontWeight: '700' }}>Credited</div> :
                row.PAYMENT_TYPE === 'DEBIT' ? <div style={{ color: 'red', fontWeight: '700' }}>Debited</div> :
                    <div style={{ color: 'darkgreen', fontWeight: '700' }}>Refer Bonus</div>);
    };
    const showtoast = () => {
        toast.current.show({ severity: 'error', summary: 'Payment Failure', life: 3000 });
    }
    return (
        <Container className='container'>
            <Toast ref={toast} />
            <Dialog visible={Addwallet_flag} header="Add Wallet Amount" className="wallet_dialog_responsive" onHide={() => {setAddwallet_flag(false); setAddAmount(0)}}>
                <div className='row py-2'>
                    <div className="col-sm-6 my-2">
                        {/* <Form.Group controlId='name'> */}
                            <InputText
                                type='text'
                                className="w-100"
                                placeholder='Enter Amount'
                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                value={addAmount}
                                onChange={(e) => setAddAmount(e.target.value)}
                            ></InputText>
                        {/* </Form.Group> */}
                    </div>
                    <div className="col-sm-6 wallet_text_end primary_btn my-2">
                        <Button className="p-button" disabled={addAmount <=0 }
                            onClick={() => addAmountToWallet()}>Add to Wallet</Button>
                    </div>
                </div>
                <div className="d-flex mt-2">
                    <div className="me-3"><p className="m-0">Select Amount</p></div>
                    <div className="mx-3"><p className="m-0 color_blue_hover fw-bold" onClick={(e) => setAddAmount(500)}>₹ 500</p></div>
                    <div className="mx-3"><p className="m-0 color_blue_hover fw-bold" onClick={(e) => setAddAmount(1000)}>₹ 1000</p></div>
                    <div className="mx-3"><p className="m-0 color_blue_hover fw-bold" onClick={(e) => setAddAmount(2000)}>₹ 2000</p></div>
                </div>
            </Dialog>
            <Row>
                <Col xs={12} md={6}>
                    <div>
                        <Card className='rounded bg_color_head position-relative p-2' style={{ height: '250px' }}>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div>
                                        <p className="m-0 text-white">My Wallet</p>
                                    </div>
                                    <div className="position-absolute bottom-10">
                                        <div>
                                            <p className="m-0 text-white">Balance</p>
                                        </div>
                                        <div>
                                            <h4 className="m-0 text-white">₹ {parseFloat(userData.TOTAL_WALLET_AMOUNT).toFixed(2)}</h4>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} style={{ height: '200px' }} className="d-flex text-center justify-content-center align-items-center">
                                    <div onClick={() => { setAddwallet_flag(true); setAddAmount(0)}} className="cursor_poniter">
                                        <div>
                                            <img src={Add_icon_white} />
                                        </div>
                                        <div className="mt-1">
                                            <p className=" m-0 text-white">Add Money</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                        <div className='rounded my-3'>
                            <div className='d-flex justify-content-center mt-3'>
                                <div>
                                    <small className='Atum_coin_text'>Atum Coin</small>
                                    <small className=' mx-2 fs-10 color_pink'>You have {userData?.TOTAL_ATUM_COINS} ATUM Coin</small>
                                </div>
                            </div>
                        </div>
                        <div className="pt-4">
                            <div className="d-flex justify-content-between">
                                <div className="text-center">
                                    <div><img src={Bank_card_icon} /></div>
                                    <div><small className="m-0">Multiple payment options</small></div>
                                </div>
                                <div className="text-center">
                                    <div><img src={Secure_payment_icon} /></div>
                                    <div><small className="m-0">Safest payment process</small></div>
                                </div>
                                <div className="text-center">
                                    <div><img src={Price_tag_icon} /></div>
                                    <div><small className="m-0">Special Deals on wallet payment</small></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div>
                        <div>
                            <h4>Wallet Transaction</h4>
                        </div>
                        <hr />
                        {walletTransactions && walletTransactions?.map((items, index) => {
                            return (
                                <div className="border-last my-3">
                                    <div className="d-flex align-items-center">
                                        {items.PAYMENT_TYPE === 'CREDIT' ?
                                            <div className="me-1">
                                                <img src={Credited_arrow_icon} />
                                            </div> :
                                            <div className="me-1">
                                                <img src={Deduction_arrow_icon} />
                                            </div>
                                        }
                                        <div className="lh-1">
                                            {items.PAYMENT_TYPE === 'CREDIT' ?
                                                <p className="m-0">id #{items.TRANSACTION_ID}</p> :
                                                <p className="m-0">Purchased id #{items.TRANSACTION_ID}</p>
                                            }
                                            <small className="text-grey fs-12">{items.CREATED_AT}</small>
                                        </div>
                                    </div>
                                    <div className="ms-4 mt-3">
                                        {items.PAYMENT_TYPE === 'CREDIT' ?
                                            <p className="ps-1">Added: ₹ <span className="color_head">{items.ORDER_AMOUNT}</span></p> :
                                            <p className="ps-1">Spent: ₹ {items.ORDER_AMOUNT}</p>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Col>
            </Row>
            {/* <Row>
                <Col xs={12}>
                    <div style={{textAlign: 'center', fontSize: '20px', fontWeight: '800'}}>User Wallet</div>
                </Col>
                <Col xs={12} className='d-flex justify-content-between bg_color_head p-2 align-items-center'>
                    <div>
                        <div style={{ textAlign: 'left', fontSize: '18px', fontWeight: '700', color: '#fff' }}>
                            ATUM Wallet - ₹{parseFloat(userData.TOTAL_WALLET_AMOUNT).toFixed(2)}
                        </div>
                        <div style={{ textAlign: 'left', fontSize: '13px', marginTop: '1%', color: '#fff' }}>
                            ATUM {userData.TOTAL_ATUM_COINS <= 1 ? 'Coin' : 'Coins'} - {userData.TOTAL_ATUM_COINS}
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className="mx-1">
                            <Form.Group controlId='name'>
                                <Form.Control
                                    type='name'
                                    placeholder='Enter Amount'
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                    value={addAmount}
                                    onChange={(e) => setAddAmount(e.target.value)}
                                    style={{ borderRadius: '5px', textAlign: 'center' }}
                                ></Form.Control>
                            </Form.Group>
                        </div>
                        <div>
                            <Button style={{ color: '#77cab9', backgroundColor: '#fff', border: '1px solid #fff', borderRadius: '5px', fontSize: '16px', fontWeight: '900' }}
                                onClick={() => addAmountToWallet()}>Add to Wallet</Button>
                        </div>
                    </div>
                </Col>
                <Col xs={12} style={{ marginTop: '15px' }}>
                    <DataTable value={walletTransactions} >
                        <Column header='Transaction' field="TRANSACTION_ID" />
                        <Column header='Payments' field='PAYMENT_TYPE' body={ptBody} />
                        <Column header='Amount' field='ORDER_AMOUNT' body={amtBody} />
                        <Column header='Created on' field='CREATED_AT' body={dateBody} />
                    </DataTable>
                </Col>
            </Row> */}
        </Container>
    );
}
export default Wallet;