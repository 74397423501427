import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import './Header_Carousel.css';
import { IMG_GLOBAL } from "../../Config/Config";
import useWindowDimensions from "../windowdimension";

const Header_Carousel = (props) => {

    const [banner_data, setbanners] = useState([]);
    const { height, ScreenWidth } = useWindowDimensions();

    useEffect(() => {
        // setbanners(props.BannerData);
        if(ScreenWidth <= 480 ){
            props.responsivebanners('MOBILE');
            setbanners(props.BannerData);
        }else{
        props.responsivebanners('WEBSITE');
        setbanners(props.BannerData);
        }
    }, [ScreenWidth]);

    return (
        <section className="container">
            <Carousel pause="hover" indicators={banner_data.length <= 1 ? false : true } controls={banner_data.length <= 1 ? false : true } className="Header_Carousel_Banner" variant='dark'>
                {banner_data.length > 0 && banner_data.map((items, index) =>
                    <Carousel.Item key={index}>
                        <img className={ScreenWidth >= 480 ? "height-40" : "height-30"} src={IMG_GLOBAL + items.IMAGE_PATH} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt="Banner Image" style={{ width: '100%', borderRadius: '5px' }} />
                        {/* height: '280px', */}
                    </Carousel.Item>
                )}
            </Carousel>
        </section>
    )
}

export default Header_Carousel