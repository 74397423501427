import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Carousel, Container } from "react-bootstrap";
import { Link, useHistory, useLocation } from 'react-router-dom';
import '../../../App.css';
import './Productview.css';
import { GLOBAL_DOMAIN, IMG_GLOBAL, Api_Headers } from '../../../Config/Config';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import { Card } from 'primereact/card';
import Login from '../../Login/Login';
import { Dialog } from 'primereact/dialog';
import ProductGalleri_Carousel from '../../../Components/Carousel/ProductGalleri_Carousel';
import BreadCrumbCard from '../../../Components/Breadcrumb/Breadcrumb';
import Love_Icon_Green from '../../../Assets/Atum_Icons/Love_icon_green.svg';
import Share_Icon from '../../../Assets/Atum_Icons/Share_icon_bule.svg';

const Productview = (props) => {
    const history = useHistory();
    const location = useLocation();
    const [PData, setPdata] = useState({});
    const [productID, setproductID] = useState();
    const [getIMAGE_PATH, setIMAGE_PATH] = useState();
    const [getImgArray, setgetImgArray] = useState([]);
    const [get_price, setprice] = useState();
    const [get_discountprice, setdiscountprice] = useState();
    const [Attribute_data_color, setAttribute_data_color] = useState([]);
    const [Attribute_data_EXTRA_CHARGES, setAttribute_data_EXTRA_CHARGES] = useState([]);
    const [Attribute_data_PREBOOK_AMOUNT, setAttribute_data_PREBOOK_AMOUNT] = useState([]);
    const [selected_Attribute_ID, setselected_Attribute_ID] = useState();
    const toast = useRef(null);
    const [Discount_percentage, setDiscount_percentage] = useState();
    const [selected_Attribute, setselected_Attribute] = useState({});
    const user_data = JSON.parse(window.localStorage.getItem('User_data'));
    const { Product_ID } = location.state;
    const [Cart_Percentage_Limit, setCart_Percentage_Limit] = useState(Number);
    const [Cart_Quantity_Limit, setCart_Quantity_Limit] = useState(Number);
    const [Quantity_Limit_Error, setQuantity_Limit_Error] = useState(false);
    const [totaldiscountquantity, settotaldiscountquantity] = useState(false);
    const [Product_Quantity, setProduct_Quantity] = useState(Number);
    const [login_flag, setlogin_flag] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setProduct_Quantity(1);
        getproductdata();
    }, []);

    const flag_disable = useCallback(() => {
        window.location.reload();
        setlogin_flag(false);
        // Addtocart();
    }
    )

    const BreadcrumbdatA = [
        { label: 'Product Categories', url: '/dashboard' },
        { label: `${PData?.PRODUCT_NAME}` }
    ];

    const showtoast = (event) => {
        toast.current.show({ severity: `${event.severity}`, summary: `${event.summary}`, life: 3000 });
    }
    const getproductdata = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/products/' + Product_ID,
        }).then((res) => {
            setPdata(res.data);
            if (res.data.iscolorAvailable) {
                let defaultColorData = res.data.COLORS[0]
                getattributedata(defaultColorData)
            } else {
                let defaultColorData = res.data.PRODUCT_VARIANTS[0]
                getattributedata(defaultColorData)
            }
            if (res.data.isattributesAvailable) {
                if (res.data.attributesInfo.EXTRA_CHARGES) {
                    setAttribute_data_EXTRA_CHARGES(res.data.attributesInfo.EXTRA_CHARGES);
                }
                if (res.data.attributesInfo.PREBOOK_AMOUNT) {
                    setAttribute_data_PREBOOK_AMOUNT(res.data.attributesInfo.PREBOOK_AMOUNT);

                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    
    const WishList_Api = (wish_item) => {
        console.log(wish_item)
        let wishlist_data = {
            "PRODUCT_ID": wish_item.ID,
            "CREATED_BY": user_data.data.userData.ID,
            "UPDATED_BY": user_data.data.userData.ID,
            "USER_ID": user_data.data.userData.ID
        }
        axios({
            method: 'POST',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/userswishlist',
            data: wishlist_data
        }).then((res) => {
            // setCoupon_Products_List(res.data);
            showtoast({summary:"Successfully added to WishList", severity:'success'});
        }).catch((err) => {
            showtoast({summary:"Something went Worng", severity:'error'});
            console.log(err);
        });
    }
    const Addtocart = () => {
        let RequestData = {}
        if (!user_data) {
            setlogin_flag(true);
        }
        else {
            if (PData.iscolorAvailable) {
                RequestData = {
                    "USER_ID": user_data.data.userData.ID,
                    "PRODUCT_ID": selected_Attribute.ID,
                    "QUANTITY": 1,
                    "PRODUCTS_QUANTITY": selected_Attribute.QUANTITY,
                    // "PRODUCT_ATTRIBUTE_ID": selected_Attribute.ID
                }
            } else {
                RequestData = {
                    "USER_ID": user_data.data.userData.ID,
                    "PRODUCT_ID": PData.iscolorAvailable ? selected_Attribute.ID : PData.ID,
                    "QUANTITY": Product_Quantity,
                    "PRODUCTS_QUANTITY": PData.iscolorAvailable ? selected_Attribute.QUANTITY : PData.QUANTITY,
                    // "PRODUCT_ATTRIBUTE_ID": selected_Attribute.ID
                }
            }
            axios({
                method: 'post',
                headers: Api_Headers,
                data: RequestData,
                url: GLOBAL_DOMAIN + '/v1/usercart',

            }).then((res) => {
                // const productdata = res.data;
                // setPdata(res.data);
                showtoast({summary:"Successfully added to cart",severity:'success'});

            }).catch((err) => {
                console.log(err);
                showtoast({summary:"Something went Worng", severity:'error'});
            });
        }
    }

    const Prebooking = (product) => {
        console.log(product);
        if (!user_data) {
            setlogin_flag(true);
        } else {
            axios({
                method: 'patch',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/products?action=prebook',
                data: {
                    PRODUCT_ID: product.PRODUCT_ID,
                    PRODUCT_ATTRIBUTE_ID: selected_Attribute.ID,
                }
            }).then((res) => {
                const Prebooking_data = { Attribute_Data: selected_Attribute, Attribute_price_data: res.data.data.ORDER_TOTAL_AMOUNT }
                history.push('/prebooking', Prebooking_data);
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    // const gotoprebooking = () => {
    //     if (!user_data) {
    //         history.push('/login');
    //     } else {
    //         const data = { Attribute_Data: selected_Attribute, Product_Data : PData }
    //         history.push('/prebooking', data);
    //     }
    // }
    const getattributedata = (attribute_data) => {
        console.log(attribute_data)
        setselected_Attribute(attribute_data);
        // setIMAGE_PATH(attribute_data?.IMAGE_PATH1);
        if (attribute_data.IMAGE_PATH1 != null) {
            getImgArray[0] = {
                itemImageSrc: attribute_data.IMAGE_PATH1,
                thumbnailImageSrc: attribute_data.IMAGE_PATH1,
                alt: "Description for Image 1",
                title: "Title 1"
            }
        } else {
            getImgArray[0] = {};

        }
        if (attribute_data.IMAGE_PATH2 != null) {
            getImgArray[1] = {
                itemImageSrc: attribute_data.IMAGE_PATH2,
                thumbnailImageSrc: attribute_data.IMAGE_PATH2,
                alt: "Description for Image 2",
                title: "Title 2"
            }
        } else {
            if (getImgArray[1]) {
                delete getImgArray[1];
            }
        }
        if (attribute_data.IMAGE_PATH3 != null) {
            getImgArray[2] = {
                itemImageSrc: attribute_data.IMAGE_PATH3,
                thumbnailImageSrc: attribute_data.IMAGE_PATH3,
                alt: "Description for Image 3",
                title: "Title 3"
            }
        } else {
            if (getImgArray[2]) {
                delete getImgArray[2];
            }
        }
        if (attribute_data.VIDEO_PATH != null) {
            getImgArray[3] = {
                itemImageSrc: attribute_data.VIDEO_PATH,
                thumbnailImageSrc: attribute_data.VIDEO_PATH,
                alt: "Description for Image 1",
                title: "Vedio"
            }
        } else {
            if (getImgArray[3]) {
                delete getImgArray[3];
            }
        }
        setDiscount_percentage(attribute_data?.DISCOUNT_PERCENTAGE);
        setprice(attribute_data?.PRICE);
        setdiscountprice(attribute_data?.DISCOUNT_PRICE);
    }
    const pricedata = (
        <div className=''>
            {get_discountprice == get_price ? <p className="Price_original m-0">&#8377;{get_price}</p> :
                <div>
                    <p className="Price_original m-0">&#8377;{get_discountprice} </p>
                    <p className='m-0'><del className="text-grey Price_discount">&#8377;{get_price}</del></p>
                </div>}
        </div>
    );

    const quantityCheck = (item, type) => {
        if (type === 'plus') {
            // if (Product_Quantity > item.QUANTITY) {
            //     setQuantity_Limit_Error(true);
            // }
            if (item.DISCOUNT_TYPE == 'PERCENTAGE' && item.DISCOUNT_PERCENTAGE >= 50) {
                if (Product_Quantity < 5) {
                    let sProduct_Quantity = Product_Quantity + 1;
                    settotaldiscountquantity(true);
                    setProduct_Quantity(sProduct_Quantity)
                } else {
                    // Product_Quantity = Product_Quantity
                    setProduct_Quantity(Product_Quantity)
                    settotaldiscountquantity(false);
                }
            } else {
                settotaldiscountquantity(false);
                let sProduct_Quantity = Product_Quantity + 1;
                setProduct_Quantity(sProduct_Quantity)
            }

        } else if (type === 'minus') {
            if (Product_Quantity < item.QUANTITY) {
                setQuantity_Limit_Error(false);
            } else {
                // if(item.QUANTITY < newState[index].QUANTITY){
                setQuantity_Limit_Error(true);
                // }
            }
            if (Product_Quantity > 1) {
                let eProduct_Quantity = Product_Quantity - 1;
                setProduct_Quantity(eProduct_Quantity)
            }
        }
    };

    return (
        <section>
            <Dialog visible={login_flag} style={{ width: '50vw' }} onHide={() => setlogin_flag(false)}><Login flag_Disable={flag_disable} /></Dialog>
            {/* <Header></Header> */}
            <div className="container">
                <Toast ref={toast} />
                <React.Fragment>
                    {PData &&
                        <div className='d-flex justify-content-between align-items-center my-3 mobile_none'>
                            <div>
                                <BreadCrumbCard Breadcrumbdata={BreadcrumbdatA} />
                            </div>
                            <div className='d-flex'>
                                <div className='mx-3 cursor_poniter' onClick={() => WishList_Api(PData)}>
                                <img src={Love_Icon_Green} />
                                </div>
                                <div className='cursor_not_allowed'>
                                <img src={Share_Icon} />
                                </div>
                            </div>
                        </div>
                    }
                </React.Fragment>
                <div className="row">
                    <div className='col-sm-4'>
                        <div>
                            <ProductGalleri_Carousel GetImg_Array={getImgArray} />
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div>
                            <h4>{PData.PRODUCT_FAMILY}</h4>
                        </div>
                        {PData.iscolorAvailable &&
                            <div className='mt-4'>
                                <p className='m-0'>Colours</p>
                                <div className='my-2 d-flex Attribute_color_wrapper'>
                                    {PData.COLORS.map(item => {
                                        return (
                                            <div key={item.ID} className={'Attribute_color_card text-center p-1' + (item.ID == selected_Attribute.ID ? ' attribute_active' : '')} onClick={() => getattributedata(item)}>
                                                <p className='Attribute_color m-0' style={{ backgroundColor: item.PRODUCT_COLOR }}></p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }

                        <div className='mt-4'>
                            {selected_Attribute.isattributesAvailable && selected_Attribute.attributesInfo.PREBOOK_AMOUNT && selected_Attribute.attributesInfo.PREBOOK_AMOUNT.length > 0 ?
                                selected_Attribute.attributesInfo.PREBOOK_AMOUNT.map((item, index) => {
                                    return (
                                        <div className='pre_booking_card' key={index}>
                                            <Card>
                                                <div className='pre_booking_card_heading'>
                                                    <h4 className='fs-20 m-0'>Pre-Booking Available</h4>
                                                </div>
                                                <div className='pre_booking_card_Content'>
                                                    <p className='m-0'>Secure your spot now! Pre-book this item for just &#8377; {item.ATTRIBUTE_VALUE}.</p>
                                                </div>
                                                <div className='d-flex justify-content-center pb-3'>
                                                    <Button className="border_2 color_head px-4" style={{ backgroundColor: '#fff' }}><span onClick={() => Prebooking(item)}>Pre-Book Now</span></Button>
                                                </div>
                                            </Card>
                                        </div>
                                    )
                                }) :
                                <div>
                                    <p className='m-0'>Product Description</p>
                                    <div>
                                        <p className='description_wrap'>{selected_Attribute.DESCRIPTION}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <Card>
                            <div className='row d-flex align-items-center'>
                                <div className='col-sm-6'>
                                    <p className='m-0'>Price</p>
                                    <h4 className='m-0'>{pricedata}</h4>
                                    <small className='text-grey'>( Inclusive of all taxes )</small>
                                </div>
                                {selected_Attribute.DISCOUNT_VALUE > 0 &&
                                    <div className='col-sm-6 d-flex justify-content-center'>
                                        <div className='discount_view'>
                                            {selected_Attribute.DISCOUNT_TYPE == "AMOUNT" ?
                                                <div><small>&#8377;{selected_Attribute.DISCOUNT_VALUE} Discount</small></div> :
                                                <div><small>{selected_Attribute.DISCOUNT_VALUE}% Discount</small></div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='row mt-2 justify-content-end'>
                                {/* <div className='col-sm-6 col-md-12 col-lg-6 col-xl-6 my-2 d-flex align-items-center'>
                                    <Button className='Icon_Btn Icon_Btn_Min' disabled={Product_Quantity == 1} onClick={() => quantityCheck(selected_Attribute, 'minus')}>-</Button>
                                    <div className='Icon_input'><input className='text-center' value={Product_Quantity} readOnly={true} name="cart_count" type="text" style={{ width: '50px', height: '25px' }} /></div>
                                    <Button className='Icon_Btn Icon_Btn_Max' disabled={(selected_Attribute.QUANTITY <= Product_Quantity) || (selected_Attribute.DISCOUNT_PERCENTAGE >= 50 && Product_Quantity >= 5)} onClick={() => quantityCheck(selected_Attribute, 'plus')}>+</Button>
                                </div> */}
                                <div className='col-sm-6 col-md-12 col-lg-6 col-xl-6 my-2 d-flex justify-content-center'>
                                    <Button className="btn_p fs-20 px-4" onClick={Addtocart}>Add to Cart</Button>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center mt-3'>
                                <div >
                                    <small className='Atum_coin_text'>{selected_Attribute.ELIGIBLE_ATUM_COINS} Atum Coin</small>
                                    <small className='discount_view mx-2 fs-10'>Get {selected_Attribute.ELIGIBLE_ATUM_COINS} ATUM Coin as Cashback</small>
                                </div>
                            </div>
                            {selected_Attribute.DISCOUNT_PERCENTAGE >= 50 && selected_Attribute.QUANTITY >= 5 &&
                                <small className='d-flex justify-content-center color_red mt-3'>Offer is applicable for only 5 items</small>
                            }
                            {selected_Attribute.isattributesAvailable && selected_Attribute.attributesInfo.EXTRA_CHARGES && selected_Attribute.attributesInfo.EXTRA_CHARGES.length > 0 &&
                                <div>
                                    <hr />
                                    {/* <p style={{ textAlign: 'justify' }}><b>EXTRA CHARGES</b></p> */}
                                    {selected_Attribute.attributesInfo.EXTRA_CHARGES.map((item, index) => {
                                        const Attributevalue = (
                                            <div>
                                                {item.MEASUREMENT_NAME == 'AMOUNT' &&
                                                    <span className='mx-1'>&#8377;{item.MEASUREMENT_VALUE}</span>
                                                }
                                                {item.MEASUREMENT_NAME == 'PERCENTAGE' && item.ATTRIBUTE_NAME.trim() != 'GST' &&
                                                    <span className='mx-1'>{item.MEASUREMENT_VALUE} %</span>
                                                }
                                                {item.MEASUREMENT_NAME == 'PERCENTAGE' && item.ATTRIBUTE_NAME.trim() == 'GST' &&
                                                    <span>&#8377;{parseFloat((parseFloat(get_discountprice) * parseFloat(item.MEASUREMENT_VALUE)) / 100).toFixed(2)} </span>
                                                }
                                            </div>
                                        )
                                        return (
                                            <Col md={12} className='mb-2' key={index}>
                                                <div className='d-flex justify-content-between'>
                                                    <p className='m-0'>{item.ATTRIBUTE_DISPLAY_NAME}
                                                        {item.MEASUREMENT_NAME == 'PERCENTAGE' && item.ATTRIBUTE_NAME.trim() == 'GST' &&
                                                            <span>({item.MEASUREMENT_VALUE}%)</span>
                                                        }</p>
                                                    <p className='m-0'>{Attributevalue}</p>
                                                </div>
                                            </Col>
                                        )
                                    })
                                    }
                                </div>
                            }
                        </Card>
                    </div>
                    <div className='my-5 d-flex justify-content-center'>
                        <div className='w-80'>
                            <Row>
                                <Col sm={12} md={6} lg={6}><img src={IMG_GLOBAL + selected_Attribute.IMAGE_PATH1} height={300} className="w-100"></img></Col>
                                <Col sm={12} md={6} lg={6}>
                                    <p><b>Product Description</b></p>
                                    <p>{selected_Attribute.DESCRIPTION}</p>
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mt-5">
                                    <p><b>Product Sustanability</b></p>
                                    <p >{selected_Attribute.PRODUCT_SUSTAINABILITY}</p>
                                </Col>
                                <Col sm={12} md={6} lg={6} className="mt-5">
                                    <img src={IMG_GLOBAL + selected_Attribute.IMAGE_PATH1} height={300} className="w-100"></img>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Productview;