import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

export default function Privacy(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div className='mt-5 pt-5 container'>
            <h3 style={{textAlign: 'center'}}>Privacy Policy</h3>
            <h4>Introduction</h4>
            <p>Atum Life Private Limited carries out its business from its retail stores website.</p>
            <p>Atum Life Private Limited respects your privacy and this Privacy Policy outlines the manner in which your data is collected by us through our various interactions with you on the Platform, including data shared by you on your agreeing to become ATUM LIFE customer on the Platform and continuing to remain / avail the ATUMLIFE customer benefits (whether on the Platform or at our retail stores); and the manner in which the data so collected is used by Atum Life Private Limited. You are advised to please read the Privacy Policy carefully. Please note that the provisions of the Policy with respect to the Atumlife customer.</p>
            <p>By accessing the services provided by the Platform and/or continuing to remain / avail Atumlife customer benefits, you agree to the collection and use of your data by Atum Life Private Limited in the manner provided in this Privacy Policy.</p>
            <p>If you have questions or concerns regarding this statement, write to us at info@atumlife.com. The Platform may have links to third party websites/apps. This Policy does not apply to the procedures and practices followed by third party websites/apps that are not managed, owned or controlled by Atum Life Private Limited. Data provided by you to such third party websites/apps shall be governed by their privacy policy.</p>
            <p>Through this Privacy Policy, We wish to make you :</p>
            <ul>
                <li>Feel at ease while using the Platforms and/or availing the benefits of the ATUMLIFE customer.</li>
                <li>Feel confident while sharing information with us.</li>
                <li>Aware that you may contact us with your questions or concerns about privacy on this Platform and/or Atum life customer.</li>
                <li>Aware that by using the Platforms and/or availing / continuing the ATUM LIFE customer you are agreeing to the collection of certain data.</li>
            </ul>
            <h4>Information that may be Collected from You?</h4>
            <p>Atum Life Private Limited collects the details provided by you on registration together with information we learn about you from your use of our service and your visits to the Platform and other websites accessible from them.</p>
            <p>We take customer data privacy very seriously. Kindly, note that Atum Life Private Limited does not access or store your Payment Card details (i.e. credit/debit card number, expiration date, CVV etc.). When you make a purchase using your card, all required transaction details are captured on the secured payment page, and encrypted using Industrial Strength Cipher, and are securely transmitted to your card issuer for obtaining an authorization decision. At no time during the purchase process or thereafter does Atum Life Private Limited have access to, or store, your complete card account information.</p>
            <p>We read your SMS messages only for the purpose of OTP verification at the time of making payments and during user registration on the Platform. We do not read any other messages on your mobile except OTP verification SMS sent by Atum Life Private Limited. We also don’t access your call logs on phone.</p>
            <p>We may collect additional information in connection with your participation in any promotions or contests offered by us and information you provide when giving us feedback or completing profile forms. We also monitor customer traffic patterns and Platform use, which enables us to improve the service we provide. We will collect only such information as is necessary and relevant to us to provide you with the services available on the Platform and/or services / promotions agreed to be availed by you as a ATUMLIFE customer.</p>
            <p>While you use the Platform, we may have automatic access to (receive and collect) certain anonymous information in standard usage logs through our web server, obtained from "cookies" sent to your browser from web server cookies stored on your hard drive, including but not limited to :</p>
            <ul>
                <li>Computer-identification information.</li>
                <li>IP address, assigned to the computer which you use.</li>
                <li>The domain server through which you access our service.</li>
                <li>The type of computer you're using.</li>
                <li>The type of web browser you're using.</li>
            </ul>
            <h4>While you are transacting on the Platform or as ATUM LIFE customer, personally identifiable information collected about you may include :</h4>
            <ul>
                <li>First and last name.</li>
                <li>Email addresses.</li>
                <li>Contact details including phone numbers.</li>
                <li>PIN/ZIP Code.</li>
                <li>Demographic profile (like your age, gender, occupation, education, address and durables owned).</li>
                <li>Personal preferences and interests (such as books, movies, music and so on).</li>
                <li>Your opinion on services, products, features on our Platform.</li>
            </ul>
            <h4>We may also collect the following information about :</h4>
            <ul>
                <li>The pages you visit/access.</li>
                <li>The links you click on our Platform.</li>
                <li>The number of times you access the page.</li>
                <li>Things you view, add to bag, add to wish list.</li>
                <li>Other websites you open simultaneously while browsing on ours.</li>
                <li>Your opinion on services, products, features on our Platform.</li>
            </ul>
            <p>You may terminate your account at any time by writing to us at info@atumlife.com, from the email ID you have used for creating your account login, and mentioning "Please close my Atum Life Private Limited account" in the subject line. We will disable access to your account within 2 working days of receiving your mail. However, your information may remain stored in archive on our servers even after the deletion or termination of your account.</p>
            <h4>When / How do we collect the Information?</h4>
            <p>We will collect anonymous traffic information from you when you visit our Platform. We collect the personally identifiable information from you when you register with us or you transact as a guest. During registration you are required to give us your contact information (such as name, email address, date of birth, gender, billing address, delivery address, pin code, mobile number, occupation, interests etc.) Upon registration users may receive communications from us (e.g. newsletters, updates). Even when transact as a guest we collect identifiable information (such as name, email address, date of birth, gender, billing address, delivery address, pin code, mobile number etc.)</p>
            <p>We use this information to contact you about the services you are using on our Platform and services in which you have expressed interest.</p>
            <p>You have the option to provide demographic information (such as occupation, education and gender) to us; we encourage you to submit this information so we can provide you a more personalized experience on our Platform.</p>
            <p>If you choose to use our referral service to tell a friend about our Platform, we will ask you for your friend's name and email address. We will automatically send your friend a one-time email inviting him or her to visit the Platform does store this information and the information is used for the sole purpose of sending this one-time email and tracking the success of our referral program.</p>
            <p>We are the sole owners of your information collected by us at different points on our Platform.</p>
            <p>We will collect personally identifiable information about you only as part of a voluntary registration process, guest check out, on-line survey, or contest or any combination thereof.</p>
            <p>Our advertisers may collect anonymous traffic information from their own assigned cookies to your browser.</p>
            <p>The Platform contains links to other websites. We are not responsible for the privacy practices of such websites which we do not own, manage or control.</p>
            <p>We may at certain times make chat rooms, forums, instant messenger and message boards and other services available to you. Please understand that any information that is disclosed in these areas becomes public information. We have no control over its use and you should exercise caution when disclosing your personal information to anyone.</p>
            <p>If you use a bulletin board or chat room on this Platform, you should be aware that any personally identifiable information you submit there can be read, collected, or used by other users of these forums, and could be used to send you unsolicited messages. We are not responsible for the personally identifiable information you choose to submit in these forums</p>
            <h4>How is the information used?</h4>
            <h4>We use your contact information to send you :</h4>
            <ul>
                <li>Password reminder and registration confirmation.</li>
                <li>Special offers.</li>
                <li>Changes in the service policies or terms of use.</li>
                <li>Event based communications such as order information, renewal notices, invites, reminders etc.</li>
            </ul>
            <p>Note: “We send users updates upon registration updates regarding your order status, give service updates and respond to your queries. We also send promotional emails/SMS to inform you about new arrivals, special offers, etc.</p>
            <h4>We use your personal information to :</h4>
            <ul>
                <li>Improve personalized features on our Platform to enhance your shopping experience.</li>
                <li>To contact you for your account / profile related matters or any other reason as may be necessary.</li>
                <li>To provide the services requested by you example deliver merchandise ordered by you.</li>
                <li>To preserve social history as governed by existing law or policy.</li>
            </ul>
            <h4>We use anonymous traffic information to :</h4>
            <ul>
                <li>Remember you in order to deliver better and more personalized service e.g. track your entries in our promotion schemes.</li>
                <li>Recognize your usage privileges to our Platform.</li>
                <li>Administer and manage smooth functioning of our Platform by diagnosing problems.</li>
                <li>Track your session and activities so that we can understand better how people use our Platform.</li>
            </ul>
            <h4>Who do we share your information with ?</h4>
            <p>We do not share or rent your information with third parties; except ATUM Life Private Limited and its associate companies (as defined under the Companies Act, 2013), our agents and alliance partners, as provided hereunder.</p>
            <h4>ATUM Life Private Limited</h4>
            <p>The data collected from you at a ATUM Life Private Limited store operated by us, may be shared with ATUM Life Private Limited. ATUM Life Private Limited may use the information including personally identifiable information for its product development, marketing, profiling and target advertising.</p>
            <h4>Agents</h4>
            <p>We use the services of our enabling partners such as outside shipping companies, resellers, business associates to fulfill orders, and credit / debit card processing companies to process the payment for goods and services ordered from the Platform. These entities do not retain, store, share or use personally identifiable information for any other purposes.</p>
            <h4>Alliance Partners</h4>
            <p>We will share your information with our alliance partners who work with us or on our behalf to help provide you the services. An alliance partner is a company or an individual who owns and manages (wholly or part of) online content on their websites powered by the Platform.</p>
            <p>We share email addresses with agents and Alliance partners. The agents and Alliance partners use the email address to confirm the deliveries, send notices and offer services related to the service.</p>
            <p>We do not rent, sell or share your personal information and we will not disclose any of your personally identifiable information to any other third parties unless :</p>
            <p>Required by special circumstances such as compliance with subpoenas, court orders, requests/order from legal authorities or law enforcement agencies requiring such disclosure; To help investigate, prevent or take action regarding unlawful and illegal activities, suspected fraud, potential threat to the safety or security of any person, violations of Platform terms of use or to defend against legal claims; We have your permission; To provide products or services you've requested We reserve the right to disclose your personal identifiable and email address information as required by law and when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, or legal process served on the Platform.</p>
            <p>We share your information with advertisers on an aggregate basis only.</p>
            <p>The security of your personal information and email address is important to us. When you enter sensitive information (such as credit / debit card number) on our acquiring bank's system or order forms, they encrypt that information using secure socket layer technology (SSL). To learn more about SSL, follow this link <Link to={{ pathname:'http://www.verisign.com' }} target='_blank'>http://www.verisign.com</Link></p>
            <p>We follow generally accepted industry standards to protect the personal information and email address submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while we strive to use commercially acceptable means to protect your personal information and email address, we cannot guarantee its absolute security.</p>
            <p>If you have any questions about security on the Platform, you can mail at info@atumlife.com</p>
            <h4>What choices are available to you regarding collection, use and distribution of your information ?</h4>
            <p>You can accept or decline the cookies. All websites that are customizable require that you accept cookies. You must also accept cookies to register as someone for access to some of our services. For information on how to set your browser to alert you to cookies, or to reject cookies, go to <Link to={{ pathname:'http://www.cookiecentral.com/' }} target='_blank'>http://www.cookiecentral.com/</Link></p>
            <h4>Cookies</h4>
            <p>A cookie is a small text file that is stored on a user's computer for record-keeping purposes. We use cookies on this Platform. We do link the information we store in cookies to any personally identifiable information you submit while on the Platform.</p>
            <p>We use both session ID cookies and persistent cookies. We use session cookies to make it easier and secure for you to navigate the Platform. A session ID cookie expires when you close your browser. A persistent cookie remains on your hard drive for an extended period of time. You can remove persistent cookies by following directions provided in your Internet browser's "help" file. Reference for cookies can be found at <Link to={{ pathname: 'http://www.cookiecentral.com/' }} target='_blank'>http://www.cookiecentral.com/</Link></p>
            <p>We use session cookies to store the secure session and browsing preferences of the user. We set a persistent cookie to store your username and interests so you don't have to enter it more than once. Persistent cookies also enable us to track and target the interests of our users to enhance the experience on the Platform.</p>
            <p>If you reject cookies, you may still use the Platform, but your ability to use some areas of the Platform, will be limited. Cookies are used in the shopping bag to enable enhanced security and to ensure there is no URL based spamming.</p>
            <p>Unless you voluntarily identify yourself (through registration, for example), we will have no way of knowing who you are, even if we assign a cookie to your computer. The only personal information a cookie can contain is information you supply (an example of this is when transact as a guest or add items to shopping bag). A cookie cannot read data off your hard drive.</p>
            <p>Some of our business partners (e.g., advertisers) set cookies while delivering banners on the Platform. We have no access to or control over these cookies.</p>
            <p>This privacy statement covers the use of cookies by the Platform only and does not cover the use of cookies by any advertisers.</p>
            <p>We do use cookies on point of collection pages of email address but we do not use them in emails.</p>
            <p>Our web servers automatically collect limited information about your computer's connection to the Internet, including your IP address, when you visit the Platform. (Your IP address is a number that lets computers attached to the Internet know where to send you data -- such as the web pages you view.) Your IP address does not identify you personally. We use this information to deliver our web pages to you upon request, to tailor the Platform to the interests of our customers, to measure traffic within the Platform and let advertisers know the geographic locations from where our visitors come.</p>
            <p>The Platform includes links to other websites. Such websites are governed by their respective privacy policies, which are beyond our control. Once you leave our servers (you can know where you are by checking the URL in the location bar on your browser), use of any information you provide is governed by the privacy policy of the operator of the website you are visiting. That policy may differ from ours. If you can't find the privacy policy of any of these websites via a link from the website's homepage, you should contact the website directly for more information.</p>
            <h4>Voluntary disclosure of information.</h4>
            <p>Supplying personally identifiable information is entirely voluntary. You are not required to register with us in order to use the Platforms. However, we offer some services only to visitors who do register</p>
            <p>You may change your interests at any time and may opt-in or opt-out of any marketing / promotional / newsletters mailings. Atum Life Private Limited reserves the right to send you certain service related communication, considered to be a part of your Platform account without offering you the facility to opt-out. You may update your information and change your account settings at any time.</p>
            <p>We provide you with the opportunity to opt-out of having your email address used for certain purposes, when we ask for this information. For example, if you purchase a product / service but do not wish to receive any additional marketing material from us, you can indicate your preferences. If you no longer wish to receive our newsletter and promotional communications, you may opt-out of receiving them by selecting your preferences in "My Account". If you still face an issue then you may mail us at info@atumlife.com.</p>
            <p>If your personally identifiable information or email address change, or if you no longer desire our service, you may correct, update, delete or deactivate it by emailing our Customer Support Mail at info@atumlife.com.</p>
            <p>Upon request, we will remove / block your personally identifiable information from our database, thereby canceling your registration. However, your information may remain stored in archive on our servers even after the deletion or the termination of your account.</p>
            <p>If we plan to use your personally identifiable information for any commercial purposes, we will notify you at the time we collect that information and allow you to opt-out of having your information used for those purposes.</p>
            <h4>What security procedures are in place to protect information from loss, misuse or alteration ?</h4>
            <p>To protect your privacy and security, we will verify your identity before granting access or making changes to your personally identifying information. If you have registered your profile on the Platform, your Platform ID and Password are required in order to access your profile information.</p>
            <p>To protect against the loss, misuse and alteration of the information under our control, we have in place appropriate physical, electronic and managerial procedures. For example, our servers are accessible only to authorized personnel and your information is shared with respective personnel on need to know basis to complete the transaction and to provide the services requested by you.</p>
            <p>Although we will endeavor to safeguard the confidentiality of your personally identifiable information, transmissions made by means of the Internet cannot be made absolutely secure. By using this Platform, you agree that Atum Life Private Limited will have no liability for disclosure of your information due to errors in transmission or unauthorized acts of third parties.</p>
            <p>If you have any questions about security on the Platform, you can send Mail at info@atumlife.com.</p>
            <p>All information gathered on the Platform is securely stored within the Atum Life Private Limited controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet and, of course, any information you include in a posting to the discussion areas is available to anyone with internet access.</p>
            <p>However the internet is an ever evolving medium. We may revise this Privacy Policy from time to time to incorporate necessary changes. Of course, our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be.</p>
            <p>We use third-party advertising companies to serve ads when you visit the Platform. These companies may use information (not including your name, address, email address or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.</p>
            <p>In accordance with Information Technology Act 2000 and the rules made there under, the contact address of the Grievance Officer are provided below:</p>
            <p>VISAKA TOWERS, Ground floor, S.P Road</p>
            <p>Hyderabad- 500003</p>
            <p>Telangana, India</p>
            <p>Email: info@atumlife.com.</p>
            <h4>How can you correct inaccuracies in the information ?</h4>
            <p>To correct or update any information you have provided, the Platforms allow you to do it online.</p>
            <h4>Policy Updates</h4>
            <p>We reserve the right to change or update this policy at any time by placing a prominent notice on the Platform. Such changes shall be effective immediately upon posting to this Platform.</p>
            <p>If we decide to change this Privacy Policy, we will post those changes to this privacy statement, the homepage, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to modify this privacy statement at any time, so please review it frequently. If we make material changes to this policy, we will notify you here, or by means of a notice on our homepage.</p>
            <p>If we decide to make changes in our email practices, we will post those changes to this privacy policy, the homepage, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.</p>
            <p>You can offer your views, suggestions, if any, by submitting the filled in feedback form online.</p>
            <h4>Secure Shopping</h4>
            <p>Shopping on the Platform is 100% safe. We use industry standard secure systems to protect your personal and payment information. For your safety, we do not store your complete credit card details or payment information in our systems, and this is directly transmitted securely to the bank for payment processing.</p>
            <p>Atum Life Private Limited is concerned about the safety and security of our customers. Accordingly, we have put a number of technological protections in place to ensure that our transaction process is extremely safe and that our customers' information is secure. For enhanced security Atum Life Private Limited does not store any financial information on its servers. All such information entered by you is directly received by our Banks servers and are then transmitted to your banks servers. All this is done through industry standard encryption protocol known as Secure Socket Layer (SSL). Majority of online transactions are completed without incident. However, please keep in mind that customer protection is a two-way street. When buying on any online venue, caution must always be practiced. Following are some guidelines that you should follow to ensure safe and secure online shopping experience.</p>
            <h4>Safety and Security Tips</h4>
            <h4>Protect your passwords</h4>
            <ul>
                <li>If using a public computer or terminal, always log out when you complete an online session.</li>
                <li>Keep your passwords private. Remember, anybody who knows your password may access your account.</li>
                <li>When creating a valid password, use letters and numbers only and it should be 6-15 characters long. A combination of letters and numbers is best. Do not use dictionary words, your name, e-mail address, or other personal information that can be easily obtained. It is also recommended that you frequently change your password.</li>
                <li>Avoid using the same password for multiple online accounts.</li>
            </ul>
            <h4>Be wary of unsolicited e-mail and telephone contacts.</h4>
            <ul>
                <li>Atum Life Private Limited will never e-mail or call a customer and ask that they disclose or verify their Platform password, credit card, or banking account number. Such information should only be submitted on the web page forms, when completing an order on the Platform or when making updates to Your Account areas and never on phone or e-mail to us.</li>
                <li>If you are contacted by, or receive an unsolicited e-mail from an unknown entity impersonating as Atum Life Private Limited or any of its sister concerns or associates, and are asked to confirm or provide your password or personal or banking information, simply disregard the request and report the incident to Atum Life Private Limited for investigation.</li>
                <li>Customers should always carefully review order and shipment confirmation e- mails to ensure that they are legitimate and have been sent by Atum Life Private Limited. To do this, simply cross-reference confirmation e-mails with the transaction history found in your account area. If you receive shipment confirmation e-mail for a transaction you have not done, report the incident to Atum Life Private Limited.</li>
                <li>A word of caution is that never disclose any confidential information such as password in any communication with us or with any of our employee. Atum Life Private Limited will never ask for such information over e-mails or phone.</li>
                <li>If you receive an e-mail asking for personal details such as password never respond to it.</li>
                <li>A phishing email will normally have a tone of urgency – like "Your account will be temporarily suspended" and therefore please "Login to keep your account active." Please remember Atum Life Private Limited will NEVER send such emails to its customers.</li>
                <li>If you are doubtful about the credibility of the email, please DO NOT click on any link provided in the email. This may trigger malicious codes being installed on your PC. You must report the incident to Atum Life Private Limited</li>
                <li>If you receive a suspected spoofed Atum Life Private Limited e-mail, or discover a fake Atum Life Private Limited website / app, please report the incident to us.</li>
            </ul>
            <h4>Ensure your Personal Computer is protected</h4>
            <p>Fraudsters send genuine-looking emails that contain viruses and Trojans (A Trojan refers to a program that appears to be safe, but hidden inside, is usually something harmful, probably a virus). These viruses / Trojans, install a malicious code on your PC without your knowledge. They extract your confidential information that fraudsters use to access your online profile / account etc.</p>
            <h4>Here are a few important action points that you should bear in mind</h4>
            <ul>
                <li>Keep your PC updated with the latest anti-virus / anti- spyware software.</li>
                <li>Install a personal firewall on your PC to protect your account.</li>
                <li>Keep your PC updated with the latest security patches and most importantly.</li>
                <li>Do not click on links or open attachments in unknown or unsolicited (spam) emails.</li>
            </ul>
            <h4>Privacy Policy for Chatbot</h4>
            <p>Please read the below section applicable for chatbot. Use the application only if you understand and agree with all guidelines mentioned hereby.</p>
            <h4>Information Collection and Use on Chatbot</h4>
            <p>Once you sign up to use our application, we get access to your email address, public profile and your friend list on Facebook. We collect your name and email address for identification and authentication. We also have access to queries posted by you on our chat bot messenger application. Your queries help us analyse your demographic preferences, interests and provide you with a better user experience. We do not collect any financial information like credit card or bank account details from any of our users.</p>
            <h4>Information Security</h4>
            <p>We ensure utmost security of your personal information. We don’t store your Facebook password. To prevent security breach and unauthorised access, we have implemented commercially viable measures, administratively, physically and electronically, to safeguard all your data that we collect online.</p>
            <p>If you use the Atum Life Private Limited chatbot, You shall be responsible for maintaining the confidentiality of your Display Name and Password and you shall be responsible for all activities that occur under your Display Name and Password.</p>
            <p>You agree that if you provide any information that is untrue, inaccurate, not current or incomplete, we shall have the right to indefinitely suspend or terminate or block access of your membership on the Platform.</p>
            <p>You agree to immediately notify Atum Life Private Limited of any unauthorized use / breach of your password or account.</p>
            <h4>Cookies Policy</h4>
            <p>A cookie is a small text file that we place on your device to enable various features of the Platform. "Cookies" are used to store user preferences and to track user trends, so as to enhance your interactive experience and generally improve our Services to you. You can change your cookie settings to accept or not accept cookies in your browser settings. If you do accept a "cookie", you thereby agree to our use of any Personal Information collected by us using that Cookie that Cookie.</p>
            <h4>Third Party Links</h4>
            <p>We occasionally tie-up with third parties to make available certain offers, events or special schemes or provide you with exciting features. In such instances, your personal information may be shared with such third parties and/or may become available to them or be disclosed to them, such third parties may have their own applicable privacy rules and we shall not be liable for the use or misuse of your information by such third parties.</p>
            <p>You acknowledge that, we will only disclose your information in an aggregated form which is not capable of being used or interpreted in such a manner as to identify you.</p>
            <h4>Information Sharing and Disclosures</h4>
            <p>On certain circumstances, we may share your personal information to legal entities, law enforcement agencies or government authority if needed as per law.</p>
            <h4>Amendments</h4>
            <p>The privacy policy statement is liable to be periodically reviewed. Whenever we revise and modify the policy, the same will be updated online. We request you to visit our privacy policy statement frequently for any updates. Continued use of service will constitute your acknowledgement and acceptance of the terms of the updated policy guidelines. If you do not agree with any changes in the policy, whatsoever, please stop using the application with immediate effect.</p>
        </div>
    )
}