import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Container } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { Column } from "primereact/column";
import { GLOBAL_DOMAIN, Api_Headers, IMG_GLOBAL } from '../../Config/Config';
// import  ProfileSVG  from '../../Assets/profile.svg';
import axios from 'axios';
import { Card } from 'primereact/card';
import { Password } from 'primereact/password';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useHistory, Link } from 'react-router-dom';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './UserProfile.css';
import DeliveryAddress from '../Payments/DeliveryAddress/DeliveryAddress';
import { BreadCrumb } from 'primereact/breadcrumb';
import BreadCrumbCard from '../../Components/Breadcrumb/Breadcrumb';
import Account_Circle_Icon from '../../Assets/Atum_Icons/Account_circle_icon.svg';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputNumber } from 'primereact/inputnumber';

const UserProfile = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [current_password, setCurrent_Password] = useState('');
    const [new_password, setNew_Password] = useState('');
    const [confirm_password, setConfirm_Password] = useState('');
    const [userData, setUserData] = useState({});
    const [userAddresses, setUserAddresses] = useState([]);
    const [editstatus, seteditstatus] = useState(false);
    const [editemail, seteditemail] = useState(false);
    const [editnumber, seteditnumber] = useState(false);
    const [changepassword, setchangepassword] = useState(false);
    const [PasswordFormData, setPasswordFormData] = useState({});
    const [errorflag, seterrorflag] = useState(false);
    const [errormessage, seterrormessage] = useState(false);
    const user_data = JSON.parse(window.localStorage.getItem('User_data'));
    const history = useHistory();
    const breadcrumb_data = [
        { label: 'My Account' },
    ];

    useEffect(() => {
        window.scrollTo(0, 0)
        getUserInfo();
        getUserAddress();
    }, []);

    // const passwordForm  = () => {
    //     PasswordForm.setFieldValue('CURRENT_PASSWORD',Password.CURRENT_PASSWORD)
    //     PasswordForm.setFieldValue('NEW_PASSWORD',Password.NEW_PASSWORD)
    //     PasswordForm.setFieldValue('CONFIRM_PASSWORD',Password.CONFIRM_PASSWORD)
    // }

    const PasswordForm = useFormik({

        initialValues: {
            CURRENT_PASSWORD: "",
            NEW_PASSWORD: "",
            CONFIRM_PASSWORD: "",
        },
        validate: (data) => {
            let errors = {};
            if (!data.CURRENT_PASSWORD) {
                errors.CURRENT_PASSWORD = 'CURRENT PASSWORD is required.';
            }
            if (!data.NEW_PASSWORD) {
                errors.NEW_PASSWORD = 'NEW PASSWORD is required.';
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i.test(data.NEW_PASSWORD)) {
                errors.NEW_PASSWORD = 'Invalid Valid Password like "Password@123"';
            }
            if (!data.CONFIRM_PASSWORD) {
                errors.CONFIRM_PASSWORD = 'CONFIRM PASSWORD is required.';
            }
            if (data.NEW_PASSWORD && data.CONFIRM_PASSWORD) {
                if (data.NEW_PASSWORD !== data.CONFIRM_PASSWORD) {
                    errors.CONFIRM_PASSWORD = "Password not matched";
                }
            }
            return errors;
        },
        onSubmit: (data) => {
            setPasswordFormData(data);
            console.log('Password From Data', data)
            updatePassword(data)
        }
    })

    const resetaddress = () => {
        PasswordForm.resetForm();
    }

    const isFormFieldValid = (name) => !!(PasswordForm.touched[name] && PasswordForm.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{PasswordForm.errors[name]}</small>;
    };

    const getUserInfo = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
        }).then((res) => {
            console.log(res);
            setUserData(res.data);
            setFirstName(res.data.FIRST_NAME);
            setLastName(res.data.LAST_NAME);
            setEmail(res.data.EMAIL);
            setMobileNumber(res.data.MOBILE_NUMBER);
        }).catch((err) => {
            console.log(err);
        });
    }
    const getUserAddress = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/enduseraddresses?type=list&limit=100&userId=' + user_data.data.userData.ID,
        }).then((res) => {
            setUserAddresses(res.data.rows);
            // console.log('enduseraddressses==============', res.data.rows);
        }).catch((err) => {
            console.log(err);
        });
    }
    // const submitHandler = () => {

    // };


    const updateProfile = () => {
        if (changepassword) {
            let obj = {
                'CURRENT_PASSWORD': current_password,
                'NEW_PASSWORD': confirm_password
            }
        }
        let obj = {
            'FIRST_NAME': firstName,
            'LAST_NAME': lastName,
            'MOBILE_NUMBER': mobileNumber,
            'EMAIL': email,
        };
        axios({
            method: 'PUT',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
            data: obj,
        }).then((res) => {
            console.log(res.data);
            seteditstatus(false);
            getUserInfo();
            seterrorflag(false);
        }).catch((err) => {
            console.log('Update password', err);
            seterrormessage(err.message);
            seterrorflag(true);
        });
    };

    const updatePassword = (data) => {
        let obj = {
            'CURRENT_PASSWORD': data.CURRENT_PASSWORD,
            'NEW_PASSWORD': data.CONFIRM_PASSWORD
        }
        console.log(obj)
        axios({
            method: 'PATCH',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID + '?action=updatePassword',
            data: obj,
        }).then((res) => {
            console.log(res.data);
            seteditstatus(false);
            resetaddress();
        }).catch((err) => {
            console.log(err);
        });
    };

    const accept = () => {
        axios({
            method: 'PATCH',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID + '?action=deleteuser',
        }).then((res) => {
            console.log(res.data);
            window.localStorage.clear();
            // setTimeout(() => {
            history.push('/login');
            //   }, 3000);

            // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        }).catch((err) => {
            console.log(err);
        });
    }

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const DeleteAccount = () => {
        confirmDialog({
            message: 'Do you want to delete Your Account?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    }
    const Passwordfooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 px-3 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );
    return (
        <section className='my-4'>
            <Container>
                <ConfirmDialog />
                <div className='mb-4'>
                    <Row>
                        <Col sm={12} md={10}>
                            <Row>
                                <Col sm={12} md={4} className=''>
                                    <Card className='d-flex justify-items-center justify-content-center'>
                                        <img src={userData.IMAGE_PATH === null ? Account_Circle_Icon : IMG_GLOBAL + userData.IMAGE_PATH} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                                        <p style={{ width: '120px' }} className='text-center'>{userData.FIRST_NAME} {userData.LAST_NAME}</p>
                                    </Card>
                                </Col>
                                <Col sm={12} md={8}>
                                    <Card>
                                        <React.Fragment>
                                            {errorflag && 
                                            <p className='text-center color_red'>{errormessage}</p>
                                            }
                                        </React.Fragment>
                                        <Form className=''>
                                            <Row>
                                                <Col md={9}>
                                                    <Form.Group className="input_field mb-3" controlId='name'>
                                                        <label htmlFor="First_Name" className="d-block mb-1 text-grey">First Name</label>
                                                        <InputText
                                                            type='text'
                                                            className='w-100'
                                                            id='First_Name'
                                                            placeholder='Enter your First name'
                                                            keyfilter={/[^\s]/}
                                                            value={firstName}
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                            disabled={!editstatus}
                                                        ></InputText>
                                                    </Form.Group>
                                                </Col>
                                                {editstatus == false ?
                                                    <Col md={3} className='mt-2 d-flex align-items-center'>
                                                        <Button type='button' className="p-button-text color_blue" onClick={() => seteditstatus(true)}>Edit</Button>
                                                    </Col>
                                                    :
                                                    <Col md={3} className='mt-2 d-flex align-items-center'>
                                                        <Button type='button' className="p-button-text color_blue" onClick={() => updateProfile()}>Save</Button>
                                                    </Col>
                                                }
                                                <Col md={9}>
                                                    <Form.Group className="input_field mb-3" controlId='name'>
                                                        <label htmlFor="Last_Name" className="d-block mb-1 text-grey">Last Name</label>
                                                        <InputText
                                                            type='text'
                                                            id='Last_Name'
                                                            className='w-100'
                                                            placeholder='Enter your Last name'
                                                            keyfilter={/[^\s]/}
                                                            value={lastName}
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            disabled={!editstatus}
                                                        ></InputText>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={9}>
                                                    <Form.Group className="input_field mb-3" controlId='email'>
                                                        <label htmlFor="Email" className="d-block mb-1 text-grey">Email</label>
                                                        <span className="p-input-icon-right w-100">
                                                            {userData.IS_EMAIL_VERIFIED == 1 &&
                                                                <i className="pi pi-check color_head" />
                                                            }
                                                            <InputText
                                                                type='email'
                                                                id='Email'
                                                                className='w-100'
                                                                placeholder='Enter email'
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                disabled={!editemail}
                                                            ></InputText>
                                                        </span>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3} className='mt-2 d-flex align-items-center'>
                                                    {(email != '' && editemail == false) ?
                                                        <Button type='button' className="p-button-text color_blue" label="Edit" onClick={() => { seteditemail(true); seteditnumber(false); seteditstatus(false) }}></Button>
                                                        :
                                                        <Button type='button' className="p-button-text color_blue" label="Save" disabled={email == '' || (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email))} onClick={() => { seteditemail(false); updateProfile() }}></Button>
                                                    }
                                                </Col>
                                                <React.Fragment>
                                                    <div className='mb-2' style={{marginTop:'-15px'}}>
                                                    {email === "" &&
                                                        <small id="mobile_error" className="p-error block">Email is requied.</small>}
                                                    {email && (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) &&
                                                        <small id="mobile_error" className="p-error block">Please enter valid Email Address.</small>}
                                                    </div>
                                                </React.Fragment>
                                                <Col xs={9} md={9}>
                                                    <Form.Group className="input_field mb-3" controlId='tel'>
                                                        <label htmlFor="Phone_Number" className="d-block mb-1 text-grey">Phone Number</label>
                                                        <span className="p-input-icon-right w-100">
                                                            {userData.IS_EMAIL_VERIFIED == 1 &&
                                                                <i className="pi pi-check color_head" />
                                                            }
                                                            <InputText
                                                                type='tel'
                                                                id='Phone_Number'
                                                                className='w-100'
                                                                placeholder='Enter Mobile No.'
                                                                maxlength={10}
                                                                minLength={10}
                                                                required
                                                                keyfilter={/[0-9]/}
                                                                value={mobileNumber}
                                                                onChange={(e) => setMobileNumber(e.target.value)}
                                                                disabled={!editnumber}
                                                            ></InputText >
                                                        </span>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={3} md={3} className='mt-2 d-flex align-items-center'>
                                                    {/* {userData.MOBILE_NUMBER == null && userData.IS_MOBILE_NUMBER_VERIFIED == 0 ? */}
                                                    <React.Fragment>
                                                        {editnumber == false ?
                                                            <Button type='button' className="p-button-text color_blue" label="Edit" onClick={() => { seteditnumber(true); seteditemail(false); seteditstatus(false) }}></Button>
                                                            :
                                                            <Button type='button' className="p-button-text color_blue" label="Save" disabled={mobileNumber==='' || mobileNumber?.length > 10 || mobileNumber?.length < 10 || mobileNumber && (!/^[6-9]\d{9}$/i.test(mobileNumber))} onClick={() => { seteditnumber(false); updateProfile() }}></Button>
                                                        }
                                                    </React.Fragment>
                                                    {/* :
                                                        <React.Fragment>
                                                            <Button type='button' className="p-button-text color_blue" label="Verify"></Button>
                                                        </React.Fragment>
                                                    } */}

                                                </Col>
                                                <React.Fragment>
                                                    <div className='mb-2' style={{marginTop:'-15px'}}>
                                                    {mobileNumber === "" &&
                                                        <small id="mobile_error" className="p-error block">Mobile is requied.</small>}
                                                    {mobileNumber && (mobileNumber?.length > 10 || mobileNumber?.length < 10 || (!/^[6-9]\d{9}$/i.test(mobileNumber))) &&
                                                        <small id="mobile_error" className="p-error block">Please enter valid Mobile Number.</small>}
                                                    </div>
                                                </React.Fragment>
                                            </Row>
                                            <div className='d-flex justify-content-between'>
                                                <div><p className='change_password color_blue fw-500' onClick={() => setchangepassword(true) || seteditstatus(false)}><ins>Change Password</ins></p></div>
                                                {/* <div><p className='color_red cursor_poniter' onClick={() => DeleteAccount()}><span><i className="pi pi-trash mx-1"></i></span>Delete Account</p></div> */}
                                            </div>
                                            {changepassword &&
                                                <div>
                                                    <Row>
                                                        <Col md={9}>
                                                            <Form.Group className="input_field mb-3" controlId='name'>
                                                                <label htmlFor="CURRENT_PASSWORD" className="d-block mb-1 text-grey">Current Password</label>
                                                                <InputText
                                                                    type='text'
                                                                    id='CURRENT_PASSWORD'
                                                                    placeholder='Enter your Current Password'
                                                                    value={PasswordForm.values.CURRENT_PASSWORD}
                                                                    onChange={PasswordForm.handleChange}
                                                                    className={(classNames({ 'p-invalid': isFormFieldValid('CURRENT_PASSWORD') }))}
                                                                ></InputText>
                                                                <small>{getFormErrorMessage('CURRENT_PASSWORD')}</small>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={9}>
                                                            <Form.Group className="input_field mb-3" controlId='name'>
                                                                <label htmlFor="NEW_PASSWORD" className="d-block mb-1 text-grey">New Password</label>
                                                                <Password
                                                                    type='text'
                                                                    name='NEW_PASSWORD'
                                                                    toggleMask
                                                                    id='NEW_PASSWORD'
                                                                    footer={Passwordfooter}
                                                                    placeholder='Enter your New Password'
                                                                    value={PasswordForm.values.NEW_PASSWORD}
                                                                    onChange={PasswordForm.handleChange}
                                                                    className={`w-100 $(classNames({ 'p-invalid': isFormFieldValid('NEW_PASSWORD') }))`}
                                                                ></Password >
                                                                <small>{getFormErrorMessage('NEW_PASSWORD')}</small>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={9}>
                                                            <Form.Group className="input_field mb-3" controlId='name'>
                                                                <label htmlFor="CONFIRM_PASSWORD" className="d-block mb-1 text-grey">Confirm Password</label>
                                                                <InputText
                                                                    type='Password'
                                                                    id='CONFIRM_PASSWORD'
                                                                    placeholder='Re Enter your Password'
                                                                    value={PasswordForm.values.CONFIRM_PASSWORD}
                                                                    onChange={PasswordForm.handleChange}
                                                                    className={(classNames({ 'p-invalid': isFormFieldValid('CONFIRM_PASSWORD') }))}
                                                                ></InputText>
                                                                <small>{getFormErrorMessage('CONFIRM_PASSWORD')}</small>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                            {changepassword &&
                                                <div className='primary_btn mb-3 d-flex'>
                                                    <Button className='p-button-text mx-2 text-black' onClick={() => seteditstatus(false) || setchangepassword(false)}>
                                                        Cancel
                                                    </Button>
                                                    <Button className='p-button mx-2' onClick={PasswordForm.handleSubmit}>
                                                        Update Password
                                                    </Button>
                                                </div>
                                            }

                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={2}></Col>
                    </Row>
                </div>
                {/* <Row>
                    <Col md={4} className="order-section card p-3 h-50" style={{
                        overflow: 'auto',
                        position: 'sticky',
                        top: '10%'
                    }}>
                        <div className='d-flex justify-content-between mb-3'>
                            <div className='d-flex'>
                                <i className="pi pi-user" style={{ 'fontSize': '2em' }}></i>
                                <h4 className='mx-3'>
                                    <sapn>{userData !== '' ? userData.FIRST_NAME : 'Guest'}</sapn>
                                </h4>
                            </div>
                            <div className='d-flex align-items-center'>
                                <i className="pi pi-user-edit" style={{ 'fontSize': '1.5em' }} onClick={() => seteditstatus(true)}></i>
                            </div>
                        </div>
                        <Form className=''>
                            <Row>
                            <Col md={12}>
                            <Form.Group className="field mb-3" controlId='name'>
                                <label htmlFor="First_Name" className="d-block mb-1">First Name</label>
                                <InputText
                                    type='text'
                                    className='form-control'
                                    id='First_Name'
                                    placeholder='Enter your First name'
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    disabled={!editstatus}
                                ></InputText>
                            </Form.Group>
                            </Col>

                            <Col md={12}>
                            <Form.Group className="field mb-3" controlId='name'>
                                <label htmlFor="Last_Name" className="d-block mb-1">Last Name</label>
                                <InputText
                                    type='text'
                                    id='Last_Name'
                                    className='form-control'
                                    placeholder='Enter your Last name'
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    disabled={!editstatus}
                                ></InputText>
                            </Form.Group>
                            </Col>

                            <Col md={12}>
                            <Form.Group className="field mb-3" controlId='email'>
                                <label htmlFor="Email" className="d-block mb-1">Email</label>
                                <InputText
                                    type='email'
                                    id='Email'
                                    className='form-control'
                                    placeholder='Enter email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled
                                ></InputText>
                            </Form.Group>
                            </Col>

                            <Col md={12}>
                            <Form.Group className="field mb-3" controlId='tel'>
                                <label htmlFor="Phone_Number" className="d-block mb-1">Phone Number</label>
                                <InputText
                                    type='tel'
                                    id='Phone_Number'
                                    className='form-control'
                                    placeholder='Enter Mobile No.'
                                    maxlength="10"
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                    disabled
                                ></InputText>
                            </Form.Group>
                            </Col>
                        </Row>
                            {changepassword &&
                                <div>
                                    <hr />
                                    <Form.Group className="field mb-3" controlId='name'>
                                        <label htmlFor="CURRENT_PASSWORD" className="d-block mb-1">Current Password</label>
                                        <InputText
                                            type='text'
                                            id='CURRENT_PASSWORD'
                                            placeholder='Enter your Current Password'
                                            value={PasswordForm.values.CURRENT_PASSWORD}
                                            onChange={PasswordForm.handleChange}
                                            className={(classNames({ 'p-invalid': isFormFieldValid('CURRENT_PASSWORD') }))}
                                        ></InputText>
                                        <small>{getFormErrorMessage('CURRENT_PASSWORD')}</small>

                                    </Form.Group>
                                    <Form.Group className="field mb-3" controlId='name'>
                                        <label htmlFor="NEW_PASSWORD" className="d-block mb-1">New Password</label>
                                        <InputText
                                            type='text'
                                            id='NEW_PASSWORD'
                                            placeholder='Enter your New Password'
                                            value={PasswordForm.values.NEW_PASSWORD}
                                            onChange={PasswordForm.handleChange}
                                            className={(classNames({ 'p-invalid': isFormFieldValid('NEW_PASSWORD') }))}
                                        ></InputText>
                                        <small>{getFormErrorMessage('NEW_PASSWORD')}</small>

                                    </Form.Group>
                                    <Form.Group className="field mb-3" controlId='name'>
                                        <label htmlFor="CONFIRM_PASSWORD" className="d-block mb-1">Confirm Password</label>
                                        <InputText
                                            type='Password'
                                            id='CONFIRM_PASSWORD'
                                            placeholder='Re Enter your Password'
                                            value={PasswordForm.values.CONFIRM_PASSWORD}
                                            onChange={PasswordForm.handleChange}
                                            className={(classNames({ 'p-invalid': isFormFieldValid('CONFIRM_PASSWORD') }))}
                                        ></InputText>
                                        <small>{getFormErrorMessage('CONFIRM_PASSWORD')}</small>
                                    </Form.Group>
                                </div>
                            }
                            {editstatus == true &&
                                <div>
                                    <div className='mb-3' style={{ display: 'flex', justifyContent: 'end' }}>
                                        <Button className='form-control btn_p mx-2' variant="success" onClick={() => updateProfile()}>
                                            Update
                                        </Button>
                                        <Button className='form-control btn_p mx-2' variant="success" onClick={() => seteditstatus(false)}>
                                            Cancel
                                        </Button>
                                    </div>
                                    <hr />
                                    <div className='d-flex justify-content-between'>
                                        <div><p className='change_password' onClick={() => setchangepassword(true) || seteditstatus(false)}>Change Password</p></div>
                                        <div><p className='color_red cursor_poniter' onClick={() => DeleteAccount()}><span><i className="pi pi-trash mx-1"></i></span>Delete Account</p></div>
                                    </div>
                                </div>
                            }
                            {changepassword &&
                                <div className='mb-3 d-flex justify-content-center'>
                                    <Button className='form-control btn_p mx-2' variant="success" onClick={PasswordForm.handleSubmit}>
                                        Update
                                    </Button>
                                    <Button className='form-control btn_p mx-2' variant="success" onClick={() => seteditstatus(false) || setchangepassword(false)}>
                                        Cancel
                                    </Button>
                                </div>
                            }

                        </Form>
                    </Col>
                    <Col xs={12} md={8}>
                        <div className='row mx-3'>
                            <DeliveryAddress />
                        </div>
                    </Col>
                </Row> */}
            </Container>
        </section>
    )
}
export default UserProfile;