import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const Layout = ({ children }) => {
    return (
        <section>
            <Header />
            <div className="mt-140">
                <main>{children}</main>
            </div>
            <Footer />
        </section>
    )
}

export default Layout;