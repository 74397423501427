import React, {useEffect} from 'react';

export default function Terms(){
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='mt-5 pt-5 container'>
            <h3 style={{textAlign: 'center'}}>Terms and Conditions</h3>

            <div>
                <h4>1. Introduction</h4>
                <p>Welcome to the Atum Life ("Website") service provided by Atum Life Private Limited. This website/app/chatbot is owned and operated by Atum Life Private Limited. Registered office: Visaka Towers, Ground Floor. S.P Road, Secunderabad, Hyderabad, Telangana – 500 003. In using the Atum Life service, of Atum Life Private Limited you are deemed to have accepted the Terms and Conditions of the agreement listed below or as may be revised from time to time, which is, for an indefinite period and you understand and agree that you are bound by such terms till the time you access this website/app/chatbot. If you want to ask us anything about these terms & conditions or have any comments or complaints on or about our website/app/chatbot, please write to us at info@atumlife.com.</p>
            </div>

            <div>
                <h4>2. Payment Terms</h4>
                <p>All payments are non-refundable (except as expressly set forth in the Terms and Conditions).</p>
            </div>

            <div>
                <h4>3. Terms & Conditions for COD</h4>
                <h4>3.1. Ownership of Rights</h4>
                <p>All rights, including copyright, in this website/app/chatbot are owned by or licensed to Atum Life Private Limited. Any use of this website/app/chatbot or its contents, including copying or storing it or them in whole or part, other than for your own personal, non- commercial use is prohibited without the permission of Atum Life Private Limited. You may not modify, distribute or re-post something on this website/app/chatbot for any purpose.</p>
                <h4>3.2. Accuracy of Content and Invitation to Offer</h4>
                <p>Atum Life has taken every care in the preparation of the content of this website/app/chatbot, in particular to ensure that prices quoted are correct at time of publishing and all products have been fairly described. All prices are displayed inclusive of GST. Packaging may vary from that shown. The weights, dimensions and capacities given are approximate only. We have made every effort to display as accurately as possible the colours of our products that appear on the website/app/chatbot. However, as the actual colours you see will depend on your monitor, we cannot guarantee that your monitor's display of any colour will accurately reflect the colour of the product on delivery. To the extent permitted by applicable law, Atum Life disclaims all warranties, express or implied, as to the accuracy of the information contained in any of the materials on this website/app/chatbot. Atum Life shall not be liable to any person for any loss or damage which may arise from the use of any of the information contained in any of the materials on this website/app/chatbot.</p>
                <p>All products/services and information displayed on Atum Life ("Website/app/chatbot") constitute an invitation to offer. Your order for purchase constitutes your offer which shall be subject to the terms and conditions as listed herein. Atum Life reserves the right to accept or reject your offer in part or in full. Our acceptance of your order will take place upon dispatch of the product(s) ordered. Dispatch of all the product(s) ordered, may or may not happen at the same time, in such a scenario that portion of the order which has been dispatched will be deemed to have been accepted by Atum Life and the balance would continue to be on offer to Atum Life and Atum Life reserves the right to accept or reject such balance order. No act or omission of Atum Life prior to the actual dispatch of the product (s) ordered will constitute acceptance of your offer. If you have supplied us with your email address, we will notify you by email as soon as possible to confirm receipt of your order and email you again to confirm dispatch and therefore acceptance of the order.</p>
                <h4>3.3. Eligibility to Use</h4>
                <p>Use of the Website/app/chatbot is available only to persons who can form legally binding contracts under applicable law. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Website/app/chatbot. If your age is below 18 years you are prohibited to use/purchase/contract from/with this website/app/chatbot. Such persons are also prohibited from purchasing any material which is for adult consumption the sale or purchase of which to/by minors are strictly prohibited. If you are registering as a business entity, you represent that you have the authority to bind the entity to this User Agreement. Unless otherwise specified, the materials on this website/app/chatbot are directed solely at those who access this web from India. Atum Life makes no representation that any products or services referred to in the materials on this web are appropriate for use, or available outside India. Those who choose to access this website/app/chatbot from outside India are responsible for compliance with local laws if and to the extent local laws are applicable. Atum Life will deliver the products only within in India and will not be liable for any claims relating to any products ordered from outside India. Some Indian states prohibit direct sale of merchandise from other states and require special documentation to effect such a sale without dual taxation, if Atum Life receives a order from such states or to be delivered to such states (to see a list please refer our shipping policy) under such circumstances Atum Life retains the right to accept or reject the order.</p>
                <p>Those who choose to access this website/app/chatbot from Indian states which restrict such use are responsible for compliance with local laws if and to the extent local state laws are applicable. Atum Life will deliver the products only within states having open import policy and will not be liable for any claims relating to any products ordered from restricted states.</p>
                <p>Except where additional terms and conditions are provided which are product specific, these terms and conditions supersede all previous representations, understandings, or agreements and shall prevail notwithstanding any variance with any other terms of any order submitted. By using the services of Atum Life you agree to be bound by the Terms and Conditions.</p>
                <h4>3.4. Account and registration obligations</h4>
                <p>Customers opting for guest checkout will be automatically registered once order is successfully placed.</p>
                <p>Your Information is defined as any information you provide to us in the registration, buying or listing process, in the feedback area or through any e-mail feature or a written letter or form mailed or submitted to any of our stores and offices. We will protect your Information in accordance with our Privacy Policy . If you use the Website/app/chatbot, you are responsible for maintaining the confidentiality of your password and account and any activities that occur under your account. Atum Life shall not be liable to any person for any loss or damage which may arise as a result of any failure by you to protect your password or account. If you know or suspect that someone else knows your password or suspect any unauthorized use of your password you should notify us by contacting us immediately through the address provided in the beginning of this agreement. If Atum Life has reason to believe that there is likely to be a breach of security or misuse of the Atum Life Website/app/chatbot, we may require you to change your password or we may suspend your account without any liability to Atum Life.</p>
                <p>Atum Life does not want to receive confidential, proprietary or trade secret information through this Website/app/chatbot (excluding information related to any order you submit). Please note that any information, materials, suggestions, ideas or comments sent to Atum Life will be deemed non-confidential. By submitting any such information, you are granting Atum Life an irrevocable and unrestricted license to use, modify, reproduce, transmit, display and distribute such materials, information, suggestions, ideas or comments for any purpose whatsoever. However, we will not use your name in connection with any such materials, information, suggestions, and ideas or comments unless we first obtain your permission or otherwise are required by law to do so.</p>
                <h4>3.5. You also agree and confirm:</h4>
                <p>
                    <ul>
                        <li>To provide true, accurate, current and complete information about yourself as prompted by Atum Life registration form or guest details form (such information being the "Registration Data").</li>
                        <li>To maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, incomplete, or not current or if Atum Life has reasonable grounds to suspect that such information is untrue, inaccurate, and not current or not in accordance with the User Agreement, Atum Life has the right to indefinitely suspend or terminate your membership and refuse to provide you with access to the Website/app/chatbot.</li>
                        <li>That in the event that a non- delivery occurs on account of a mistake by you (i.e. wrong name or address or any other wrong information) any extra cost incurred by Atum Life for redelivery shall be claimed from you and you shall pay such cost.</li>
                        <li>That you will use the services provided by Atum Life, its affiliates, consultants and contracted companies, for lawful purposes only and comply with all applicable laws and regulations while using the Website/app/chatbot and transacting on the Website/app/chatbot.</li>
                        <li>You will provide authentic and true information in all instances where such information is requested of you. Atum Life reserves the right to confirm and validate the information and other details provided by you at any point of time. If upon confirmation your details are found not to be true (wholly or partly), Atum Life has the right in its sole discretion to reject the registration and debar you from using the Services of Atum Life and or other affiliated website/app/chatbot without prior intimation whatsoever.</li>
                        <li>That you are accessing the services available on this Website/app/chatbot and transacting at your sole risk and are using your best and prudent judgment before entering into any transaction through this Website/app/chatbot.</li>
                        <li>That the address at which delivery of the product ordered by you is to be made will be correct and proper in all respects.</li>
                        <li>That before placing an order you will check the product description carefully. By placing an order for a product you agree to be bound by the conditions of sale and invitation of offer included in the item's description.</li>
                    </ul>
                </p>
                <h4>3.6. Usage Restrications</h4>
                <p>You shall not use the Website/app/chatbot for any of the following purposes:</p>
                <p>
                    <ul>
                        <li>Disseminating any unlawful, harassing, libelous, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable material.</li>
                        <li>Transmitting material that encourages conduct that constitutes a criminal offense, results in civil liability or otherwise breaches any relevant laws, regulations or code of practice.</li>
                        <li>Gaining unauthorized access to other computer systems.</li>
                        <li>Interfering with any other person's use or enjoyment of the Website/app/chatbot.</li>
                        <li>Breaching any applicable laws.</li>
                        <li>Interfering or disrupting networks or website/app/chatbot connected to the Website/app/chatbot.</li>
                        <li>Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner.</li>
                    </ul>
                </p>
                <h4>3.7. Exactness not guaranteed</h4>
                <p>Atum Life hereby disclaims any guarantees of exactness as to the finish and appearance of the final Product as ordered by the user.</p>
                <h4>3.8. Pricing Information</h4>
                <p>While Atum Life strives to provide accurate product and pricing information, pricing or typographical errors may occur. Atum Life cannot confirm the price of a product until after you order. In the event that a product is listed at an incorrect price or with incorrect information due to an error in pricing or product information, Atum Life shall have the right, at our sole discretion, to refuse or cancel any orders placed for that product, unless the product has already been dispatched. In the event that an item is mis-priced, Atum Life may, at its discretion, either contact you for instructions or cancel your order and notify you of such cancellation. Unless the product ordered by you has been dispatched, your offer will not be deemed accepted and Atum Life will have the right to modify the price of the product and contact you for further instructions using the e-mail address provided by you during the time of registration, or cancel the order and notify you of such cancellation. In the event that Atum Life accepts your order the same shall be debited to your credit / debit card account and duly notified to you by email that the payment has been processed. The payment may be processed prior to Atum Life dispatch of the product that you have ordered. If we have to cancel the order after we have processed the payment, the said amount will be reversed back to your credit / debit card account.</p>
                <h4>3.9. GST</h4>
                <p>Reduced price will be inclusive of GST.</p>
                <h4>3.10. In case of mispricing</h4>
                <p>Despite our best efforts, a small number of the many hundreds of items in our catalogue are mispriced. However, we verify prices as part of our dispatch procedures. If an item's correct price is lower than our stated price, we charge the lower amount and send you the item. If a product's correct price is higher than our stated price, we will cancel your order and notify you of that cancellation.</p>
                <p>We strive to provide you with the best value. However, sometimes a price online may not match the price in a store in your particular geographic region and store pricing may sometimes differ from online prices. Prices and availability are subject to change without notice.</p>
                <h4>3.11. Cancellation by Atum Life</h4>
                <p>Please note that there may be certain orders that we are unable to accept and must cancel. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason. Some situations that may result in your order being canceled include limitations on quantities available for purchase, inaccuracies or errors in product or pricing information, or problems identified by our credit and fraud avoidance department. We may also require additional verifications or information before accepting any order. We will inform you if all or any portion of your order is canceled or if additional information is required to accept your order. If your order is cancelled after your credit / debit card has been charged, the said amount will be reversed back in your Card.</p>
                <p>We will seek authorization on your Card for the aggregate amount of the order placed by you, however, we may charge your card (capture) only with the amount corresponding to the actual portion of the order that we are able/willing to fulfill, and any associated shipping charges/taxes and levies etc. Any surplus amount, for which we might have originally sought and received an authorization on your card from your Card Issuer, if not captured by us in the time period stipulated by our Merchant Banker, will not be captured / charged. If however the same is charged to your card / bank account in error, we will refund it as soon as the same is realized by us or brought to our notice, whichever is sooner. Atum Life reserves the right to capture / charge your card to the full or partial authorized amount for the concerned order.</p>
                <h4>3.12 Cancellation of fraudulent orders</h4>
                <p>To provide a safe and secure shopping experience, we regularly monitor transactions for fraudulent activity. In the event of detecting any suspicious activity, Atum Life reserves the right to cancel pending and future orders without any liability. Atum Life also reserves the right to refuse or cancel orders in scenarios like inaccuracies in pricing of product on website/app/chatbot and stock unavailability. We may also require additional verifications or information before accepting any order. If your order is cancelled after your card has been charged, the said amount will be reversed to your Card Account. Any promotional voucher used for the cancelled orders may not be refunded.</p>
                <h4>3.13. Cancellations by the customer</h4>
                <p>You may cancel your order or some items in your order before we bill and ship the items. You may also exchange the merchandise or get a refund by returning the merchandise (with the exception of perishables, made to order, or items of personal hygiene such as and cosmetics) in unused, saleable condition in its original packaging along with original tags, for a return or an exchange, within 14 days from the date on which these goods are delivered. For details of how to exercise this right please see our Returns Policy. Atum Life does not accept returns or exchanges of Groceries,Instant eatables fragrances, cosmetics, and any made to order or altered merchandise. In case we receive a cancellation notice and the order has not been processed /approved by us, we shall cancel the order and refund the entire amount, if any, charged to your card. However, The customer agrees not to dispute the decision made by Atum Life and accept Atum Life's decision regarding the cancellation.</p>
                <h4>3.14. Returns/Exchange at ATUM Life stores</h4>
                <p>
                    <ul>
                        <li>Most items are returnable in our stores, to check which items cannot be returned in our stores please call our customer care on 8341122922. You may visit any Atum Life store in India, and return the merchandise in original condition (unused, unwashed, unaltered, untampered & in saleable condition) with original tags, invoice & packaging intact.</li>
                        <li>The Atum Life does not issues any credit note as such,customer can exchange the products in return from the store merchandise of the same value.</li>
                        <li>Atum Life for the return of the merchandise, regardless of whether the payment for the original purchase was made by credit / debit card, e-gift voucher, or through any other means.</li>
                    </ul>
                </p>
                <h4>3.15. Financial Details</h4>
                <p>You agree, understand and confirm that the credit / debit card details or other financial details provided by you for availing of services on Atum Life will be correct and accurate and you shall not use the credit /debit card or financial facility which is not lawfully owned / obtained by you. You also understand that any financial information submitted by you is directly received by our acquiring bank and not stored by Atum Life. Atum Life will not be liable for any credit / debit card fraud. The liability for use of a card fraudulently will be on you and the onus to 'prove otherwise' shall be exclusively on you. All information collected by us shall be governed as per our Privacy Policy.</p>
                <h4>3.16 Fraudulent / Declined transactions</h4>
                <p>Atum Life and its associated acquiring bank or financial institutions reserve the right to recover the cost of goods, collection charges and lawyers fees from persons using the Website/app/chatbot fraudulently. Atum Life and its associated acquiring banks or financial institutions reserve the right to initiate legal proceedings against such persons for fraudulent use of the Website/app/chatbot and any other unlawful acts or acts or omissions in breach of these terms and conditions.</p>
                <h4>3.17. Electronic Communications</h4>
                <p>When you visit the Website/app/chatbot or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the Website/app/chatbot. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
                <h4>3.18. Colors</h4>
                <p>Our best effort is to display the colors of the products as accurate as possible. However, colors may appear slightly different on digital devices.</p>
                <h4>3.19. Reviews, feedback, submissions</h4>
                <p>All reviews, comments, feedback, postcards, suggestions, ideas, and other submissions disclosed, submitted or offered to Atum Life on or by this Website/app/chatbot or otherwise disclosed, submitted or offered in connection with your use of this Website/app/chatbot (collectively, the "Comments") shall be and remain Atum Life property. Such disclosure, submission or offer of any Comments shall constitute an assignment to Atum Life of all worldwide rights, titles and interests in all copyrights and other intellectual properties in the Comments. Thus, Atum Life owns exclusively all such rights, titles and interests and shall not be limited in any way in its use, commercial or otherwise, of any Comments. Atum Life will be entitled to use, reproduce, disclose, modify, adapt, create derivative works from, publish, display and distribute any Comments you submit for any purpose whatsoever, without restriction and without compensating you in any way. Atum Life is and shall be under no obligation (1) to maintain any Comments in confidence; (2) to pay you any compensation for any Comments; or (3) to respond to any Comments. You agree that any Comments submitted by you to the Website/app/chatbot will not violate this policy or any right of any third party, including copyright, trademark, privacy or other personal or proprietary right(s), and will not cause injury to any person or entity. You further agree that no Comments submitted by you to the Website/app/chatbot will be or contain libelous or otherwise unlawful, threatening, abusive or obscene material, or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any form of 'spam'.</p>
                <p>Atum Life does not regularly review posted Comments, but does reserve the right (but not the obligation) to monitor and edit or remove any Comments submitted to the Website/app/chatbot. You grant Atum Life the right to use the name that you submit in connection with any Comments. You agree not to use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of any Comments you submit. You are and shall remain solely responsible for the content of any Comments you make and you agree to indemnify Atum Life and its affiliates for all claims resulting from any Comments you submit. Atum Life and its affiliates take no responsibility and assume no liability for any Comments submitted by you or any third party.</p>
                <h4>3.20. Copyright & Trademark</h4>
                <p>Atum Life and its suppliers and licensors expressly reserve all intellectual property rights in all text, programs, products, processes, technology, content and other materials, which appear on this Website/app/chatbot. Access to this Website/app/chatbot does not confer and shall not be considered as conferring upon anyone any license under any of Atum Life or any third party's intellectual property rights. All rights, including copyright, in this website/app/chatbot are owned by or licensed to Atum Life . Any use of this website/app/chatbot or its contents, including copying or storing it or them in whole or part, other than for your own personal, non- commercial use is prohibited without the permission of Atum Life . You may not modify, distribute or re-post anything on this website/app/chatbot for any purpose.</p>
                <p>The Atum Life names and logos and all related product and service names, design marks and slogans are the trademarks or service marks of Atum Life Private Limited. All other marks are the property of their respective companies. No trademark or service mark license is granted in connection with the materials contained on this Website/app/chatbot. Access to this Website/app/chatbot does not authorize anyone to use any name, logo or mark in any manner.</p>
                <p>References on this Website/app/chatbot to any names, marks, products or services of third parties or hypertext links to third party website/app/chatbot or information are provided solely as a convenience to you and do not in any way constitute or imply Atum Life endorsement, sponsorship or recommendation of the third party, information, product or service.</p>
                <p>Atum Life is not responsible for the content of any third party website/app/chatbot and does not make any representations regarding the content or accuracy of material on such website/app/chatbot. If you decide to link to any such third party website/app/chatbot, you do so entirely at your own risk.</p>
                <p>All materials, including images, text, illustrations, designs, icons, photographs, programs, music clips or downloads, video clips and written and other materials that are part of this Website/app/chatbot (collectively, the 'Contents') are intended solely for personal, non- commercial use. You may download or copy the Contents and other downloadable materials displayed on the Website/app/chatbot for your personal use only. No right, title or interest in any downloaded materials or software is transferred to you as a result of any such downloading or copying. You may not reproduce (except as noted above), publish, transmit, distribute, display, modify, create derivative works from, sell or participate in any sale of or exploit in any way, in whole or in part, any of the Contents, the Website/app/chatbot or any related software. All software used on this Website/app/chatbot is the property of Atum Life or its suppliers and protected by Indian and international copyright laws. The Contents and software on this Website/app/chatbot may be used only as a shopping resource. Any other use, including the reproduction, modification, distribution, transmission, republication, display, or performance, of the Contents on this Website/app/chatbot is strictly prohibited. Unless otherwise noted, all Contents are copyrights, trademarks, trade dress and/or other intellectual property owned, controlled or licensed by Atum Life,one of its affiliates or by third parties who have licensed their materials to Atum Life and are protected by Indian and international copyright laws. The compilation (meaning the collection, arrangement, and assembly) of all Contents on this Website/app/chatbot is the exclusive property of Atum Life and is also protected by Indian and international copyright laws.</p>
                <h4>3.21. Objectionable Material</h4>
                <p>You understand that by using this Website/app/chatbot or any services provided on the Website/app/chatbot, you may encounter Content that may be deemed by some to be offensive, indecent, or objectionable, which Content may or may not be identified as such. You agree to use the Website/app/chatbot and any service at your sole risk and that to the fullest extent permitted under applicable law, Atum Life and its affiliates shall have no liability to you for Content that may be deemed offensive, indecent, or objectionable to you.</p>
                <h4>3.22 INDEMNITY</h4>
                <p>You agree to defend, indemnify and hold harmless Atum Life, its employees, directors, officers, agents and their successors and assigns from and against any and all claims, liabilities, damages, losses, costs and expenses, including attorney's fees, caused by or arising out of claims based upon your actions or inactions, which may result in any loss or liability to Atum Life or any third party including but not limited to breach of any warranties, representations or undertakings or in relation to the non-fulfillment of any of your obligations under this User Agreement or arising out of your violation of any applicable laws, regulations including but not limited to Intellectual Property Rights, payment of statutory dues and taxes, claim of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers and infringement of intellectual property or other rights. This clause shall survive the expiry or termination of this User Agreement.</p>
                <h4>3.23. Termination</h4>
                <p>This User Agreement is effective unless and until terminated by either you or Atum Life . You may terminate this User Agreement at any time by informing us in writing through Indian postal system by registered post, that you no longer wish to be associated with this website/app/chatbot, provided that you discontinue any further use of this Website/app/chatbot.</p>
                <p>Atum Life may terminate this User Agreement at any time and may do so immediately without notice, and accordingly deny you access to the Website/app/chatbot. Such termination will be without any liability to Atum Life. Upon any termination of the User Agreement by either you or Atum Life, you must promptly destroy all materials downloaded or otherwise obtained from this Website/app/chatbot, as well as all copies of such materials, whether made under the User Agreement or otherwise. Atum Life right to any Comments shall survive any termination of this User Agreement. Any such termination of the User Agreement shall not cancel your obligation to pay for the product already ordered from the Website/app/chatbot or affect any liability that may have arisen under the User Agreement.</p>
                <h4>3.24 Limitation of liability and disclaimers</h4>
                <p>The Website/app/chatbot is provided without any warranties or guarantees and in an "As Is" condition. You must bear the risks associated with the use of the Website/app/chatbot.</p>
                <p>The Website/app/chatbot provides content from other Internet website/app/chatbot or resources and while Atum Life tries to ensure that material included on the Website/app/chatbot is correct, reputable and of high quality, it cannot accept responsibility if this is not the case. Atum Life will not be responsible for any errors or omissions or for the results obtained from the use of such information or for any technical problems you may experience with the Website/app/chatbot.</p>
                <p>This disclaimer does not apply to any product warranty offered by the manufacturer of the product as specified in the product specifications. This disclaimer constitutes an essential part of this User Agreement.</p>
                <p>To the fullest extent permitted under applicable law, Atum Life or its suppliers shall not be liable for any indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or in connection with the Website/app/chatbot, its services or this User Agreement.</p>
                <p>Without prejudice to the generality of the section above, the total liability of Atum Life to you for all liabilities arising out of this User Agreement be it in tort or contract is limited to the amount charged to you, against the value of the products ordered by you.
                <p>Atum Life its associates and technology partners make no representations or warranties about the accuracy, reliability, completeness, currentness and/or timeliness of any content, information, software, text, graphics, links or communications provided on or through the use of the Website/app/chatbot or that the operation of the Website/app/chatbot will be error free and/or uninterrupted. Consequently, Atum Life assumes no liability whatsoever for any monetary or other damage suffered by you on account of the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Website/app/chatbot; and/or any interruption or errors in the operation of the Website/app/chatbot</p>
                <p>By providing your phone number to us, You consent to be contacted by us via phone calls and/or SMS notifications, in case of any transactional messages (i.e.) order, shipment delivery, returns, refund related updates. If your phone number is registered under the National Do Not Call Registry, we will not use your personal information to initiate any promotional phone calls or promotional SMS.</p></p>
                <h4>3.25. Website/app/chatbot security</h4>
                <p>You are prohibited from violating or attempting to violate the security of the Website/app/chatbot, including, without limitation,</p>
                <ul>
                    <li>Accessing data not intended for you or logging onto a server or an account which you are not authorized to access;</li>
                    <li>Attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization;</li>
                    <li>Attempting to interfere with service to any other user, host or network, including, without limitation, via means of submitting a virus to the Website/app/chatbot, overloading, 'flooding', 'spamming', 'mail bombing' or 'crashing';</li>
                    <li>Sending unsolicited email, including promotions and/or advertising of products or services; or Forging any TCP/IP packet header or any part of the header information in any email or newsgroup posting.</li>
                </ul>
                <p>Violations of system or network security may result in civil or criminal liability. Atum Life will investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations. You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of this Website/app/chatbot or any activity being conducted on this Website/app/chatbot. You agree, further, not to use or attempt to use any engine, software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search this Website/app/chatbot other than the search engine and search agents available from Atum Life on this Website/app/chatbot and other than generally available third party web browsers (e.g., Netscape Navigator, Microsoft Internet Explorer).</p>
                <h4>3.26. Entire Agreement</h4>
                <p>If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed to be superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect. Unless otherwise specified herein, this agreement constitutes the entire agreement between you and Atum Life with respect to the Atum Life website/app/chatbot/services and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and Atum Life with respect to the Atum Life website/app/chatbot/services. Atum Life's failure to act with respect to a breach by you or others does not waive its right to act with respect to subsequent or similar breaches.</p>
                <h4>3.27. Privacy Policy</h4>
                <p>We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent and we only use your information as described in the Privacy Policy. We view protection of your privacy as a very important community principle. We understand clearly that you and your Information is one of our most important assets. We store and process your Information on computers located in India and abroad that are protected by physical as well as technological security devices. We use third parties to verify and certify our privacy principles. If you object to your Information being transferred or used in this way please do not use the Website/app/chatbot.</p>
                <h4>3.28. Governing law and jurisdiction</h4>
                <p>This User Agreement shall be construed in accordance with the applicable laws of India. The Courts at Hyderabad shall have exclusive jurisdiction in any proceedings arising out of this agreement.</p>
                <p>Any dispute or difference either in interpretation or otherwise, of any terms of this User Agreement between the parties hereto, the same shall be referred to an independent arbitrator who will be appointed by Atum Life Private Limited. and his decision shall be final and binding on the parties hereto. The above arbitration shall be in accordance with the Arbitration and Conciliation Act, 1996 as amended from time to time. The arbitration shall be held in Hyderabad. The High Court of judicature at Hyderabad alone shall have the jurisdiction and the Laws of India shall apply.</p>
                <h4>3.29. Modification of terms and conditions of service</h4>
                <p>Atum Life may at any time modify the User Agreement without any prior notification to you. Subsequent to any modification of the User Agreement, we will inform you of the modifications in the User Agreement via email at the address provided by you while registering on Atum Life. You can access the latest version of the User Agreement at any given time on Atum Life. You should regularly review the User Agreement on Atum Life. In the event the modified User Agreement is not acceptable to you, you should discontinue using the service. However, if you continue to use the service you shall be deemed to have agreed to accept and abide by the modified User Agreement.</p>
            </div>
        </div>
    )
};