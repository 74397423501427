import error_img from '../Assets/error/error_img.jpg';
const Errorpage = () =>{
    return(
        <section className='mt-5'>
            <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                <div>
                    <img src={error_img} width='500px' height='500px' />
                </div>
            </div>
        </section>
    )
} 
export default Errorpage