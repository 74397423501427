import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Navbar, Nav, Row, Col, NavDropdown } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { GLOBAL_DOMAIN, IMG_GLOBAL, Api_Headers } from '../../Config/Config';
import { useHistory, Link, useLocation } from 'react-router-dom';
import Logo from '../../Assets/logo_white.svg';
import './Header.css';
import '../../App.css';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import blog_icon from '../../Assets/Atum_Icons/Blog_icon.svg';
import help_icon from '../../Assets/Atum_Icons/Help_icon.svg';
import cart_icon from '../../Assets/Atum_Icons/Cart_icon.svg';
import cart_icon_color from '../../Assets/Atum_Icons/Cart_icon_color.svg';
import profile_icon from '../../Assets/Atum_Icons/Profile_icon.svg';
import location_icon from '../../Assets/Atum_Icons/Location_icon.svg';
import { Sidebar } from 'primereact/sidebar';
// import { Dialog } from 'primereact/dialog';
import Login from '../../Pages/Login/Login';
import Wishlist_Icon from '../../Assets/icons/wishlist_white_icon.svg';
import Phone_Icon from '../../Assets/icons/phone_icon.svg';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import profile_icon_color from '../../Assets/Atum_Icons/Profile_icon_color.svg';
import Wallet_icon_color from '../../Assets/Atum_Icons/Wallet_icon_color.svg';
import notification_icon_color from '../../Assets/Atum_Icons/Notifications_icon_color.svg';
import logout_icon_color from '../../Assets/Atum_Icons/Logout_icon_color.svg';
import Store_icon from '../../Assets/Atum_Icons/Store icon.svg';
import Transaction_icon from '../../Assets/Atum_Icons/Transactions_icon.svg';
import useWindowDimensions from '../windowdimension';
import { Button } from 'primereact/button';
import Signup from '../../Pages/Signup/signup';

const Header = (props) => {

    const history = useHistory();
    const location = useLocation();
    const [user_data, setuserdata] = useState();
    const [search, setsearch] = useState('');
    // const [Selectedstore, setSelectedstore] = useState('');
    // const login_data = JSON.parse(window.localStorage.getItem('User_data'));
    const [visibleLeft, setVisibleLeft] = useState(false);
    const [visiblenotification, setvisiblenotification] = useState(false);
    const [Headercategories_data, setHeaderCategories] = useState([]);
    const [login_flag, setlogin_flag] = useState(false)
    const [signup_flag, setsignup_flag] = useState(false)
    const [storepickup, setstorepickup] = useState([]);
    const [SelectedstorepickupID, setSelectedstorepickupID] = useState(0);
    const [SelectedStoreData, setSelectedStoreData] = useState({});
    const [StoreVisible, setStoreVisible] = useState(false);
    const [WishlistVisible, setWishlistVisible] = useState(false);
    const [WishListData, setWishListData] = useState();
    const [Mobile_Menu, setMobile_Menu] = useState(false);
    const [notification_data, setnotification_data] = useState();
    // console.log(login_data);
    const toast = useRef(null);
    const { height, ScreenWidth } = useWindowDimensions();

    const showtoast = () => {
        toast.current.show({ severity: 'success', summary: 'Successfully added to cart', life: 3000 });
    }
    useEffect(() => {
        getstorepickupdata()
        GetUserData();
        getCategories_Data();
    }, []);

    const GetUserData = () => {
        if (JSON.parse(window.localStorage.getItem('User_data'))) {
            setuserdata(JSON.parse(window.localStorage.getItem('User_data')).data.userData);
            // console.log(JSON.parse(window.localStorage.getItem('User_data')).data.userData);
        } else {
            setuserdata('');
        };
    }
    // )

    const getCategories_Data = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/categories?type=list&limit=500&endUsers=true',
        }).then((res) => {
            // console.log('Categories Data :', res.data);
            setHeaderCategories(res.data.rows);
        }).catch((err) => {
            console.log(err);
        });
    }

    const getCatID = (CatID) => {
        // setCatId(GetCat.ID)
        localStorage.setItem('CATID', JSON.stringify(CatID));
        // console.log(CatID);
        setVisibleLeft(false)
        const data = { GRTCAT_ID: JSON.stringify(CatID) }
        history.push('/dashboard');
    }

    const searchdata = event => {
        event.preventDefault();
        localStorage.setItem('search_data', search);
        setsearch('');
        console.log(Headercategories_data[0]);
        localStorage.setItem('CATID', JSON.stringify(Headercategories_data[0]))
        history.push('/dashboard')
    }

    const getNotification = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/notifications?type=list&limit=1000&user_id=' + user_data.ID + '&userType=END_USER',
        }).then((res) => {
            setnotification_data(res.data.rows);
        }).catch((err) => {
            console.log(err);
        });
    }

    const notification_flag = () => {
        getNotification();
        setvisiblenotification(true);
    }
    const logout = () => {
        window.localStorage.clear();
        GetUserData();
        history.push('/home');
        window.location.reload();
    }

    const Login_flag = () => {
        setlogin_flag(true);
        Signup_flag_disable();
    }
    const login_enable_flag = useCallback(() => {
        Login_flag();
    })
    const login_flag_disable = useCallback(() => {
        setlogin_flag(false);
    })

    const flag_disable = useCallback(() => {
        setlogin_flag(false);
        window.location.reload();
        // GetUserData();
    }
    )

    const signup_enable = () => {
        setsignup_flag(true);
        login_flag_disable()
    }
    const Signup_flag_enable = useCallback(() => {
        signup_enable();
    })
    const Signup_flag_disable = useCallback(() => {
        setsignup_flag(false);
        // props.flag_Disable()
    }
    )
    const sign_disable = useCallback(() => {
        setsignup_flag(false);
        // props.flag_Disable()
    })

    const WishListClickFlag = () => {
        getWishList();
        setWishlistVisible(true);
    }

    const getWishList = () => {

        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/userswishlist?type=list&limit=100&userId=' + user_data?.ID,
        }).then((res) => {
            console.log(res);
            setWishListData(res.data)
        }).catch((err) => {
            console.log(err);
        });
    }

    const RemoveWishList = (event) => {
        axios({
            method: 'DELETE',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/userswishlist/' + event.ID,
        }).then((res) => {
            console.log(res);
            getWishList()
        }).catch((err) => {
            console.log(err);
        });
    }
    
    const AddtoCart = (item) => {
        axios({
            method: 'POST',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/usercart/',
            data: {
                "USER_ID": user_data.ID,
                "PRODUCT_ID": item.PRODUCT_ID,
                "QUANTITY": 1,
            },
        }).then((res) => {
            // console.log('CART Dataaa :', res.data);
            RemoveWishList(item)
            showtoast();
        }).catch((err) => {
            // console.log(err);
        });
    }

    const getstorepickupdata = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/stores?type=list&limit=20',
        }).then((res) => {
            setstorepickup(res.data.rows);
            setSelectedstorepickupID(res.data.rows[0].ID);
            setSelectedStoreData(res.data.rows[0]);

        }).catch((err) => {
            console.log(err);
        });
    }

    const selectedstorepickdata = (selectedstoredata_ID) => {
        setSelectedstorepickupID(selectedstoredata_ID);
        const SecletedStore_Data = storepickup.find((option) => (option.ID == selectedstoredata_ID));
        setSelectedStoreData(SecletedStore_Data);
        console.log(SecletedStore_Data);
    }

    const Stores_Header = (items, i) => {
        return (
            <div className="field-radiobutton" key={i}>
                <RadioButton inputId="Stores_Data" name="Store" value={items.ID} onChange={(e) => { selectedstorepickdata(e.value); setStoreVisible(false) }} checked={SelectedstorepickupID === items.ID} />
                <label htmlFor="Store_Pickup_Store" className='mx-3'>{items.STORE_NAME}</label>
            </div>

        )
    }
    const signupheader = () => {
        return (
            <div className="d-block w-100 text-center mb-3">
                {/* <Link to='/home'>  <img src={Logo} width="170px" /></Link> */}
                <h4 className='text-center'>SIGN UP</h4>
                <p className='text_grey fs-12'>just provide your basic information, and you'll be ready to explore.</p>
            </div>
        )
    }
    return (
        <section className=''>
            <Toast ref={toast} />
            <Dialog visible={login_flag} className='dailog_box_responsive' onHide={() => setlogin_flag(false)}><Login flag_Disable={flag_disable} login_flag_disable={login_flag_disable} Signup_flag_enable={Signup_flag_enable} /></Dialog>
            <Dialog visible={signup_flag} header={signupheader()} className='dailog_box_responsive' onHide={() => setsignup_flag(false)}><Signup Sign_disable={sign_disable} Signup_flag_disable={Signup_flag_disable} login_enable_flag={login_enable_flag} /></Dialog>
            <div className='Sidebar_Categories'>
                <Sidebar className='Sidebar_header' visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
                    <h4 className='Sidebar_header_heading'>Categories</h4>
                    <Row className='mt-3'>
                        {/* <Col sm={6}> */}
                        {Headercategories_data && Headercategories_data.map((category_data, index) => {
                            return (
                                <Col sm={6} key={index}>
                                    <div className="">
                                        <div className="text-center " onClick={() => getCatID(category_data)}>
                                            <div className="">
                                                <img src={IMG_GLOBAL + category_data.IMAGE_PATH + category_data.IMAGE_NAME} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt='img' height='110px' width='110px' className="product-image category_item" style={{}} />
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <p className="mt-2 text-truncate">{category_data.CATEGORY_DISPLAY_NAME}</p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}
                        {/* </Col> */}
                    </Row>
                </Sidebar>
            </div>
            <div className='Sidebar_Notifications'>
                <Sidebar className='Sidebar_header' position="right" visible={visiblenotification} onHide={() => setvisiblenotification(false)}>
                    <h4 className='Sidebar_header_heading'>Notification</h4>
                    <React.Fragment>
                        {notification_data && notification_data.map((items, index) => {
                            return (
                                <div className='m-2 border-last'>
                                    <div>
                                        <p className='fs-16 fw-500 m-0'>{items.NOTIFICATION_HEADING}</p>
                                    </div>
                                    <div className='line-height-18 mt-1'>
                                        <p className='fs-14 text-grey'>{items.NOTIFICATION_TEXT}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </React.Fragment>
                </Sidebar>
            </div>
            <div className='Store_Sidebar'>
                <Sidebar className='Sidebar_header' position="right" visible={StoreVisible} onHide={() => setStoreVisible(false)}>
                    <h4 className='Sidebar_header_heading'>Store Address</h4>
                    <div className='mt-3'>
                        {storepickup && storepickup.map((Store_data, index) => {
                            return (
                                <div className='Stores_data Card_Order_Process mb-2' key={index}>
                                    <Card title={Stores_Header(Store_data)} className='box-shadow-none border border-2'>
                                        <Row>
                                            <Col sm={4} className="d-flex align-items-center">
                                                <div>
                                                    <img alt="Card" className='img-fluid' src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png' height={200} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                                                </div>
                                            </Col>
                                            <Col sm={8}>
                                                <div className='py-2'>
                                                    <div><p className='m-0'>{Store_data.STORE_NAME}<br /> {Store_data.ADDRESS_LINE1},{Store_data.ADDRESS_LINE2},{Store_data.CITY},{Store_data.STATE},{Store_data.COUNTRY}<br />{Store_data.PINCODE}</p> </div>
                                                    <div className='d-flex'>
                                                        <img src={Phone_Icon} />
                                                        <p className='m-0 mx-2'>{Store_data.CONTACT_NUMBER}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            )
                        })}
                    </div>
                </Sidebar>
            </div>
            <div className='Sidebar_WishList'>
                <Sidebar className='Sidebar_header' position="right" visible={WishlistVisible} onHide={() => setWishlistVisible(false)}>
                    <h4 className='Sidebar_header_heading'>My Wishlist</h4>
                    {WishListData?.rows.length > 0 ?
                        <div className='mt-3'>
                            {WishListData && WishListData?.rows.map((wishlist_data, index) => {
                                const pricedata = (
                                    <div className='status_button'>
                                        {(parseFloat(wishlist_data.PRICE) - parseFloat(wishlist_data.DISCOUNT_PRICE)) === 0 ? <p className='m-0'><span className="fw-500"> &#8377;{wishlist_data.PRICE}</span></p> :
                                            <p className='m-0'><del className="text-grey">&#8377;{wishlist_data.PRICE}</del><span className='fw-500'> {wishlist_data.DISCOUNT_PRICE}</span></p>}
                                    </div>
                                );
                                const Cart_Product_Footer = (
                                    <div className='row pt-2 border-top'>
                                        <div className='d-flex justify-content-between'>
                                            <Button className='d-flex align-items-center p-button-text p-0' onClick={() => {RemoveWishList(wishlist_data)}}> <span className='mx-2 text-grey'>Remove </span></Button>
                                            <Button className='d-flex align-items-center p-button-text p-0' onClick={() => AddtoCart(wishlist_data)}>
                                                <img src={cart_icon_color} />
                                                <span className='mx-1 color_head fw-500'> Add to cart</span></Button>
                                        </div>
                                    </div>
                                );

                                return (
                                    <div className='product_cart_card' key={index}>
                                        <Card footer={Cart_Product_Footer} className='mb-3 box-shadow-none border border-2 rounded'>
                                            <div className='row' key={index}>
                                                <div className='col-sm-4'>
                                                    <img
                                                        alt="LOGO"
                                                        src={IMG_GLOBAL + wishlist_data.IMAGE_PATH1}
                                                        width='100%'
                                                        // height='50vh'
                                                        onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                                        className="d-inline-block border rounded p-1 align-top"
                                                        style={{ height: '80px' }}
                                                    />
                                                </div>
                                                <div className='col-sm-8 p-0 position-relative'>
                                                    <p className='text-truncate m-0'>{wishlist_data.PRODUCT_NAME}</p>
                                                    <div className='text-end position-absolute bottom-0 end-0'>
                                                        <div className='m-0 px-3'>{pricedata}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div>
                            <h4>No Data Found</h4>
                        </div>
                    }
                </Sidebar>
            </div>
            <div className='Sidebar_Mobile_Menu'>
                <Sidebar className='Sidebar_header' position="right" visible={Mobile_Menu} onHide={() => setMobile_Menu(false)}>
                    <div className='Sidebar_header_heading top-0 py-2'> <Link to='/home' as="li">
                        <img
                            alt="LOGO"
                            src={Logo}
                            width="100px"
                            className="align-top text-center"
                        />
                    </Link>
                    </div>
                    <div className='mt-3'>
                        <div className='d-inline-grid lh-lg'>
                            {/* <div as="li" className='link_data' onClick={() => WishListClickFlag()}><img src={Wishlist_Icon} width={24} /><span className='mx-3'>Wishlist </span></div> */}
                            <Link as="li" className='link_data' to="/submitorder"><img src={cart_icon_color} width={24} /><span className='mx-3'>Cart</span></Link>
                            <Link as="li" className='link_data' to="/profile"> <img src={profile_icon_color} /><span className='mx-3'>My Account</span></Link>
                            <Link as="li" className='link_data' to="/wallet"> <img src={Wallet_icon_color} /><span className='mx-3'>Wallet</span></Link>
                            <Link as="li" className='link_data' to="/transaction"> <img src={Transaction_icon} /><span className='mx-3'>My Transactions</span></Link>
                            <Link as="li" className='link_data' to="/notification"> <img src={notification_icon_color} /><span className='mx-3'>Notification</span></Link>
                            {/* <NavDropdown.Item><Link as="li" className='link_data' to="/blog">Blog</Link></NavDropdown.Item> */}
                            <Link as="li" className='link_data' to="/orderlist"> <img src={cart_icon_color} width={24} height={24} /><span className='mx-3'>Orders</span></Link>
                            <div as="li" onClick={logout} className='link_data color_red mt-3'><img src={logout_icon_color} /><span className='mx-2 color_red'>Logout</span></div>
                        </div>
                    </div>
                </Sidebar>
            </div>
            {/* <div>
            <Container>
                <Navbar fixed="top" className='bg_color_head text-white px-5' variant="light">
                        <Navbar.Brand className='p-0 col'>
                            <Link to='/home'>
                                <img
                                    alt="LOGO"
                                    src={Logo}
                                    width="100px"
                                    className="d-inline-block align-top text-center"
                                /></Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav navbarScroll className="col justify-content-center align-items-center">
                                <span className="p-input-icon-right mx-4">
                                    <i className="pi pi-search color_head" onClick={() => searchdata()} />
                                    <InputText value={search} className="p-0" style={{ width: "300px" }}
                                        onChange={(e) => setsearch(e.target.value)} />
                                </span>
                            </Nav>
                            <Nav className="col justify-content-end align-items-center">
                                <Nav.Link className='text-white'><span className='rotue_link' onClick={GetStoreData}><small className='mx-2'>{Selectedstore}</small><i className="pi pi-map-marker"></i></span></Nav.Link>
                                {user_data ? <Nav.Link className='text-white'><Link className='rotue_link' to="/notification"><i className="pi pi-bell"></i></Link></Nav.Link> : ''}
                                {user_data ? <Nav.Link className='text-white'><Link className='rotue_link' to="/cart"><i className="pi pi-shopping-cart"></i></Link></Nav.Link> : ''}
                                {user_data ? <NavDropdown className='text-white' title={<i className="pi pi-user"></i>} id="basic-nav-dropdown">
                                    <NavDropdown.Item ><Link className='link_data' to="/profile">Profile</Link></NavDropdown.Item>
                                    <NavDropdown.Item ><Link className='link_data' to="/wallet">Wallet</Link></NavDropdown.Item>
                                    <NavDropdown.Item><Link className='link_data' to="/blog">Blog</Link></NavDropdown.Item>
                                    <NavDropdown.Item ><Link className='link_data' to="/orderlist">Orders</Link></NavDropdown.Item>
                                    <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                                </NavDropdown> : <Nav.Link className='text-white' to="/login"><Link className='rotue_link' to="/login">Login</Link></Nav.Link>}
                            </Nav>
                        </Navbar.Collapse>
                </Navbar>
                </Container>
            </div> */}

            {/* <Container> */}
            {ScreenWidth <= 786 ?
                <div className='p-0 bg_color_head position-fixed top-0 w-100 fixed-top'>
                    <div className='position-relative d-flex justify-content-between align-items-center px-3 pt-5 pb-3'>
                        <div className='position-absolute top-10 start-50 translate-middle-x'>
                            <Link to='/home' as="li">
                                <img
                                    alt="LOGO"
                                    src={Logo}
                                    width="100px"
                                    className="d-inline-block align-top text-center"
                                />
                            </Link>
                        </div>
                        <div className='position-absolute top-20 end-5'>
                            {user_data ?
                                <div onClick={() => setMobile_Menu(true)}><i className="pi pi-bars text-white" style={{ 'fontSize': '2em' }}></i></div>
                                :
                                <div as="li" className='text-white cursor_poniter my-2'><span className='rotue_link' onClick={Login_flag} > Login </span></div>
                            }
                        </div>
                    </div>
                    <div className='text-white pb-2 px-3 d-flex align-items-center justify-content-between'>
                        <div>
                            <p className='m-0 d-flex align-items-center' onClick={() => setStoreVisible(true)}><img src={location_icon} width={20} /><span><small className='mx-2'>{SelectedStoreData.CITY}</small></span><i className='pi pi-angle-down'>
                            </i></p>
                        </div>
                        <div>
                            <Form onSubmit={searchdata} className="d-flex">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-search color_head" />
                                    <InputText
                                        id="Search"
                                        type="text"
                                        name='Search'
                                        value={search}
                                        onChange={(e) => setsearch(e.target.value)}
                                        placeholder="Search for Products"
                                        className="my-1"
                                        // aria-label="Search For Products"
                                        style={{ width: '150px', height: '36px', borderRadius: '8px', color: '#BEC9C5', fontSize: '12px' }}
                                    />
                                </span>
                                {/* <Button variant="outline-success">Search</Button> */}
                            </Form>
                        </div>
                    </div>
                </div>
                :
                <Navbar expand="lg" fixed="top" className='p-0 bg_color_head text-white h-72' variant="light">
                    <Nav className="container d-flex align-items-center w-100 justify-content-between">
                        <Nav id="navbarScroll" className='location_header'>
                            <Nav.Link as="li" className='text-white text-center'><span className='rotue_link d-flex align-items-center' onClick={() => setStoreVisible(true)}><img src={location_icon} width={20} /><span><small className='mx-2'>{SelectedStoreData.CITY}</small></span><i className='pi pi-angle-down'>
                            </i></span></Nav.Link>
                        </Nav>
                        <Nav className="">
                            <Navbar.Brand className='p-0 d-flex align-items-center'>
                                <Link to='/home' as="li">
                                    <img
                                        alt="LOGO"
                                        src={Logo}
                                        width="100px"
                                        className="d-inline-block align-top text-center"
                                    /></Link>
                            </Navbar.Brand>
                        </Nav>
                        <Nav>
                            <Navbar.Toggle aria-controls="navbarScroll" />
                            <Navbar.Collapse id="navbarScroll">
                                <Nav
                                    className="me-auto my-2 my-lg-0 justify-content-end align-items-center"
                                    // style={{ maxHeight: '100px' }}
                                    navbarScroll
                                >
                                    {/* {user_data ? <Nav.Link className='text-white'><Link className='rotue_link' to="/notification"><i className="pi pi-bell"></i></Link></Nav.Link> : ''} */}
                                    {user_data ? <Nav.Link as="li" className='text-white text-center cursor_poniter' onClick={() => WishListClickFlag()}><img src={Wishlist_Icon} width={20} /><br /><small className='mt-3'>Wishlist</small></Nav.Link> : ''}
                                    {user_data ? <Nav.Link as="li" className='text-white text-center cart_nav px-4'><Link className='rotue_link' to="/submitorder"><img src={cart_icon} width={20} /><br /><small className='mt-3'>Cart</small></Link></Nav.Link> : ''}
                                    {user_data ? <NavDropdown className='text-white text-center ps-4' title={<span><img src={profile_icon} width={20} /><br /><small className='mt-3'>Profile</small></span>}>
                                        <NavDropdown.Item ><Link as="li" className='link_data' to="/profile"> <img src={profile_icon_color} /><span className='mx-3'>My Account</span></Link></NavDropdown.Item>
                                        <NavDropdown.Item ><Link as="li" className='link_data' to="/wallet"> <img src={Wallet_icon_color} /><span className='mx-3'>Wallet</span></Link></NavDropdown.Item>
                                        <NavDropdown.Item ><Link as="li" className='link_data' to="/transaction"> <img src={Transaction_icon} /><span className='mx-3'>My Transactions</span></Link></NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => notification_flag()} className='link_data' ><img src={notification_icon_color} /><span className='mx-3'>Notification</span></NavDropdown.Item>
                                        {/* <NavDropdown.Item><Link as="li" className='link_data' to="/blog">Blog</Link></NavDropdown.Item> */}
                                        <NavDropdown.Item ><Link as="li" className='link_data' to="/orderlist"> <img src={cart_icon_color} width={24} height={24} /><span className='mx-3'>Orders</span></Link></NavDropdown.Item>
                                        <NavDropdown.Item onClick={logout} className='link_data text-center color_red mt-3'><img src={logout_icon_color} /><span className='mx-2 color_red'>Logout</span></NavDropdown.Item>
                                    </NavDropdown> : <Nav.Link as="li" className='text-white cursor_poniter'><span className='rotue_link' onClick={Login_flag} ><span className='mx-3'>Login</span> </span></Nav.Link>}
                                </Nav>
                                {/* <Nav className="col-sm-12 col-md-6 d-flex align-items-center justify-content-center">
                        <Form className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                            />
                            <Button variant="outline-success">Search</Button>
                        </Form>
                    </Nav> */}
                            </Navbar.Collapse>
                        </Nav>
                    </Nav>
                    <Nav>
                        <Navbar.Collapse id="navbarScroll">
                            <Navbar expand="lg" fixed="top" id="navbarScroll" className='bg_color_sub_heading text-white mt-10' variant="light">
                                <Nav className="container w-100">
                                    <Nav className=' d-flex align-items-center justify-content-between'>
                                        <Nav.Link as="li" onClick={() => { setVisibleLeft(true) }} className='text-white text-center cursor_poniter'><span className='rotue_link'><i className="pi pi-align-left" style={{ fontSize: '20px' }}></i><span className='mx-2'>Categories</span></span></Nav.Link>
                                        <Nav.Link as="li"> <Form onSubmit={searchdata} className="d-flex">
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-search color_head" />
                                                <InputText
                                                    id="Search"
                                                    type="text"
                                                    name='Search'
                                                    value={search}
                                                    onChange={(e) => setsearch(e.target.value)}
                                                    placeholder="Search for Products"
                                                    className="my-1"
                                                    // aria-label="Search For Products"
                                                    style={{ width: '318px', height: '36px', borderRadius: '8px', color: '#BEC9C5', fontSize: '12px' }}
                                                />
                                            </span>
                                            {/* <Button variant="outline-success">Search</Button> */}
                                        </Form></Nav.Link>
                                    </Nav>
                                    <Nav className=' d-flex align-items-center justify-content-between'>
                                        <Nav.Link><Link as="li" className='text-white rotue_link' to="/stores"><img src={Store_icon} width={24} /><small className='pl-2'>Our Stores</small></Link></Nav.Link>
                                        <Nav.Link><Link as="li" className='text-white rotue_link' to="/blog"><img src={blog_icon} width={24} /><small className='pl-2'>Blog</small></Link></Nav.Link>
                                        <Nav.Link><Link as="li" className='text-white rotue_link' to="/contact"><img src={help_icon} width={24} /><small className='pl-2'>Help</small></Link></Nav.Link>
                                    </Nav>
                                </Nav>
                            </Navbar>
                        </Navbar.Collapse>
                    </Nav>
                </Navbar>
            }
            {/* </Container> */}
        </section>
    );
}

export default Header;
