import React, { useState, useEffect } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import home_icon from '../../Assets/Atum_Icons/Home_icon.svg';

const BreadCrumbCard = (props) => {

    const items = props.Breadcrumbdata;

    const home = { icon: 'pi pi-home', url: '/home' }

    return (
        <div>
            <div className='bread_crumb'>
                <BreadCrumb model={items} home={home} />
            </div>
        </div>
    )
}

export default BreadCrumbCard