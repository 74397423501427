import React, {useEffect} from 'react';

export default function Delivery(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div className='mt-5 pt-5 container'>
            <h3 style={{textAlign: 'center'}}>Delivery Policy</h3>
            <p>Expected time taken to dispatch the items is given against each product on the respective product details page. While we will try our best to dispatch products as soon as possible however in unusual circumstances the delivery of the products may take up to 7 days approx as per local guidelines.</p>
            <p>In the unlikely event that we are not able to deliver your order completely, we shall cancel the remaining undelivered part of the order, and send an email to you informing about the same. In such cases, your payment against the undelivered part of the order shall be refunded, in the manner the payment was made.</p>
            <p>If your order has multiple items, these may ship from different locations in separate shipments. You will receive an email if the shipment does not contain all the items that you have ordered.</p>
            <p>If you believe that the product is not in good condition, or if the packaging is tampered or damaged, please refuse to accept delivery of the package, and write to us at info@atumlife.com mentioning your order reference number. We shall make our best efforts to ensure that a replacement delivery is made to you at the earliest.</p>
            <p>For any divergence or discrepancy in delivery of order, we request you to call our Customer Care 8341122922 or write to us at info@atumlife.com within 2 working days from the date of receipt of delivery SMS or email communication, failing to do so, the claim shall not be accepted.</p>
            <p>Please note all items (including gifts) will be shipped with an invoice mentioning the price, as per Indian Tax Regulations.</p>
        </div>
    )
}