import { Form, Button, Row, Col, Carousel, Container } from "react-bootstrap";
import React, { Component, useState, useEffect, } from 'react';
import { GLOBAL_DOMAIN, Api_Headers } from "../../Config/Config";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import HCarousel from "../../Components/HCarousel";
import Logo from '../../Assets/logo.svg';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';

const Forgotpassword = () => {
    const history = useHistory();
    const [login_button, setlogin_button] = useState(false)
    const [button, setButton] = useState(true);
    const [error, seterror] = useState('');
    const [password_flag, setPassword_flag] = useState(false);
    const [resetdata, setresetdata] = useState({});
    const [errorstatus, seterrorstatus] = useState(false)
    const [isRevealNewPwd, setisRevealNewPwd] = useState(false);
    const [isRevealConfirmPwd, setisRevealConfirmPwd] = useState(false);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email Address is required')
            .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Enter valid email address'),
        Otp_valid: Yup.string()
            .required('Otp is required')
            .max(6, 'Enter valid OTP')
            .min(6, 'Enter valid OTP'),
        new_password: Yup.string()
            .required('Password is required')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$#!%*?&]{5,}$/, 'Enter valid Password eg:Atumlife@1')
            .min(8, 'Aleast 8 char'),
        confirm_password: Yup.string()
            .required('Password is required')
            // .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Enter valid Password')
            .oneOf([Yup.ref('new_password')], 'Your password do not match.'),
    });

    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const ResetPassword = () => {
        console.log(resetdata)
        axios({
            method: 'patch',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + resetdata.data.ID + '?action=updatePassword',
            data: {
                // EMAIL:getValues('mobile_number'),
                PASSWORD:getValues('confirm_password')
            }
        }).then((res) => {
            console.log('Login Data :', res.data);
            if(res.data.code == "204"){
                localStorage.setItem('User_data', JSON.stringify(res.data));
                history.push('/login');
            }else{
            seterror(res.data.message);
            }
        // setButton(false);
        seterror('')
        }).catch((err) => {
            console.log(err);
            seterror(err.response.data.message);
        });
    };
    const getOtp = () => {
        console.log(getValues('mobile_number'));
        axios({
            method: 'patch',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers?action=forgotpassword',
            data: {
                EMAIL:getValues('email')
            }
        }).then((res) => {
            console.log('Login Data :', res.data);
        setButton(false);
        seterror('')
        }).catch((err) => {
            console.log(err);
            seterror(err.response.data.message);
        });
    };

    const verifyOtp = () => {
        // console.log(JSON.stringify(data, null, 2));
        axios({
            method: 'patch',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers?action=verifyemailOTP',
            data: {
                EMAIL:getValues('email'),
                OTP:getValues('Otp_valid')
            }
        }).then((res) => {
            console.log('Login Data :', res.data);
            setresetdata(res.data);
            // localStorage.setItem('User_data', JSON.stringify(res.data));
            setlogin_button(true);
            setPassword_flag(true);
            // sessionStorage.setItem('token', res.data.token);
            // history.push('/home');
        }).catch((err) => {
            console.log(err);
            seterror(err.response.data.message);
        });
    };
    const Passwordheader = <h6>Pick a password</h6>;
    const Passwordfooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );
    return (
        <section className='container pt-5'>
            <div className='row card_responsive'>
                <div className='col-sm-6 d-flex justify-content-center'>
                    <HCarousel />
                </div>
                <div className='col-sm-6 justify-content-center d-flex'>
                    <div className='col-sm-8 card py-5'>
                        <div className='card_margin'>
                            <div className="d-block w-100 text-center mb-3">
                                <Link to='/home'>  <img src={Logo} width="170px" /></Link>
                                <h5 className='text-center color_head'><b>Forgot Password</b></h5>
                            </div>
                            <div className='text-center'>
                                {error && <small className='color_red'>{error.response.data.message}</small>}
                            </div>
                            <div className='row justify-content-center'>
                                <div className="login-form col-sm-10">
                                    <form onSubmit={handleSubmit(verifyOtp)}>
                                        <div className="field">
                                            <label className='mb-1'>Email Address</label>
                                            <InputText
                                                name="email"
                                                type="text"
                                                {...register('email')}
                                                placeholder={'Enter your Eamil Address'}
                                                disabled={!button}
                                                className={`w-100 ${errors.email ? 'is-invalid' : ''}`}
                                            />
                                            <div className="invalid-feedback">{errors.email?.message}</div>
                                        </div>
                                        {password_flag &&
                                        <div>
                                        <div className="field p-input-icon-right">
                                            <label className='mb-1'>New Password</label>
                                            {!isRevealNewPwd ? 
                                         <i className={`pi pi-eye ${errors.new_password ? '' : 'mt-1'}`} onClick={() => setisRevealNewPwd(prevState => !prevState)}></i> :
                                         <i className={`pi pi-eye-slash ${errors.new_password ? '' : 'mt-1'}`} onClick={() => setisRevealNewPwd(prevState => !prevState)}></i> 
                                        }
                                            <InputText
                                                name="new_password"
                                                type={isRevealNewPwd ? "text" : "password"}
                                                {...register('new_password')}
                                                placeholder={'Enter your New Password'} feedback = {false}
                                                toggleMask header={Passwordheader} footer={Passwordfooter}
                                                // disabled={!button}
                                                className={`w-100 ${errors.new_password ? 'is-invalid' : ''}`}
                                            />
                                            <div className="invalid-feedback">{errors.new_password?.message}</div>
                                        </div>
                                        <div className="field">
                                            <span  className="p-input-icon-right">
                                            <label className='mb-1'>Confirm Password</label>
                                            {!isRevealConfirmPwd ? 
                                         <i className={`pi pi-eye ${errors.confirm_password ? '' : 'mt-1'}`} onClick={() => setisRevealConfirmPwd(prevState => !prevState)}></i> :
                                         <i className={`pi pi-eye-slash ${errors.confirm_password ? '' : 'mt-1'}`} onClick={() => setisRevealConfirmPwd(prevState => !prevState)}></i> 
                                        }
                                            <InputText
                                                name="confirm_password"
                                                type={isRevealConfirmPwd ? "text" : "password"}
                                                {...register('confirm_password')}
                                                placeholder={'Enter your confirm password'}
                                                // disabled={!button}
                                                className={`w-100 ${errors.confirm_password ? 'is-invalid' : ''}`}
                                            />
                                            <div className="invalid-feedback">{errors.confirm_password?.message}</div>
                                        </span>
                                        </div>
                                        </div>
                                        }
                                        {/* <div className="form-group">
                                            <label className='mb-2'>OTP Number</label>
                                            <input
                                                name="Otp_valid"
                                                type="text"
                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                {...register('Otp_valid')}
                                                className={`form-control ${errors.Otp_valid ? 'is-invalid' : ''}`}
                                            />
                                            <div className="invalid-feedback">{errors.Otp_valid?.message}</div>
                                        </div> */}
                                        {login_button ?  <div className="form-group text-center">
                                            <button type="button" onClick={() => ResetPassword()}  className="btn btn_p text-white w-100 mt-3 px-4">
                                                Reset Password
                                            </button></div> : 
                                            <div>
                                        {button ? <div className="form-group text-center">
                                            <button type="button" onClick={() => getOtp()}  className="btn btn_p text-white w-100 mt-3 px-4">
                                                Get Otp
                                            </button>
                                        </div> : ''}
                                        {!button ? <div className="form-group">
                                            <label className='mb-2'>OTP Number</label>
                                            <InputText
                                                name="Otp_valid"
                                                type="text"
                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                {...register('Otp_valid')}
                                                className={`form-control ${errors.Otp_valid ? 'is-invalid' : ''}`}
                                            />
                                            <div className="invalid-feedback">{errors.Otp_valid?.message}</div>
                                        </div> : ''}
                                        {!button ? <div className="form-group text-center">
                                            <button type="submit"  onClick={() => verifyOtp()}  className="btn btn_p text-white w-100 mt-3 px-4">
                                                Verify Otp
                                            </button>
                                        </div> : ''}
                                        </div>
                                     } 
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Forgotpassword;