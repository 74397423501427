import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Form, Button, Carousel, Row, Col } from "react-bootstrap";
import { signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { Link, useHistory } from 'react-router-dom';
import "./signup.css";
import Img1 from '../../Assets/health_friendly.png';
import Img2 from '../../Assets/recycle.png';
import Img3 from '../../Assets/sustain_able.png';
import Logo from '../../Assets/logo.svg';
import Glogo from '../../Assets/google.png';
import Flogo from '../../Assets/facebook.png';
import { auth } from '../../firebase-config';
import { GLOBAL_DOMAIN, Api_Headers } from '../../Config/Config';
import { Skeleton } from 'primereact/skeleton';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { FileUpload } from 'primereact/fileupload';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import Login from '../Login/Login'

const Signup = (props) => {

    const history = useHistory();
    const [loadImg, setLImg] = useState(false);
    const [error, seterror] = useState({});
    const [errorstatus, seterrorstatus] = useState(false);
    const provider1 = new GoogleAuthProvider();
    const provider2 = new FacebookAuthProvider();
    const [organizationdata, setorganizationdata] = useState([]);
    const [ImgUpload, setImgUpload] = useState({});
    const [Imgerror, setImgerror] = useState(true);
    const [login_flag, setlogin_flag] = useState(false);
    const RegistrationForm = useFormik({

        initialValues: {
            FIRST_NAME: "",
            LAST_NAME: "",
            EMAIL: "",
            MOBILE_NUMBER: "",
            PASSWORD: "",
            REFERRED_BY: "",
            FACEBOOK: "",
            INSTAGRAM: "",
            TWITTER: "",
            ORGANIZATION_ID: null,
            OTHER_ORGANIZATION: "",
            LOCATION: "",
            Upload_Img: null,
            IS_STUDENT: false
        },
        validate: (data) => {
            let errors = {};
            if (!data.FIRST_NAME) {
                errors.FIRST_NAME = 'First Name is required.';
            }
            if (!data.MOBILE_NUMBER) {
                errors.MOBILE_NUMBER = 'Mobile Number is required.';
            } else if (!/^[6-9]\d{9}$/i.test(data.MOBILE_NUMBER)) {
                errors.MOBILE_NUMBER = 'Invalid Phone Number';
            }
            if (!data.LAST_NAME) {
                errors.LAST_NAME = 'Last Name is required';
            }
            if (!data.EMAIL) {
                errors.EMAIL = 'Eamil is required.';
            } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(data.EMAIL)) {
                errors.EMAIL = 'Invalid Email Address';
            }
            if (!data.PASSWORD) {
                errors.PASSWORD = 'Password is required.';
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(data.PASSWORD)) {
                errors.PASSWORD = 'Enter Valid password "Password@123"';
            }
            if (!data.LOCATION && data.IS_STUDENT) {
                errors.LOCATION = 'Location is required.';
            }
            if (!data.ORGANIZATION_ID && data.IS_STUDENT) {
                errors.ORGANIZATION_ID = 'Organization is required.';
            }
            // if (!data.Upload_Img && data.IS_STUDENT) {
            //     errors.Upload_Img = 'ID Proof is required.';
            // }
            if (Imgerror && data.IS_STUDENT) {
                errors.Upload_Img = 'ID Proof is required.';
            }
            if (!data.OTHER_ORGANIZATION && data.ORGANIZATION_ID?.LOOKUP_NAME == "OTHER" && data.IS_STUDENT) {
                errors.OTHER_ORGANIZATION = 'Organization is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            console.log(data)
            // setFormData(data);
            Signup(data);
        }
    })

    const login_here = () => {
        setlogin_flag(true);
        props.login_enable_flag();
        login_disable_flag()
        // history.push('/home')
    }
    const close_signup = () => {
        login_disable_flag();
        history.push('/home')
    }
    const resetrigisterform = () => {
        RegistrationForm.resetForm();
    }

    const isFormFieldValid = (name) => !!(RegistrationForm.touched[name] && RegistrationForm.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{RegistrationForm.errors[name]}</small>;
    };

    const Signup = (data) => {
        // console.log(JSON.stringify(data, null, 2));
        const ORGANIZATION = data.ORGANIZATION_ID?.LOOKUP_NAME == "OTHER" ? data.OTHER_ORGANIZATION : data.ORGANIZATION_ID?.LOOKUP_NAME
        const formData = new FormData();
        formData.append("userData", JSON.stringify({
            FIRST_NAME: data.FIRST_NAME,
            LAST_NAME: data.LAST_NAME,
            EMAIL: data.EMAIL,
            MOBILE_NUMBER: data.MOBILE_NUMBER,
            PASSWORD: data.PASSWORD,
            REFERRED_BY: data.REFERRED_BY,
            // FACEBOOK:data.FACEBOOK,
            // INSTAGRAM:data.INSTAGRAM,
            // TWITTER:data.TWITTER,
            IS_STUDENT: data.IS_STUDENT,
            // ORGANIZATION_ID:data.ORGANIZATION_ID.ID,
            // ORGANIZATION_NAME: ORGANIZATION,
            // LOCATION:data.LOCATION
        }));
        if (data.IS_STUDENT) {
            formData.append("identityImage", ImgUpload);
            formData.append("userData", JSON.stringify({
                FACEBOOK: data.FACEBOOK,
                INSTAGRAM: data.INSTAGRAM,
                TWITTER: data.TWITTER,
                ORGANIZATION_ID: data.ORGANIZATION_ID.ID,
                ORGANIZATION_NAME: ORGANIZATION,
                LOCATION: data.LOCATION
            }))
        }

        axios({
            method: 'post',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((res) => {
            // console.log('Login Data :', res.data);
            // localStorage.setItem('login_data', res.data);
            // sessionStorage.setItem('token', res.data.token);
            resetrigisterform();
            props.Sign_disable();
            // history.push('/home');
        }).catch((err) => {
            seterror(err)
            seterrorstatus(true)
            // props.Sign_disable();
            console.log(err);
        });
    };

    const GoogleLogin = () => {
        signInWithPopup(
            auth, provider1
        ).then((res) => {
            let obj = null; const credential = GoogleAuthProvider.credentialFromResult(res);

            obj = {
                'FIRST_NAME': res?._tokenResponse?.firstName,
                'LAST_NAME': res?._tokenResponse?.lastName,
                'EMAIL': res?._tokenResponse?.email,
                'MOBILE_NUMBER': res?.user?.phoneNumber,
                'TOKEN': res?._tokenResponse?.idToken,
            };

            axios({
                method: 'post',
                headers: Api_Headers,
                url: `${GLOBAL_DOMAIN}/v1/endusers/webgmaillogin`,
                data: obj,
            }).then((res) => {
                console.log(res);
                localStorage.setItem('User_data', JSON.stringify(res.data));
                history.push('/home');
            }).catch((err) => {
                console.log(err);
            });

            // console.log('user google: ', res._tokenResponse, obj);
        }).catch((err) => {
            const errorCode = err.code,
                errorMessage = err.message, email = err.customData.email,
                credential = GoogleAuthProvider.credentialFromError(err);
            console.log('Error: ', errorCode, errorMessage, email, credential);
        });
    };

    const FacebookLogin = () => {
        signInWithPopup(
            auth, provider2
        ).then((res) => {
            const credential = FacebookAuthProvider.credentialFromResult(res);
            let obj = {
                'FIRST_NAME': res?._tokenResponse?.firstName,
                'LAST_NAME': res?._tokenResponse?.lastName,
                'EMAIL': res?._tokenResponse?.email,
                'MOBILE_NUMBER': res?.user?.phoneNumber,
                'TOKEN': res?._tokenResponse?.idToken,
            };
            axios({
                method: 'post',
                headers: Api_Headers,
                url: `${GLOBAL_DOMAIN}/v1/endusers/webfacebooklogin`,
                data: obj,
            }).then((res) => {
                console.log(res);
                localStorage.setItem('User_data', JSON.stringify(res.data));
                history.push('/home');
            }).catch((err) => {
                console.log(err);
            });
            // history.push("/home")
            //   console.log('user google: ', res, credential);
        }).catch((err) => {
            const errorCode = err.code,
                errorMessage = err.message, email = err.customData.email,
                credential = FacebookAuthProvider.credentialFromError(err);
            console.log('Error: ', errorCode);
        });
    };

    const getOrganizationData = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: `${GLOBAL_DOMAIN}/v1/lookupcodes?type=drop&LOOKUP_TYPE_NAME=ORGANIZATIONS`,
        }).then((res) => {
            // const OrganizationOther_DATA = {
            //     ID: 0, 
            //     LOOKUP_NAME: 'OTHER', 
            //     LOOKUP_DISPLAY_NAME: 'Other', 
            //     LOOKUP_TYPE_NAME: 'ORGANIZATIONS'
            // }
            setorganizationdata(res.data);
            // setorganizationdata(res.data.push(OrganizationOther_DATA));
            // console.log(res.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    const RegistrationFormUpload = (e) => {
        console.log(e);
        if (e.target.files.length == 0) {
            setImgerror(true);
        } else {
            setImgerror(false);
        }
        setImgUpload(e.target.files[0])
    }

    const Passwordheader = <h6>Pick a password</h6>;
    const Passwordfooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );
    useEffect(() => {
        setLImg(true);
        getOrganizationData();
        setTimeout(() => {
            setLImg(false);
        }, 1000);
    }, []);

    const flag_disable = useCallback(() => {
        history.push('/home');
        // setlogin_flag(false);
    }
    )
    const login_disable_flag = useCallback(() => {
        props.Signup_flag_disable()
    })
  
    return (
        <section className='container'>
            {/* <Dialog visible={login_flag} style={{ width: '50vw' }} onHide={() => setlogin_flag(false)}><Login flag_Disable={flag_disable} /></Dialog> */}
            <div className='d-flex justify-content-center'>
                {/* <div className='col-sm-6 d-flex justify-content-center'>
                    {loadImg === false ? <Carousel variant='dark' className='carousel_disable' controls={false} indicators={false} style={{ width: '70%', }}>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Img1}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Img2}
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={Img3}
                                alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel> : <Skeleton height="21rem" borderRadius="15px" />}
                </div> */}
                {/* <div className='justify-content-center d-flex'> */}
                    <div className=''>
                        <div className='card_margin'>
                            {/* <div className="d-block w-100 text-center mb-3">
                                <Link to='/home'>  <img src={Logo} width="170px" /></Link>
                                <h4 className='text-center'>SIGN UP</h4>
                                <p className='text_grey fs-12'>just provide your basic information, and you'll be ready to explore.</p>
                            </div> */}
                            <div className='text-center'>
                                {errorstatus && <small className='color_red'>{error.response.data.message}</small>}
                            </div>
                            <div className='row justify-content-center'>
                                <div className="login-form col-sm-10">
                                    <Form className='mt-2' onSubmit={RegistrationForm.handleSubmit} enctype="multipart/form-data">
                                        <Row>
                                            <Col sm={6}>
                                                <Form.Group className="input_field mb-3" controlId='name'>
                                                    <label htmlFor="FIRST_NAME" className="d-block mb-1">First Name<sup className='color_red'>*</sup></label>
                                                    <InputText
                                                        type='text'
                                                        id='FIRST_NAME'
                                                        placeholder='Enter Your First Name'
                                                        value={RegistrationForm.values.FIRST_NAME}
                                                        onChange={RegistrationForm.handleChange}
                                                        className={(classNames({ 'p-invalid': isFormFieldValid('FIRST_NAME') }))}
                                                    ></InputText>
                                                    <small className='fs-12'>{getFormErrorMessage('FIRST_NAME')}</small>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group className="input_field mb-3" controlId='name'>
                                                    <label htmlFor="LAST_NAME" className="d-block mb-1">Last Name<sup className='color_red'>*</sup></label>
                                                    <InputText
                                                        type='text'
                                                        id='LAST_NAME'
                                                        placeholder='Enter Your Last Name'
                                                        value={RegistrationForm.values.LAST_NAME}
                                                        onChange={RegistrationForm.handleChange}
                                                        className={(classNames({ 'p-invalid': isFormFieldValid('LAST_NAME') }))}
                                                    ></InputText>
                                                    <small className='fs-12'>{getFormErrorMessage('LAST_NAME')}</small>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group className="input_field mb-3" controlId='name'>
                                                    <label htmlFor="EMAIL" className="d-block mb-1">Email<sup className='color_red'>*</sup></label>
                                                    <InputText
                                                        type='text'
                                                        id='EMAIL'
                                                        placeholder='Enter Your Email'
                                                        value={RegistrationForm.values.EMAIL}
                                                        onChange={RegistrationForm.handleChange}
                                                        className={(classNames({ 'p-invalid': isFormFieldValid('EMAIL') }))}
                                                    ></InputText>
                                                    <small className='fs-12'>{getFormErrorMessage('EMAIL')}</small>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group className="input_field mb-3" controlId='name'>
                                                    <label htmlFor="MOBILE_NUMBER" className="d-block mb-1">Mobile Number<sup className='color_red'>*</sup></label>
                                                    <InputText
                                                        type='text'
                                                        id='MOBILE_NUMBER'
                                                        maxLength={10}
                                                        minLength={10}
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        placeholder='Enter Your mobile number'
                                                        value={RegistrationForm.values.MOBILE_NUMBER}
                                                        onChange={RegistrationForm.handleChange}
                                                        className={(classNames({ 'p-invalid': isFormFieldValid('MOBILE_NUMBER') }))}
                                                    ></InputText>
                                                    <small className='fs-12'>{getFormErrorMessage('MOBILE_NUMBER')}</small>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group className="input_field mb-3" controlId='name'>
                                                    <label htmlFor="PASSWORD" className="d-block mb-1">Password<sup className='color_red'>*</sup></label>
                                                    <Password
                                                        type='text'
                                                        name='PASSWORD'
                                                        id='PASSWORD'
                                                        toggleMask header={Passwordheader} footer={Passwordfooter}
                                                        minLength={8}
                                                        placeholder='Enter Your password'
                                                        value={RegistrationForm.values.PASSWORD}
                                                        onChange={RegistrationForm.handleChange}
                                                        className={`w-100 ${(classNames({ 'p-invalid': isFormFieldValid('PASSWORD') }))}`}
                                                    ></Password>
                                                    <small className='fs-12'>{getFormErrorMessage('PASSWORD')}</small>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group className="input_field mb-3" controlId='name'>
                                                    <label htmlFor="REFERRED_BY" className="d-block mb-1">Referral Code</label>
                                                    <InputText
                                                        type='text'
                                                        id='REFERRED_BY'
                                                        placeholder='Enter referal code'
                                                        value={RegistrationForm.values.REFERRED_BY}
                                                        onChange={RegistrationForm.handleChange}
                                                        className={(classNames({ 'p-invalid': isFormFieldValid('REFERRED_BY') }))}
                                                    ></InputText>
                                                    <small className='fs-12'>{getFormErrorMessage('REFERRED_BY')}</small>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12}>
                                                <Form.Group className="input_field mb-3" controlId='name'>
                                                    <Checkbox id="IS_STUDENT" checked={RegistrationForm.values.IS_STUDENT} onChange={RegistrationForm.handleChange}></Checkbox>
                                                    <label htmlFor="IS_STUDENT" className="mx-2">Are you a Student ?</label>
                                                </Form.Group>
                                            </Col>
                                            {RegistrationForm.values.IS_STUDENT == true &&
                                                <React.Fragment>
                                                    <Col sm={6}>
                                                        <Form.Group className="input_field mb-3" controlId='name'>
                                                            <label htmlFor="ORGANIZATION_ID" className="d-block mb-1">Organization<sup className='color_red'>*</sup></label>
                                                            <Dropdown name='ORGANIZATION_ID' id='ORGANIZATION_ID'
                                                                placeholder='Choose Organization'
                                                                value={RegistrationForm.values.ORGANIZATION_ID}
                                                                options={organizationdata} optionLabel={'LOOKUP_DISPLAY_NAME'}
                                                                onChange={RegistrationForm.handleChange}
                                                                className={`form-control p-0 ${classNames({ 'p-invalid': isFormFieldValid('ORGANIZATION_ID') })}`} />
                                                            <small className='fs-12'>{getFormErrorMessage('ORGANIZATION_ID')}</small>
                                                        </Form.Group>
                                                    </Col>
                                                    {RegistrationForm.values.ORGANIZATION_ID?.LOOKUP_NAME == "OTHER" &&
                                                        <Col sm={6}>
                                                            <div className="field mb-3">
                                                                <label htmlFor="OTHER_ORGANIZATION" className="d-block mb-1">Organization<sup className='color_red'>*</sup></label>
                                                                <InputText
                                                                    type='text'
                                                                    id='OTHER_ORGANIZATION'
                                                                    placeholder='Enter Your Organization'
                                                                    value={RegistrationForm.values.OTHER_ORGANIZATION}
                                                                    onChange={RegistrationForm.handleChange}
                                                                    className={(classNames({ 'p-invalid': isFormFieldValid('OTHER_ORGANIZATION') }))}
                                                                ></InputText>
                                                                <small className='fs-12'>{getFormErrorMessage('OTHER_ORGANIZATION')}</small>
                                                            </div>
                                                        </Col>
                                                    }
                                                    <Col sm={6}>
                                                        {/* <img src={URL.createObjectURL(RegistrationForm.values.Upload_Img)}></img> */}
                                                        <Form.Group className="input_field mb-3" controlId='name'>
                                                            <label htmlFor="Upload_Img" className="d-block mb-1">Upload Id Proof<sup className='color_red'>*</sup></label>
                                                            <InputText type='file' mode="basic" name="Upload_Img" id='Upload_Img'
                                                                className={(classNames({ 'p-invalid': Imgerror }))}
                                                                accept="image/*" onChange={(e) => RegistrationFormUpload(e) && RegistrationForm.handleChange} />
                                                            {Imgerror && <small className='fs-12 p-error'>ID Proof is required</small>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Group className="input_field mb-3" controlId='name'>
                                                            <label htmlFor="LOCATION" className="d-block mb-1">Location<sup className='color_red'>*</sup></label>
                                                            <InputText
                                                                type='text'
                                                                id='LOCATION'
                                                                placeholder='Enter Your Location'
                                                                value={RegistrationForm.values.LOCATION}
                                                                onChange={RegistrationForm.handleChange}
                                                                className={(classNames({ 'p-invalid': isFormFieldValid('LOCATION') }))}
                                                            ></InputText>
                                                            <small className='fs-12'>{getFormErrorMessage('LOCATION')}</small>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Group className="input_field mb-3" controlId='name'>
                                                            <label htmlFor="FACEBOOK" className="d-block mb-1">Facebook</label>
                                                            <InputText
                                                                type='text'
                                                                id='FACEBOOK'
                                                                placeholder='Enter Your Facebook'
                                                                value={RegistrationForm.values.FACEBOOK}
                                                                onChange={RegistrationForm.handleChange}
                                                                className={(classNames({ 'p-invalid': isFormFieldValid('FACEBOOK') }))}
                                                            ></InputText>
                                                            <small className='fs-12'>{getFormErrorMessage('FACEBOOK')}</small>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Group className="input_field mb-3" controlId='name'>
                                                            <label htmlFor="INSTAGRAM" className="d-block mb-1">Instagram</label>
                                                            <InputText
                                                                type='text'
                                                                id='INSTAGRAM'
                                                                placeholder='Enter Your Instagram'
                                                                value={RegistrationForm.values.INSTAGRAM}
                                                                onChange={RegistrationForm.handleChange}
                                                                className={(classNames({ 'p-invalid': isFormFieldValid('INSTAGRAM') }))}
                                                            ></InputText>
                                                            <small className='fs-12'>{getFormErrorMessage('INSTAGRAM')}</small>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Group className="input_field mb-3" controlId='name'>
                                                            <label htmlFor="TWITTER" className="d-block mb-1">Twitter</label>
                                                            <InputText
                                                                type='text'
                                                                id='TWITTER'
                                                                placeholder='Enter Your twitter Id'
                                                                value={RegistrationForm.values.TWITTER}
                                                                onChange={RegistrationForm.handleChange}
                                                                className={(classNames({ 'p-invalid': isFormFieldValid('TWITTER') }))}
                                                            ></InputText>
                                                            <small className='fs-12'>{getFormErrorMessage('TWITTER')}</small>
                                                        </Form.Group>
                                                    </Col>
                                                </React.Fragment>
                                            }
                                            <div className='d-flex justify-content-center'>
                                                <Button type='submit' className='px-5 mt-2 btn_p w-100' variant="success" style={{ borderRadius: '25px' }}>Submit </Button>
                                            </div>
                                        </Row>
                                    </Form>
                                    <div className='fs-12 text-center mt-3'>
                                        <p className='my-1'>Already Having Account ? <b className='color-primary' onClick={() => login_here()}>Login here!</b></p>
                                    </div>
                                    <div className='d-flex justify-content-center flex-row'>
                                        <div className='my-1 fs-12 mx-2'>
                                            <p className='m-0'>Signup With Social Media</p>
                                        </div>
                                        <div onClick={GoogleLogin}>
                                            <img src={Glogo} className='google' />
                                        </div>

                                        <div onClick={FacebookLogin}>
                                            <img src={Flogo} className='facebook' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </section>
    )
}

export default Signup