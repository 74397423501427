import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Container } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { GLOBAL_DOMAIN, IMG_GLOBAL, Payment_Gateway, Api_Headers } from '../../Config/Config';
import axios from 'axios';
import { Divider } from 'primereact/divider';
import { Timeline } from 'primereact/timeline';
import { useLocation } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import { useHistory, Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';

const Orderdetails = (props) => {

    const location = useLocation();
    const [User_Info, setuserinfo] = useState({});
    const [Order_details, setOrder_details] = useState([]);
    const [Orderpaymentdetails, setOrderpaymentdetails] = useState({});
    const [orderHistory, setorderHistory] = useState([]);
    const [Prebook_Amount, setPrebook_Amount] = useState();
    const [Total_Price, setTotal_Price] = useState();
    const [OriginalAmount, setOriginalAmount] = useState(0)
    const [Total_Order_Amount, setTotal_Order_Amount] = useState(0);
    const [store_data, setstore_data] = useState();
    const order_data = location.state.Order_data;
    const [Paymentmethod, setpaymentmethod] = useState('ONLINE');
    const [paymentStatus, setpayment_status] = useState('SUCCESS');
    const toast = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
        getOrderView();
        // getUserInfo();
        console.log(order_data);
        // getStore_details()
        // getOrderDetails()
    }, []);

    const getUserInfo = (user_data) => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + order_data.USER_ID,
        }).then((res) => {
            setuserinfo(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }


    const getOrderView = (order) => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/userorders/' + order_data?.ID,
        }).then((res) => {
            console.log(res.data)
            setorderHistory(res.data);
            getOrderDetails(res.data);
            if (res.data.DELIVERY_TYPE === "STORE_PICKUP") {
                getStore_details(res.data.STORE_ID)
            }
            // setDailogflag(true);
        }).catch((err) => {
            console.log(err);
        });
    };

    const getOrderDetails = (order) => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/orderItems?type=list&limit=100&orderTrackerId=' + order_data?.ORDER_TRACKER_ID,
        }).then((res) => {
            console.log(res.data)
            setOrderpaymentdetails(res.data);
            setOrder_details(res.data.rows);
            // setDailogflag(true);
            setTotal_Order_Amount(res.data.ORDER_TOTAL_AMOUNT);
            let totaloriginalprice = 0
            res.data.rows.map(items => (
                totaloriginalprice = totaloriginalprice + parseFloat(items.ORIGINAL_PRICE * items.QUANTITY)
            ))
            setOriginalAmount(totaloriginalprice);
            if (order.STATUS === "PREBOOK") {
                //     if(Prebook_Amount > 0){
                setTotal_Price(res.data.ORDER_TOTAL_AMOUNT - order.PREBOOK_AMOUNT);
                // }else{
                // setTotal_Price(order.ORDER_TOTAL_AMOUNT + res.data.TOTAL_EXTRA_CHARGES);
                // }
            } else {
                setTotal_Price(res.data.ORDER_TOTAL_AMOUNT);
            }
            // setTotal_Price(order.ORDER_TOTAL_AMOUNT);

            // console.log("total price", (res.data.TOTAL_EXTRA_CHARGES))
            if (order.PREBOOK_AMOUNT) {
                setPrebook_Amount(order.PREBOOK_AMOUNT);
            } else {
                setPrebook_Amount(0);
            }
            getUserInfo(order);
        })
    };
    const getStore_details = (ID) => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/stores/' + ID,
        }).then((res) => {
            console.log(res.data)
            setstore_data(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    const PlaceOrder = () => {
        // console.log("PlaceOder", orderdetails);
        const Order_Item = {
            "ORDER_ID": order_data?.ID,
            "ORDER_TRACKER_ID": "",
            "TRANSACTION_TOKEN": "",
            "TRANSACTION_ID": "",
            "PAYMENT_MODE": Paymentmethod,
            "PAYMENT_STATUS": paymentStatus,
            "USER_ID": order_data.USER_ID,
            "ORDER_TOTAL_AMOUNT": Total_Price,
            "STATUS": "ORDER_COMPLETE",
            "CREATED_BY": order_data.USER_ID,
            "UPDATED_BY": order_data.USER_ID
        };
        if (Paymentmethod == 'ONLINE') {
            axios({
                method: 'POST',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/paymentgateway/generateWebToken',
                data: {
                    'ORDER_TOTAL_AMOUNT': Total_Price,
                    'USER_ID': order_data?.USER_ID,
                }
            }).then((res) => {
                const checksumInfo = JSON.parse(res.data.paytmchecksum);
                var config = {
                    "root": "",
                    "style": {
                        "bodyBackgroundColor": "#fff",
                        "bodyColor": "#333",
                        "themeBackgroundColor": "#77cab9",
                        "themeColor": "#fff",
                        "headerBackgroundColor": "#77cab9",
                        "headerColor": "#fff",
                        "errorColor": "red",
                        "successColor": "green",
                        "card": {
                            "padding": "",
                            "backgroundColor": "",
                            "borderRadius": '0px',
                        }
                    },
                    "data": {
                        "orderId": res.data.data.orderInfo.ORDER_ID,
                        "token": res.data.data.txnToken,
                        "tokenType": "TXN_TOKEN",
                        "amount": res.data.data.orderInfo.ORDER_TOTAL_AMOUNT,
                    },
                    "payMode": {
                        "labels": {},
                        "filter": {
                            "exclude": [
                                "WALLET",
                                "BANK_MANDATE",
                                "BANK_TRANSFER",
                                "EMI",
                                "EMI_DC",
                                "MGV",
                                "LOGIN",
                                "PPBL",
                                "PDC",
                                "SC",
                                "BALANCE",
                            ]
                        },
                        "order": [
                            "NB",
                            "CARD",
                            "UPI",
                        ]
                    },
                    "website": "WEB",
                    "flow": "DEFAULT",
                    "merchant": {
                        "mid": Payment_Gateway.MERCHANT_ID,
                        'name': 'ATUM Life',
                        "redirect": false,
                    },
                    "handler": {
                        "transactionStatus": function transactionStatus(paymentStatus) {
                            window.Paytm.CheckoutJS.close();
                            Order_Item.ORDER_TRACKER_ID = res.data.data.orderInfo.ORDER_ID;
                            // Order_Item.PAYMENT_STATUS = paymentStatus.STATUS == 'TXN_SUCCESS' ? 'SUCCESS' : 'PENDING';
                            if (paymentStatus.STATUS == 'TXN_SUCCESS') {
                                Order_Item.PAYMENT_STATUS = 'SUCCESS';
                                axios({
                                    method: 'POST',
                                    headers: Api_Headers,
                                    url: GLOBAL_DOMAIN + '/v1/orderhistory',
                                    data: Order_Item,
                                }).then((res) => {
                                    // console.log('order placed', res.data.data.data);
                                    getOrderView();
                                    // HideDialog();
                                    // history.push('/orderlist');
                                    // history.push(`/order/${res.data.data.data.ID}`)
                                }).catch((err) => {
                                    console.log(err);
                                });
                            } else {
                                showtoast()
                            }
                        },
                        "notifyMerchant": function notifyMerchant(eventName, data) {
                            window.Paytm.CheckoutJS.close();
                        },
                    },
                };

                if (window.Paytm && window.Paytm.CheckoutJS) {
                    window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                        window.Paytm.CheckoutJS.invoke();
                    }).catch(function onError(err) {
                        console.log("Error => ", err);
                    });
                };
            }).catch((err) => {
                console.log(err); window.Paytm.CheckoutJS.invoke();
            });
        } else if (Paymentmethod == 'ATUM_WALLET') {

            axios({
                method: 'POST',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/orderhistory',
                data: Order_Item,
            }).then((res) => {
                // HideDialog();
                getOrderView();
                // history.push('/orderlist');
            }).catch((err) => {
                console.log(err);
            })
        }
    };

    const showtoast = () => {
        toast.current.show({ severity: 'error', summary: 'Payment Failure', life: 3000 });
    }

    const customizedMarker = (item) => {
        return (
            <React.Fragment>
                {item.STATUS_UPDATED ?
                    <span className="custom-marker-active shadow-1">
                        {/* <i className={item.icon}></i> */}
                    </span>
                    :
                    <span className="custom-marker shadow-1">
                        {/* <i className={item.icon}></i> */}
                    </span>
                }
            </React.Fragment>
        );
    };

    const customizedContent = (item) => {
        return (
            <div>
                <p>{item.STATUS}</p>
            </div>
            // <Card title={item.status} subTitle={item.date}>
            //     { item.image && <img src={`images/product/${item.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.name} width={200} className="shadow-1" />}
            //     <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
            //         quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
            //     <Button label="Read more" className="p-button-text"></Button>
            // </Card>
        );
    };
    return (
        <section className='container my-4'>
            <Toast ref={toast} />
            <Row spacing={2}>
                <Col xs={12} md={6} spacing={3}>
                    {orderHistory && orderHistory?.STATUS === "CANCELLED" &&
                        <div className='mb-4 text-center border'>
                            <h4 className=' m-0 py-2 color_red'>Order Cancelled</h4>
                        </div>
                    }
                    {Order_details && Order_details?.map((items, index) => {
                        return (
                            <div className='border-last' key={index}>
                                <Row key={index} spacing={2} className='my-2'>
                                    <Col xs={3} md={3} >
                                        <img
                                            className="w-100 border rounded"
                                            height={'110px'}
                                            src={IMG_GLOBAL + items.IMAGE_PATH}
                                            onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                        />
                                    </Col>
                                    <Col xs={9} md={9} className='position-relative'>
                                        <div >
                                            <div>
                                                <h6>{items.PRODUCT_NAME}</h6>
                                            </div>
                                            <div className=''>
                                                <div className='position-absolute top-50 start-3'>
                                                    <small className='text-grey'>Qty: {items.QUANTITY}</small>
                                                    {items.IS_COLOR_VARIANT == 1 &&
                                                        <div className='mt-2'>
                                                            <div className='Attribute_color_card text-center d-flex'>
                                                                <small className='text-grey'>Color:</small>
                                                                <small className='Attribute_color d-inline-flex' style={{ height: '20px', width: '20px', backgroundColor: items?.PRODUCT_COLOR }}></small>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='position-absolute top-50 end-4'>
                                                    <h5>&#8377;{parseFloat(items.PRICE).toFixed(2)}</h5>
                                                    {/* {parseFloat(items.ORIGINAL_PRICE) - parseFloat(items.PRICE) == 0 ?
                                                    <h5>&#8377;{parseFloat(items.PRICE).toFixed(2)}</h5> :
                                                    <h5>&#8377;{parseFloat(items.PRICE).toFixed(2)}<del className="color_red mx-1">&#8377;{parseFloat(items.ORIGINAL_PRICE).toFixed(2)}</del></h5>
                                                } */}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Divider /> */}
                                {items.isattributesAvailable &&
                                    <div className='bg-color-light py-2 mb-2'>
                                        <div className='container'>
                                            {/* <h5>Extra Charges</h5>
                                        <div className='d-flex justify-content-between'>
                                            <div><p>Order Price</p></div>
                                            <div><p>₹ {orderdetails.PRICE}</p></div>
                                        </div>
                                        {Prebook_Amount > 0 &&
                                            <div className='d-flex justify-content-between'>
                                                <div><p>Prebook Amount:</p></div>
                                                <div><p className='color_red'>(-) ₹ {Prebook_Amount}</p></div>
                                            </div>
                                        } */}
                                            {items?.EXTRA_CHARGES_INFO.ROWS.map(item => {
                                                return (
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <small>{item.ATTRIBUTE_DISPLAY_NAME}
                                                                {item.MEASUREMENT_NAME === 'PERCENTAGE' && item.ATTRIBUTE_NAME.trim() === 'GST' &&
                                                                    <span>({parseFloat(item.MEASUREMENT_VALUE).toFixed(2)}%)</span>
                                                                }
                                                            </small>
                                                        </div>
                                                        <div>
                                                            {/* <span className='mx-1'>:</span> */}
                                                            {item.MEASUREMENT_NAME === 'AMOUNT' &&
                                                                <small className='mx-1'>&#8377;{parseFloat(item.MEASUREMENT_VALUE).toFixed(2)}</small>
                                                            }
                                                            {item.MEASUREMENT_NAME === 'PERCENTAGE' && item.ATTRIBUTE_NAME.trim() != 'GST' &&
                                                                <small className='mx-1'>{parseFloat(item.MEASUREMENT_VALUE).toFixed(2)} %</small>
                                                            }
                                                            {item.MEASUREMENT_NAME === 'PERCENTAGE' && item.ATTRIBUTE_NAME.trim() === 'GST' &&
                                                                <small>&#8377;{((parseFloat(items?.PRICE) * parseFloat(item.MEASUREMENT_VALUE)) / 100).toFixed(2)} </small>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })}
                    <div className='rounded discount_view my-3 p-3'>
                        <div>
                            <p className='text-black'>Congrats. you earned 1 Atum coin on this purchase. it will credit to your account once the order is delivered successfully.</p>
                        </div>
                        <div className='d-flex justify-content-center mt-3'>
                            <div>
                                <small className='Atum_coin_text'>1 Atum Coin</small>
                                <small className=' mx-2 fs-10 color_pink'>Get 1 ATUM Coin as Cashback</small>
                            </div>
                        </div>
                    </div>
                    <div className='my-3'>
                        <div>
                            {Orderpaymentdetails &&
                                <div>
                                    <div className='container'>
                                        <p>Order Details</p>
                                        <div className='d-flex justify-content-between'>
                                            <div><p>Order Price</p></div>
                                            <div><p>₹ {parseFloat(OriginalAmount).toFixed(2)}</p></div>
                                        </div>
                                        <div className='d-flex justify-content-between color_blue'>
                                            <div><p>Discount</p></div>
                                            <div><p className=''>(-) ₹ {parseFloat(Orderpaymentdetails?.TOTAL_DISCOUNTED_AMOUNT).toFixed(2)}</p></div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div><p>Extra Charges</p></div>
                                            <div><p>₹ {parseFloat(Orderpaymentdetails.TOTAL_EXTRA_CHARGES).toFixed(2)}</p></div>
                                        </div>
                                        {(parseFloat(orderHistory?.PREBOOK_AMOUNT).toFixed(2) > 0 && orderHistory?.STATUS === "PREBOOK") &&
                                            <div className='d-flex justify-content-between color_blue'>
                                                <div><p>Prebook Amount</p></div>
                                                <div><p>(-) ₹ {parseFloat(orderHistory?.PREBOOK_AMOUNT).toFixed(2)}</p></div>
                                            </div>
                                        }
                                        {parseFloat(orderHistory.COUPON_DISCOUNTED_AMOUNT) > 0 &&
                                            <div className='d-flex justify-content-between color_blue'>
                                                <div><p>Coupon Discount ({orderHistory.COUPON_NAME}) </p></div>
                                                <div><p className=''>(-) ₹ {parseFloat(orderHistory.COUPON_DISCOUNTED_AMOUNT).toFixed(2)}</p></div>
                                            </div>}
                                    </div>
                                    <Divider />
                                </div>
                            }
                            {/* {Orderdetails &&  */}
                            <div className=''>
                                {/* <Divider/> */}
                                <div className='d-flex justify-content-between container'>
                                    <div><p className='m-0'><b>Total</b></p></div>
                                    {(orderHistory.COUPON_VALUE == '' || orderHistory.COUPON_VALUE == null) ?
                                        <div><p className='color_head m-0'><b>₹ {parseFloat(Total_Price).toFixed(2)}</b></p></div> :
                                        <div><p className='color_head m-0'><b>₹ {parseFloat((parseFloat(Total_Price).toFixed(2)) - parseFloat(orderHistory.COUPON_DISCOUNTED_AMOUNT).toFixed(2)).toFixed(2)}</b></p></div>
                                    }
                                </div>
                            </div>
                        </div>
                        {(orderHistory.PAYMENT_MODE === "COD" || orderHistory.STATUS === "PREBOOK") &&
                            <div className='my-4 p-3 rounded border_2'>
                                <div>
                                    <div>
                                        <h5>Balance to Pay</h5>
                                    </div>
                                    <div className='text-grey'>
                                        <small>Excitement is building up! </small>
                                        <small>You can complete payment of the remaining balance ( {(orderHistory.COUPON_VALUE == '' || orderHistory.COUPON_VALUE == null) ?
                                        <span className='color_head m-0'><b>₹ {parseFloat(Total_Price).toFixed(2)}</b></span>:
                                        <span className='color_head m-0'><b>₹ {parseFloat((parseFloat(Total_Price).toFixed(2)) - parseFloat(orderHistory.COUPON_DISCOUNTED_AMOUNT).toFixed(2)).toFixed(2)}</b></span>
                                    } ) at your convenience.</small>
                                    </div>
                                    <div className='bg-color-light my-3 px-3 rounded'>
                                        {/* <div className='pt-2'>
                                    <small className='fw-bold'>Payment Mode</small>
                                    </div> */}
                                        <div className='Paymentmethod_card py-3'>
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="ATUM_WALLET" name="Paymentmethod" value="ATUM_WALLET"
                                                    onChange={(e) => setpaymentmethod(e.value) && setpayment_status('PENDING')}
                                                    checked={Paymentmethod === 'ATUM_WALLET'} />
                                                <label htmlFor="ATUM_WALLET" className='mx-3'>Atum Wallet
                                                    <b>({parseFloat(User_Info.TOTAL_WALLET_AMOUNT).toFixed(2)})</b>
                                                </label>
                                            </div>
                                            <div className="field-radiobutton">
                                                <RadioButton inputId="ONLINE" name="Paymentmethod" value="ONLINE"
                                                    onChange={(e) => setpaymentmethod(e.value) && setpayment_status('SUCCESS')}
                                                    checked={Paymentmethod === 'ONLINE'} />
                                                <label htmlFor="ONLINE" className='mx-3'>Online Payment</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='primary_btn text-center mt-3'>
                                        <Button className="p-button px-5" label="Pay" icon="pi pi-arrow-right" iconPos="right" onClick={() => PlaceOrder()} variant="success" disabled={Paymentmethod === 'ATUM_WALLET' && parseFloat(User_Info.TOTAL_WALLET_AMOUNT) < parseFloat(Total_Price)} />
                                    </div>
                                    {Paymentmethod == 'ATUM_WALLET' && parseInt(User_Info.TOTAL_WALLET_AMOUNT) <= Total_Price ?
                                        <div>
                                            <span className='' style={{ color: 'red' }}><b>{parseFloat(User_Info.TOTAL_WALLET_AMOUNT).toFixed(2)}</b> Please upgrade the amount from <Link to='/wallet'>Wallet</Link></span>
                                        </div> : ''
                                    }
                                </div>
                            </div>
                        }
                        {/* <div className='mt-4 text-center'>
                            <Button className='px-5 p-button-outlined p-button-danger   '>Cancel Order</Button>
                        </div> */}
                    </div>
                </Col>
                <Col xs={12} md={{ span: 5, offset: 1 }}>
                    <React.Fragment>
                        <div className='bg-color-grey p-3 mb-4 rounded'>
                            {orderHistory?.DELIVERY_TYPE === "CUSTOMER_ADDRESS" ?
                                <div>
                                    <h6>Delivery Address</h6>
                                </div> :
                                <div>
                                    <h6>Coustomer Contact</h6>
                                </div>
                            }
                            <div className=''>
                                <p className='m-0 fs-14'>{orderHistory?.FIRST_NAME} {orderHistory?.LAST_NAME}</p>
                                <div className='line-height-14'>
                                    <small className='text-grey fs-10'>{orderHistory?.DELIVERY_ADDRESS}</small>
                                </div>
                            </div>
                        </div>
                        {orderHistory?.DELIVERY_TYPE === "CUSTOMER_ADDRESS" ?
                            <div className="">
                                <div className='my-4'>
                                    <h5>Track Order</h5>
                                </div>
                                <Timeline align="left" className='customized-timeline' marker={customizedMarker} value={orderHistory?.ORDER_HISTORY} content={customizedContent} />
                            </div> :
                            <div className='d-block bg-color-grey p-3 mb-4 rounded'>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <h6>Store Address</h6>
                                    </div>
                                    <div>
                                        <p className='fs-12 color_blue cursor_poniter' onClick={() => window.open("https://maps.google.com?q=" + store_data.LATITUDE + "," + store_data.LONGITUDE)}>Get Directions</p>
                                    </div>
                                </div>
                                <div>
                                    <p className='m-0 text-grey fs-14'>{store_data?.STORE_NAME}</p>
                                </div>
                                <div className='line-height-14 d-grid'>
                                    <small className='text-grey fs-10'>{store_data?.ADDRESS_LINE1}, {store_data?.ADDRESS_LINE2}, {store_data?.CITY}, {store_data?.STATE_NAME},{store_data?.PINCODE}</small>
                                    <small className='text-grey fs-10'>Contact Number {store_data?.CONTACT_NUMBER}</small>
                                </div>
                            </div>
                        }
                    </React.Fragment>
                </Col>
            </Row>
        </section>
    )
}

export default Orderdetails