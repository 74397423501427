import React, { useEffect, useState } from 'react';
import { Card } from "primereact/card";
import { GLOBAL_DOMAIN, Api_Headers } from '../../Config/Config';
import axios from 'axios';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import Image_not from '../../Assets/logo.png';
import './Notification.css';

const Notification = () => {

    const user_data = JSON.parse(window.localStorage.getItem('User_data'));
    const [notification_data, setnotification_data] = useState([]);

    useEffect(() => {
        getNotification();
    }, []);

    const getNotification = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/notifications?type=list&limit=1000&user_id=' + user_data.data.userData.ID + '&userType=END_USER',
        }).then((res) => {
            setnotification_data(res.data.rows);
            console.log("Notification data", res)
        }).catch((err) => {
            console.log(err);
        });
    }
    return (
        <section className="mt-5 pt-5">
            <div>
                <Row className="d-flex justify-content-center">
                    <Col md={8}>
                        <div className='mb-3'>
                            <h3>Notifications</h3>
                        </div>
                        {notification_data && notification_data.map(item => {
                            return (
                                <div className='mb-3 card_notification'>
                                    <Card >
                                        <div className='d-flex'>
                                            <Col xs={2}>
                                                <img alt="Card" className="" width={'100%'} height={'75px'} src={Image_not} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                                                {/* <img alt="Card" className="img-fluid card-img-top" src={item.IMAGE_PATH} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} /> */}
                                            </Col>
                                            <Col xs={10} className="px-3 d-flex align-items-center">
                                                <div className='b-block'>
                                                    <h6>{item.NOTIFICATION_HEADING}</h6>
                                                    <p className='m-0'>{item.NOTIFICATION_TEXT}</p>
                                                </div>
                                            </Col>
                                        </div>
                                        {/* <hr/> */}
                                    </Card>

                                </div>

                            )
                        }
                        )}
                        {notification_data.length == 0 &&
                            <div>
                                <h3 className='text-error'>No Notification Found</h3>
                            </div>

                        }
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Notification; 