import React, { Component, useState, useEffect, useCallback, } from 'react';
import { GLOBAL_DOMAIN, Api_Headers } from "../../Config/Config";
import axios from 'axios';
import Header from "../../Components/Header/Header";
import Productlist from "../Products/Productlist/Productlist";
import Footer from "../../Components/Footer/Footer";
import Categories from "./Categories/Categories";
import { Skeleton } from 'primereact/skeleton';
import Errorpage from "../../Components/Errorpage";
import Header_Carousel from "../../Components/Carousel/Header_Carousel";
import Item_Card from "../../Components/Cards/Item_Card";
import Product_Carousel from "../../Components/Carousel/Product_Carousel";
import banner1 from '../../Assets/5930872_3089961 1.png';
import mobileBaner from '../../Assets/images/Space Black iPhone 14 Pro Mockup.png';
import mobileimage from '../../Assets/download 1.png'
import { useHistory, Link } from 'react-router-dom';
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import playstore_icon from '../../Assets/images/palystore.jpg';
import appstore_icon from '../../Assets/images/appstore.jpg';
import androidQR from '../../Assets/images/atum-life-android.png';
import iosQR from '../../Assets/images/atum-life-ios.png';
import useWindowDimensions from '../../Components/windowdimension';

const Home = () => {

    const [banner_data, setbanners] = useState([]);
    const [isSkeleton, setisSkeleton] = useState(true);
    const [apierrorstatus, setapierrorstatus] = useState(false);
    const user_data = JSON.parse(window.localStorage.getItem('User_data'));
    const [productdata, setproductdata] = useState([]);
    const [TopDealsdata, setTopDealsdata] = useState([])
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [CoustmerMobileNumber, setMobileNumber] = useState('');
    const [formData, setFormData] = useState({});
    const [displayPosition, setDisplayPosition] = useState('');
    const [Dialogs, setDialogs] = useState(false);
    const [resbanner, setbanner] = useState('WEBSITE');
    const { height, ScreenWidth } = useWindowDimensions();

    const getproductlist = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/products?type=list&limit=12&home=true',
        }).then((res) => {
            // console.log('Product Data :', res.data);
            // const productdata = res.data.rows;
            setproductdata(res.data.rows.slice(0, 6));
            setisSkeleton(false);
        }).catch((err) => {
            console.log(err);
        });
    }
    const getToDealslist = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/dashboards?type=list&homePage=true&platForm=WEBSITE',
        }).then((res) => {
            setTopDealsdata(res.data.TOP_DEALS.slice(0, 8));
            setisSkeleton(false);
        }).catch((err) => {
            console.log(err);
        });
    }

    const getbanner = (event) => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + `/v1/dashboards?type=list&homePage=true&platForm=${event}`,
        }).then((res) => {
            // console.log('Banner Data :', res);
            setbanners(res.data.WELCOME_BANNERS);
            setisSkeleton(false);
        }).catch((err) => {
            console.log(err);
            setTimeout(() => {
                setapierrorstatus(true);
            }, 10000)
        });
    };

    const responsivebanner = useCallback((event) => {
        getbanner(event);
    })

    const getUserInfo = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
        }).then((res) => {
            if (res.data.MOBILE_NUMBER == null || res.data.MOBILE_NUMBER == '') {
                setTimeout(() => {
                    setVisible(true)
                }, 5000);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (user_data) {
            getUserInfo()
        }
        if (ScreenWidth >= 1040) {
            getbanner('WEBSITE');
        } else {
            getbanner('MOBILE')
        }
        getToDealslist();
        getproductlist();
    }, [ScreenWidth]);

    const formik = useFormik({
        initialValues: {
            mobile_number: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.mobile_number) {
                errors.mobile_number = 'Name is required.';
            } else if (!/^[6-9]\d{9}$/i.test(data.mobile_number)) {
                errors.mobile_number = 'Invalid Phone Number';
            }
            return errors;
        },
        onSubmit: (data) => {
            setFormData(data);
            UpdateUser(data)
        }
    });

    const isFormFieldValid = (mobile_number) => !!(formik.touched[mobile_number] && formik.errors[mobile_number]);
    const getFormErrorMessage = (mobile_number) => {
        return isFormFieldValid(mobile_number) && <small className="p-error">{formik.errors[mobile_number]}</small>;
    };

    const footerContent_mobilenumber_update = (
        <div className='text-center primary_btn margin_top_2'>
            <Button className="p-button w-50 justify-content-center fw-bold" onClick={formik.handleSubmit} type="submit"> Update <span className="mx-2 pi pi-arrow-right"></span></Button>
            <p className='text-grey mt-2'><span className='cursor_poniter' onClick={() => setVisible(false)}>I will do it later</span></p>
        </div>
    );
    const headerContent_mobilenumber_update = (
        <div className='text-center'>
            <h4 className='m-0'>Don't Miss Your Order Updates</h4>
            <small className='fs-12 text-grey'>Get timely order updates so you never miss a beat.</small>
        </div>
    );

    const UpdateUser = (data) => {
        let obj = {
            'MOBILE_NUMBER': data.mobile_number,
        };
        axios({
            method: 'PUT',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.data.userData.ID,
            data: obj,
        }).then((res) => {
            console.log(res.data);
            formik.resetForm();
            setVisible(false)
        }).catch((err) => {
            console.log('Update Error', err);
        });
    }

    return (
        <section className="">
            {apierrorstatus ? <Errorpage /> :
                <div>
                    <div>
                        <Dialog className='mobile_dailog_box_responsive' header="Please scan the QR code to install application" position={'center'} visible={Dialogs}  onHide={() => setDialogs(false)}>
                            {displayPosition == 'playstore' ? <div className='d-flex justify-content-center'><img src={androidQR} width={'250px'}></img></div>
                                : <div className='d-flex justify-content-center'><img src={iosQR} width={'250px'}></img></div>}
                        </Dialog>
                    </div>
                    <div >
                        <Dialog className='colse_icon_remove mobile_dailog_box_responsive' header={headerContent_mobilenumber_update} footer={footerContent_mobilenumber_update} visible={visible} onHide={() => setVisible(false)}>
                            <div className='container'>
                                <form className="p-fluid">
                                    <div className="field">
                                        <label htmlFor="mobile_number" className={`mb-2 ${classNames({ 'p-error': isFormFieldValid('mobile_number') })}`}>Mobile Number*</label>
                                        <InputText keyfilter="int" id="name" name="mobile_number" value={formik.values.mobile_number} onChange={formik.handleChange} autoFocus className={`w-100 h-40 login_input ${classNames({ 'login_input_error': isFormFieldValid('mobile_number') })}`} />
                                        {getFormErrorMessage('mobile_number')}
                                    </div>
                                    {/* <Button type="submit" label="Submit" className="mt-2" /> */}
                                </form>
                            </div>
                        </Dialog>
                    </div>
                    {isSkeleton && <Skeleton width="100%" height="280px" />}
                    {!isSkeleton && banner_data?.length >= 1 &&
                        <Header_Carousel BannerData={banner_data} responsivebanners={responsivebanner} />
                    }
                    <div className="mt-5 container">
                        <div className="d-flex justify-content-between justify-items-center">
                            <div><h4 className="Heading_1 m-0">Top Deals</h4></div>
                            {/* <div><p className="m-0 Heading_1_suport">See more <i className="pi pi-angle-right" style={{ 'fontSize': '14px' }}></i></p></div> */}
                        </div>
                        <div className="row scrollbar_hidden pt-4">
                            {TopDealsdata.map((topDeals_Data, index) => {
                                return (
                                    <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 p-2" key={topDeals_Data.ID}>
                                        <Item_Card card_format='small' Product_list_data={topDeals_Data} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div>
                        <Categories />
                    </div>
                    {/* <div className="mt-5">
                        <div className="d-flex justify-content-center">
                            <div><h3>New Products</h3></div>
                        </div>
                        <div className="mt-3">
                            <Product_Carousel Carousel_data={productdata} />
                        </div>
                    </div> */}
                    <div>
                        <div className="container my-5">
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <div className="text-center">
                                        <img src={mobileBaner} />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <div className="d-line-grid">
                                        <div className="text-center">
                                            <p>Shop on the go! <br />Download our app for exclusive deals.</p>
                                        </div>
                                        <div className='text-center bg-color-light py-3'>
                                            <div className='my-2 cursor_poniter' onClick={() => setDisplayPosition('playstore') || setDialogs(true)}><img className='rounded' src={playstore_icon} width={150} height={70} /></div>
                                            <div className='my-2 cursor_poniter' onClick={() => setDisplayPosition('appstore') || setDialogs(true)}><img className='rounded' src={appstore_icon} width={150} height={70} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mt-5 container">
                        <div className="d-flex justify-content-between justify-items-center">
                            <div><h4 className="Heading_1 m-0">Top Sales</h4></div>
                            <div><p className="m-0 Heading_1_suport">See more <i className="pi pi-angle-right" style={{'fontSize': '14px'}}></i></p></div>
                        </div>
                        <div className="row scrollbar_hidden pt-4">
                            {productdata.slice(0,10).map(items => {
                                return (
                                    <div className="col-sm-3 p-0">
                                        <Item_Card card_format='small' Product_list_data={items} />
                                    </div>
                                )
                            })}
                        </div>
                    </div> */}
                    {/* <div className="my-4">
                        <img src={banner1} className="banner_image"></img>
                    </div> */}
                </div>
            }
        </section>
    )
}

export default Home;