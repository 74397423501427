import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';

import './Blog.css';
import Sample from '../../Assets/logo.png';
import { GLOBAL_DOMAIN, IMG_GLOBAL, Api_Headers } from '../../Config/Config';

export default function Blog() {
    const [blog, setBlog] = useState('');
    const [blogData, setBData] = useState(null);

    const getText = (html) => {
        // console.log('text started: ', html[0]);
        if (html[0] === '"') {
            return parse(JSON.parse(html));
        } else {
            return parse(html);
        }
    };

    const submitDate = (val) => {
        let date = new Date(val);
        let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let month = monthArr[date.getMonth()];
        let year = date.getFullYear();

        return day + '-' + month + '-' + year;
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/blogs?type=list&limit=200&endUsers=true',
        }).then((res) => {
            setBData(res.data.rows);
            // console.log(res.data.rows);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <div className='my-4'>
            {blog === '' && <Container >
                <Row>
                    {blogData !== null && blogData.map((item) => {
                        return (
                            item.IS_PUBLISHED == 1 && <Col xs={12} md={{ span: 3, offset: 0 }}>
                                <Card
                                    title={<h5 className='text-center'>
                                        {item.TITLE}
                                    </h5>}
                                    footer={<p className='text-end m-0 fs-12'>
                                        Published on - <span><b>{submitDate(item.PUBLISHED_AT)}</b></span>
                                    </p>}
                                    header={<div>
                                        {item.MEDIA_PATH !== null ?
                                            <img src={IMG_GLOBAL + item.MEDIA_PATH} className='card-img' /> :
                                            <img src={Sample} className='card-img' />
                                        }
                                    </div>} className='cards'
                                    onClick={() => setBlog(item)}
                                >
                                    <span className='text'>
                                        {getText(item.CONTENT)}
                                    </span>
                                </Card>
                            </Col>)
                    })}
                </Row>
            </Container>}

            {blog !== '' && <Container>
                <h5 className='page-head'>
                    <div className='' onClick={() => setBlog('')}>
                        <i 
                            className='pi pi-arrow-left clr' onClick={() => setBlog('')} 
                        />
                    </div>
                    <div className='mx-2'>
                        {blog.TITLE}
                    </div>
                </h5>

                <div className='page-content'>
                    <div className='d-flex justify-content-center'>
                        {blog.MEDIA_PATH && <img src={IMG_GLOBAL + blog.MEDIA_PATH} className='head-img' />}
                    </div>

                    <div className='mt-3'>{getText(blog.CONTENT)}</div>
                </div>
           </Container>}
        </div>
    );
};