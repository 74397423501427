import React, { useEffect, useState, useRef, useCallback } from "react";
import { Skeleton } from 'primereact/skeleton';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import image from '../../Assets/bg1.png';
import offer_tag from '../../Assets/Atum_Icons/Offer_tag.svg'
import { GLOBAL_DOMAIN, IMG_GLOBAL,Api_Headers } from "../../Config/Config";
import './Item_Card.css';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import Login from "../../Pages/Login/Login";

const Item_Card = (props) => {

    const history = useHistory();
    const location = useLocation();
    const Card_format = props.card_format;
    const getproduct_data = props.Product_list_data;
    const toast = useRef(null);
    const [user_data, setuserdata] = useState({});
    const [isSkeleton, setisSkeleton] = useState(true);
    const [isSkeletonloop, setisSkeletonloop] = useState([1]);
    const [login_flag, setlogin_flag] = useState(false);

    const showtoast = () => {
        toast.current.show({ severity: 'success', summary: 'Successfully added to cart', life: 3000 });
    }
    useEffect(() => {
        // console.log(Card_format)
        setuserdata(JSON.parse(window.localStorage.getItem('User_data')));
        Card_format != '' ? setisSkeleton(false) : setisSkeleton(true);
    }, []);

    const AddtoCart = useCallback((item) => {
        if (!user_data) {
            setlogin_flag(true);
        } else {
            axios({
                method: 'POST',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/usercart/',
                data: {
                    "USER_ID": user_data.data.userData.ID,
                    "PRODUCT_ID": item.ID,
                    "QUANTITY": 1,
                    "PRODUCT_ATTRIBUTE_ID": 0
                },
            }).then((res) => {
                // console.log('CART Dataaa :', res.data);
                showtoast();
            }).catch((err) => {
                // console.log(err);
            });
        }
    }
    )
    const GotoProductsView = (e) => {
        // console.log(e);
        // localStorage.setItem('ProductID', e.ID);
        history.push('/product/' + e.PRODUCT_NAME, { Product_ID: e.ID });
    }
    const header = (
        <div>
            <img alt="Card" onClick={() => GotoProductsView(getproduct_data)} src={IMG_GLOBAL + getproduct_data.IMAGE_PATH1} width={171} height={148} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
        </div>
    );
    const footer = (
        <div className="d-flex justify-content-between align-items-center" style={{height:'40px'}}>
            <div className="d-flex align-items-center">
                {/* <h5 className="m-0">₹{getproduct_data.DISCOUNT_PRICE}</h5> */}
                {(parseFloat(getproduct_data.PRICE) - parseFloat(getproduct_data.DISCOUNT_PRICE) > 0) ?
                    <React.Fragment>
                        <div>
                        <h5 className="m-0 fs-16">₹{getproduct_data.DISCOUNT_PRICE}</h5>
                        <p className="m-0" style={{ fontSize: '16px', color: '#bec9c5' }}><del>{getproduct_data.PRICE}</del></p>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div>
                        <h5 className="m-0 fs-16">₹{getproduct_data.PRICE}</h5>
                        </div>
                    </React.Fragment>
                }
            </div>
            <div className="Product_Card_Btn">
                <Button label="Add" onClick={() => AddtoCart(getproduct_data)} className="p-button-outlined" />
            </div>
        </div>
    );

    const flag_disable = useCallback(() => {
        window.location.reload();
        setlogin_flag(false);
    }
    )

    return (
        <section className="">
            <Dialog visible={login_flag} className="dailog_box_responsive" onHide={() => setlogin_flag(false)}><Login flag_Disable={flag_disable}/></Dialog>

            <Toast ref={toast} />
            {/* <div className=" Product_Card">
             {Card_format == 'small' ?     */}
            {isSkeleton ?
                <Row>
                    {isSkeletonloop.map((index) => {
                        return (
                            <Col sm={6} key={index}>
                                <div className='m-3'><Skeleton size="200px" /></div>
                            </Col>
                        )
                    })}
                </Row> :
                <React.Fragment>
                    <div className={Card_format == 'carousel' ? 'w-280' : ''}>
                        <div className="Product_Card">
                            {Card_format != 'carousel' && parseFloat(getproduct_data.DISCOUNT_VALUE) > 0 &&
                                <React.Fragment>
                                    {getproduct_data.DISCOUNT_TYPE == "PERCENTAGE" ?
                                        <div className="card_offer_tag">
                                            <p className="m-0 center">{parseFloat(getproduct_data.DISCOUNT_VALUE).toFixed(0)} % Off</p>
                                        </div>
                                        :
                                        <div className="card_offer_tag">
                                            <p className="m-0 center">₹{parseFloat(getproduct_data.DISCOUNT_VALUE).toFixed(0)} Off</p>
                                        </div>
                                    }
                                </React.Fragment>
                             }
                            <Card footer={footer} header={header} className="cursor_poniter">
                                <h5 className="text_Warp" style={{ fontSize: '14px' }}>{getproduct_data.PRODUCT_NAME}</h5>
                                {/* <p style={{fontSize:'14px', color:'#bec9c5'}}>Qty: {getproduct_data.} ml</p> */}
                            </Card>
                        </div>
                    </div>
                </React.Fragment>
            }
            {/* :
            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 Product_Card">
                <div className="card_offer_tag">
                    <p className="m-0 center text-center">10% <br/>Off</p>
                </div>
            <Card  footer={footer} header={header}>
                <h5 style={{fontSize:'16px'}}>Blossom crochet Large</h5>
                <p style={{fontSize:'14px', color:'#bec9c5'}}>Qty: 250 ml</p>
            </Card>
            </div>
             }
            </div> */}
        </section>
    )
}

export default Item_Card