import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import Item_Card from '../Cards/Item_Card';

const Product_Carousel = (props) => {

    const Get_Product_Data = props.Carousel_data;

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5,
            numScroll: 1
        },
        {
            breakpoint: '600px',
            numVisible: 4,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    const productTemplate = (items) => {
        return (
            <div>
                <Item_Card card_format='carousel' Product_list_data={items} />
            </div>
        )
    }
    return (
        <section className='mx-5'>
            <Carousel value={Get_Product_Data} numVisible={5} showIndicators={false} numScroll={1} responsiveOptions={responsiveOptions}
                itemTemplate={productTemplate} />
        </section>
    )
}

export default Product_Carousel