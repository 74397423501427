import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { GLOBAL_DOMAIN, IMG_GLOBAL, Payment_Gateway, Api_Headers } from '../../Config/Config';
import axios from 'axios';
import { Card } from 'primereact/card';
import '../../App.css';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { RadioButton } from 'primereact/radiobutton';
import { useHistory, Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';

const Orderlist = () => {

    const history = useHistory();
    const [userorders, setUserOders] = useState([]);
    const [Orderdetails, setOrderdetails] = useState([]);
    const [Orderpaymentdetails, setOrderpaymentdetails] = useState({});
    const [orderHistory, setorderHistory] = useState([]);
    const [Dailogflag, setDailogflag] = useState(false);
    const [Prebook_Amount, setPrebook_Amount] = useState();
    const [PayNow_flag, setPayNow_flag] = useState(false);
    const [Total_Price, setTotal_Price] = useState();
    const [Paymentmethod, setpaymentmethod] = useState('ONLINE');
    const [paymentStatus, setpayment_status] = useState('SUCCESS');
    const [User_Info, setuserinfo] = useState({});
    const [Total_Order_Amount, setTotal_Order_Amount] = useState(0);
    const [Total_Extra_Charges, setTotal_Extra_Charges] = useState(0);
    const [OriginalAmount, setOriginalAmount] = useState(0)
    const [selectedorder, setselectedorder] = useState({});
    const toast = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
        // getUserInfo();
        getUserOders();
    }, []);

    const getUserInfo = (user_data) => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/endusers/' + user_data.USER_ID,
        }).then((res) => {
            setuserinfo(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }


    const orderDate = (dt) => {
        let odate = new Date(dt), monArr = ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        let day = odate.getDate(), month = odate.getMonth(), year = odate.getFullYear();

        return day > 10 ? day + '/' + monArr[month] + '/' + year : '0' + day + '/' + monArr[month] + '/' + year;
    };

    const getUserOders = () => {
        const user_data = JSON.parse(window.localStorage.getItem('User_data'));

        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/userorders?type=list&limit=100&user_id=' + user_data.data.userData.ID,
        }).then((res) => {
            setUserOders(res.data.rows);
            // setTotal_Extra_Charges(res.data.rows.TOTAL_EXTRA_CHARGES);
            // console.log('user orders', res.data.rows);
        }).catch((err) => {
            console.log(err);
        });
    };

    const getOrderView = (order) => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/userorders/' + order.ID,
        }).then((res) => {
            console.log(res.data)
            setorderHistory(res.data);
            setDailogflag(true);
        }).catch((err) => {
            console.log(err);
        });
    };
    const showInMapClicked = (storepickup) => {
        window.open("https://maps.google.com?q=" + storepickup.LATITUDE + "," + storepickup.LONGITUDE);
    };
    const getOrderDetails = (order) => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/orderItems?type=list&limit=100&orderTrackerId=' + order.ORDER_TRACKER_ID,
        }).then((res) => {
            console.log(res.data)
            setOrderpaymentdetails(res.data);
            setOrderdetails(res.data.rows);
            setDailogflag(true);
            setTotal_Order_Amount(order.ORDER_TOTAL_AMOUNT);
            let totaloriginalprice = 0
            res.data.rows.map(items => (
                totaloriginalprice = totaloriginalprice + parseFloat(items.ORIGINAL_PRICE * items.QUANTITY)
            ))
            setOriginalAmount(totaloriginalprice);
            if (order.PAYMENT_MODE === 'COD' || order.STATUS === "PREBOOK") {
                //     if(Prebook_Amount > 0){
                setTotal_Price(res.data.ORDER_TOTAL_AMOUNT - order.PREBOOK_AMOUNT);
                // }else{
                // setTotal_Price(order.ORDER_TOTAL_AMOUNT + res.data.TOTAL_EXTRA_CHARGES);
                // }
            } else {
                setTotal_Price(res.data.ORDER_TOTAL_AMOUNT);
            }
            // setTotal_Price(order.ORDER_TOTAL_AMOUNT);

            // console.log("total price", (res.data.TOTAL_EXTRA_CHARGES))
            if (order.PREBOOK_AMOUNT) {
                setPrebook_Amount(order.PREBOOK_AMOUNT);
            } else {
                setPrebook_Amount(0);
            }
            getUserInfo(order);
        })
    };

    const PayNow_btn = (orders) => {
        setPayNow_flag(true);
        getOrderDetails(orders);
        setselectedorder(orders);
    }

    const HideDialog = () => {
        setPayNow_flag(false);
        setDailogflag(false);
    }
    const PlaceOrder = () => {
        // console.log("PlaceOder", orderdetails);
        const Order_Item = {
            "ORDER_ID": selectedorder.ID,
            "ORDER_TRACKER_ID": "",
            "TRANSACTION_TOKEN": "",
            "TRANSACTION_ID": "",
            "PAYMENT_MODE": Paymentmethod,
            "PAYMENT_STATUS": paymentStatus,
            "USER_ID": selectedorder.USER_ID,
            "ORDER_TOTAL_AMOUNT": Total_Price,
            "STATUS": "ORDER_COMPLETE",
            "CREATED_BY": selectedorder.USER_ID,
            "UPDATED_BY": selectedorder.USER_ID
        };
        // const Shipping_Address = {
        //     MOBILE_NUMBER: predata.MOBILE_NUMBER,
        //     ADDRESS_LINE1: predata.ADDRESS_LINE1,
        //     ADDRESS_LINE2: predata.ADDRESS_LINE2,
        //     STATE: predata.STATE,
        //     CITY: predata.CITY,
        //     COUNTRY: predata.COUNTRY,
        //     PINCODE: predata.PINCODE,
        // }
        // let OrderData = {
        //     orderItems: Order_Item,
        //     shippingAddress: Shipping_Address,
        //     DELIVERY_TYPE: "CUSTOMER_ADDRESS",
        //     PAYMENT_STATUS: paymentStatus,
        //     PAYMENT_MODE: Paymentmethod,
        //     ORDER_TRACKER_ID: 0,
        //     ORDER_AMOUNT: CartData.DISCOUNT_PRICE,
        //     TRANSACTION_TOKEN: "",
        //     TRANSACTION_RESPONSE_TOKEN: "",
        //     STATUS: "PREBOOK",
        //     PREBOOK_AMOUNT: CartData.attributesInfo.PREBOOK_AMOUNT[0].ATTRIBUTE_VALUE,
        //     DELIVERY_CHARGES: 0,
        //     USER_ID: user_data.data.userData.ID,
        //     ACTION: "PREBOOK",
        //     PAYMENT_STATUS: "SUCCESS",

        //     // STORE_ID : SelectedstorepickupID
        // };



        if (Paymentmethod == 'ONLINE') {
            axios({
                method: 'POST',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/paymentgateway/generateWebToken',
                data: {
                    'ORDER_TOTAL_AMOUNT': Total_Price,
                    'USER_ID': selectedorder.USER_ID,
                }
            }).then((res) => {
                const checksumInfo = JSON.parse(res.data.paytmchecksum);
                var config = {
                    "root": "",
                    "style": {
                        "bodyBackgroundColor": "#fff",
                        "bodyColor": "#333",
                        "themeBackgroundColor": "#77cab9",
                        "themeColor": "#fff",
                        "headerBackgroundColor": "#77cab9",
                        "headerColor": "#fff",
                        "errorColor": "red",
                        "successColor": "green",
                        "card": {
                            "padding": "",
                            "backgroundColor": "",
                            "borderRadius": '0px',
                        }
                    },
                    "data": {
                        "orderId": res.data.data.orderInfo.ORDER_ID,
                        "token": res.data.data.txnToken,
                        "tokenType": "TXN_TOKEN",
                        "amount": res.data.data.orderInfo.ORDER_TOTAL_AMOUNT,
                    },
                    "payMode": {
                        "labels": {},
                        "filter": {
                            "exclude": [
                                "WALLET",
                                "BANK_MANDATE",
                                "BANK_TRANSFER",
                                "EMI",
                                "EMI_DC",
                                "MGV",
                                "LOGIN",
                                "PPBL",
                                "PDC",
                                "SC",
                                "BALANCE",
                            ]
                        },
                        "order": [
                            "NB",
                            "CARD",
                            "UPI",
                        ]
                    },
                    "website": "WEB",
                    "flow": "DEFAULT",
                    "merchant": {
                        "mid": Payment_Gateway.MERCHANT_ID,
                        'name': 'ATUM Life',
                        "redirect": false,
                    },
                    "handler": {
                        "transactionStatus": function transactionStatus(paymentStatus) {
                            window.Paytm.CheckoutJS.close();
                            Order_Item.ORDER_TRACKER_ID = res.data.data.orderInfo.ORDER_ID;
                            // Order_Item.PAYMENT_STATUS = paymentStatus.STATUS == 'TXN_SUCCESS' ? 'SUCCESS' : 'PENDING';
                            if(paymentStatus.STATUS == 'TXN_SUCCESS'){
                            Order_Item.PAYMENT_STATUS = 'SUCCESS';
                            axios({
                                method: 'POST',
                                headers: Api_Headers,
                                url: GLOBAL_DOMAIN + '/v1/orderhistory',
                                data: Order_Item,
                            }).then((res) => {
                                // console.log('order placed', res.data.data.data);
                                getUserOders();
                                HideDialog();
                                // history.push('/orderlist');
                                // history.push(`/order/${res.data.data.data.ID}`)
                            }).catch((err) => {
                                console.log(err);
                            });
                        }else{
                            showtoast()
                        }
                        },
                        "notifyMerchant": function notifyMerchant(eventName, data) {
                            window.Paytm.CheckoutJS.close();
                        },
                    },
                };

                if (window.Paytm && window.Paytm.CheckoutJS) {
                    window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                        window.Paytm.CheckoutJS.invoke();
                    }).catch(function onError(err) {
                        console.log("Error => ", err);
                    });
                };
            }).catch((err) => {
                console.log(err); window.Paytm.CheckoutJS.invoke();
            });
        } else if (Paymentmethod == 'ATUM_WALLET') {

            axios({
                method: 'POST',
                headers: Api_Headers,
                url: GLOBAL_DOMAIN + '/v1/orderhistory',
                data: Order_Item,
            }).then((res) => {
                HideDialog();
                getUserOders();
                // history.push('/orderlist');
            }).catch((err) => {
                console.log(err);
            })
        }
    };
    const navigate_orderdetails = (orders) => {
        history.push('/orderdetails', { Order_data: orders });
    }
    const dateFormate = (date_string) => {
        let date = new Date(Date.parse(date_string));
        // var date    = getDateTime(dateTimeString);
        var options = { dateStyle: "medium" };
        return date.toLocaleString("en-IN", options);
        // orderDate(date.toLocaleString());
    }
    const showtoast = () => {
        toast.current.show({ severity: 'error', summary: 'Payment Failure', life: 3000 });
    }
    return (
        <section className='container'>
            {/* <Header /> */}
            <Toast ref={toast} />
            <div>
                {/* <div className='mb-3'>
                    <h2>Order List</h2>
                </div> */}

                <div>
                    <Row>
                        {userorders.map(orders => {
                            const header = (
                                <h6 className='px-3 py-2 bg_color_head text-white'>Order ID: {orders.ORDER_TRACKER_ID}</h6>
                            );
                            return (
                                <Col md={12} className='mb-3' onClick={() => { navigate_orderdetails(orders) }}>
                                    <Card header={header}>
                                        <div className='px-2'>
                                            <Row className=''>
                                                <Col xs={12} md={4}> <p><b>Order Status:</b> <span>
                                                    {orders.STATUS === "ORDERED" && 'Order Placed'}
                                                    {orders.STATUS === "DELIVERED" && 'Delivered'}
                                                    {orders.STATUS === "DISPATCHED" && 'Dispatched'}
                                                    {orders.STATUS === "CANCELLED" && 'Cancelled'}
                                                    {orders.STATUS === "PREBOOK" && 'Prebooked'}
                                                </span></p></Col>
                                                <Col xs={12} md={5}>
                                                    <p><b>Payment Mode:</b> <span>{orders.PAYMENT_MODE === 'COD' ? 'Cash On Delivery' : orders.PAYMENT_MODE === 'ONLINE' ? 'Online' : 'ATUM Wallet'}</span></p>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <p><b>Amount:</b> <span>₹ {parseFloat(orders.ORDER_TOTAL_AMOUNT).toFixed(2)}</span></p>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <p><b>Ordered Date:</b> <span>{dateFormate(orders.CREATED_AT)}</span></p>
                                                </Col>
                                                {orders.PREBOOK_AMOUNT > 0 &&
                                                    <React.Fragment>
                                                        <Col xs={12} md={5}>
                                                            <p><b>Prebook Amount:</b> <span>₹ {parseFloat(orders.PREBOOK_AMOUNT).toFixed(2)}</span></p>
                                                        </Col>
                                                        {/* <Col xs={12} md={3}>
                                                     <p><b>Payable Amount:</b> <span>₹ {(orders.ORDER_TOTAL_AMOUNT + orders.TOTAL_EXTRA_CHARGES) - orders.PREBOOK_AMOUNT}</span></p>
                                                 </Col> */}
                                                    </React.Fragment>
                                                }
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={9}>
                                                    {orders.DELIVERY_TYPE == "CUSTOMER_ADDRESS" ? <p><b>Delivery Address:</b> <span>{orders.DELIVERY_ADDRESS}</span></p>
                                                        : <p><b>Customer Contact Number:</b> <span>{orders.DELIVERY_ADDRESS}</span></p>}
                                                </Col>
                                                {(orders.PAYMENT_MODE === "COD" || orders.STATUS === "PREBOOK") &&
                                                    <Col xs={12} md={3} className="d-flex">
                                                        <Button type='submit' className=' mx-2 fs-7 btn_p text-end height_35' onClick={() => getOrderDetails(orders)} variant="success" style={{ borderRadius: '25px' }}>View Details</Button>
                                                        <Button type='submit' className='mx-2 fs-7 btn_p text-end height_35' onClick={() => PayNow_btn(orders)} variant="success" style={{ borderRadius: '25px' }}>Pay Now</Button>
                                                    </Col>
                                                }
                                            </Row>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </div>

            <Dialog header={<p>Order Details - <span className='color_head'>{orderHistory?.ORDER_TRACKER_ID} </span></p>} className='Dialog_Box' visible={PayNow_flag ? Dailogflag && PayNow_flag : Dailogflag} onHide={() => HideDialog()}>
                <div>
                    {/* {orderHistory &&
                      <p><b>Order ID</b> : <b className='color_head'>{orderHistory?.ORDER_TRACKER_ID}</b></p>
                    } */}
                    {orderHistory && orderHistory.STORE_ID > 0 &&
                        <div>
                            <div className='container'>
                                {/* <Divider/> */}
                                <div className='d-flex justify-content-between'>
                                    <h5>Store Details</h5>
                                    <Button className=' btn_p fs-10' onClick={() => showInMapClicked(orderHistory)}>Get Location<i className="pi pi-map-marker px-1 fs-10 color_head text-white"></i></Button>
                                </div>
                                <p><b>Address: </b>{orderHistory.STORE_ADDRESS}</p>
                                <p><b>Contact Number: </b>{orderHistory.STORE_CONTACT_NUMBER}</p>
                            </div>
                            <Divider />
                        </div>
                    }
                    {Orderdetails.map(orderdetails => {
                        return (
                            <div>
                                <div className='my-3'>
                                    <Row>
                                        <Col md={4}>
                                            <img className="w-100"
                                                height={'150px'}
                                                src={IMG_GLOBAL + (orderdetails.isspecialAttributesAvailable ? orderdetails.specialAttributesInfo.IMAGE_PATH : orderdetails.IMAGE_PATH)}
                                                onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                            ></img>
                                        </Col>
                                        <Col md={8}>
                                            <div>
                                                <p><b>{orderdetails.PRODUCT_NAME}</b></p>
                                                {/* </div>
                                        <div className='d-flex justify-content-betwen'> */}
                                                <div className='d-flex justify-content-between'>
                                                    <div>
                                                        {parseFloat(orderdetails.ORIGINAL_PRICE) - parseFloat(orderdetails.PRICE) == 0 ?
                                                            <p><b>Price :</b> &#8377; {parseFloat(orderdetails.PRICE).toFixed(2)}</p> :
                                                            <p><b>Price :</b>&#8377;{parseFloat(orderdetails.PRICE).toFixed(2)}<del className="color_red mx-1">&#8377;{parseFloat(orderdetails.ORIGINAL_PRICE).toFixed(2)}</del></p>
                                                        }
                                                    </div>
                                                    {Prebook_Amount > 0 &&
                                                        <div>
                                                            <p><b>Prebooked :</b> &#8377; {parseFloat(Prebook_Amount).toFixed(2)}</p>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <div>
                                                        <p><b>Quantity :</b> {orderdetails.QUANTITY}</p>
                                                    </div>
                                                    <div>
                                                        {orderdetails.IS_COLOR_VARIANT == 1 &&
                                                            <div>
                                                                <div className='Attribute_color_card text-center p-1 d-flex'>
                                                                    <p className='mr-2'>Color:</p>
                                                                    <p className='Attribute_color m-0 d-inline-flex' style={{ backgroundColor: orderdetails.PRODUCT_COLOR }}></p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Divider />

                                </div>
                                {orderdetails.isattributesAvailable ?
                                    <div>
                                        <div className='container'>
                                            {/* <h5>Extra Charges</h5>
                                            <div className='d-flex justify-content-between'>
                                                <div><p>Order Price</p></div>
                                                <div><p>₹ {orderdetails.PRICE}</p></div>
                                            </div>
                                            {Prebook_Amount > 0 &&
                                                <div className='d-flex justify-content-between'>
                                                    <div><p>Prebook Amount:</p></div>
                                                    <div><p className='color_red'>(-) ₹ {Prebook_Amount}</p></div>
                                                </div>
                                            } */}
                                            {orderdetails?.EXTRA_CHARGES_INFO.ROWS.map(item => {
                                                return (
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <p>{item.ATTRIBUTE_DISPLAY_NAME}
                                                                {item.MEASUREMENT_NAME == 'PERCENTAGE' && item.ATTRIBUTE_NAME == 'GST' &&
                                                                    <span>({parseFloat(item.MEASUREMENT_VALUE).toFixed(2)}%)</span>
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            {/* <span className='mx-1'>:</span> */}
                                                            {item.MEASUREMENT_NAME == 'AMOUNT' &&
                                                                <span className='mx-1'>&#8377;{parseFloat(item.MEASUREMENT_VALUE).toFixed(2)}</span>
                                                            }
                                                            {item.MEASUREMENT_NAME == 'PERCENTAGE' && item.ATTRIBUTE_NAME != 'GST' &&
                                                                <span className='mx-1'>{parseFloat(item.MEASUREMENT_VALUE).toFixed(2)} %</span>
                                                            }
                                                            {item.MEASUREMENT_NAME == 'PERCENTAGE' && item.ATTRIBUTE_NAME == 'GST' &&
                                                                <span>&#8377;{((parseFloat(orderdetails?.PRICE) * parseFloat(item.MEASUREMENT_VALUE)) / 100).toFixed(2)} </span>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                        <Divider />
                                    </div> :
                                    <div>
                                    </div>
                                }
                            </div>
                        )
                    })
                    }
                    {orderHistory.ORDER_HISTORY && orderHistory.ORDER_HISTORY.map(orderhistory => {
                        return (
                            <div >
                                <div className='container'>
                                    <h5>
                                        {orderhistory.STATUS == 'PREBOOK' && "Prebooking"}
                                        {orderhistory.STATUS == 'ORDER_COMPLETE' && "Order Complete"}
                                    </h5>
                                    <Row>
                                        <Col xs={6}><b>Order Date: </b>{orderhistory.UPDATED_AT}</Col>
                                        <Col xs={6}><b>Payment Mode: </b>{orderhistory.PAYMENT_MODE}</Col>
                                        <Col xs={6}><b>Payment Status: </b>{orderhistory.PAYMENT_STATUS}</Col>
                                        <Col xs={6}><b>Order Amount: </b>{orderhistory.ORDER_TOTAL_AMOUNT}</Col>
                                    </Row>
                                </div>
                                <Divider />
                            </div>
                        )
                    })
                    }
                    {parseFloat(orderHistory.COUPON_DISCOUNTED_AMOUNT) > 0 &&
                        <div className='container'>
                            <div>
                                {/* <h5>
                                    Coupon Details
                                </h5> */}
                                <Row>
                                    <Col xs={6}><b>Coupon Code: </b>{orderHistory.COUPON_CODE}</Col>
                                    <Col xs={6}><b>Coupon Value: </b>{orderHistory.COUPON_VALUE_TYPE == "PERCENTAGE" ? <span>{orderHistory.COUPON_VALUE} % </span> : <span>&#8377; {orderHistory.COUPON_VALUE}</span>}</Col>
                                </Row>
                            </div>
                            <Divider />
                            {/* <div className='d-flex justify-content-between'>
                                <div><p>Coupon Discount </p></div>
                                <div><p className='color_red'>(-) ₹ {orderHistory.COUPON_DISCOUNTED_AMOUNT}</p></div>
                            </div>
                            <Divider /> */}
                        </div>
                    }
                    {Orderpaymentdetails &&
                        <div>
                            <div className='container'>
                                <h5>Payment Details</h5>
                                <div className='d-flex justify-content-between'>
                                    <div><p>Order Price</p></div>
                                    <div><p>₹ {parseFloat(OriginalAmount).toFixed(2)}</p></div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div><p>Total Discount Amount:</p></div>
                                    <div><p className='color_red'>(-) ₹ {parseFloat(Orderpaymentdetails?.TOTAL_DISCOUNTED_AMOUNT).toFixed(2)}</p></div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div><p>Total Extra Charges</p></div>
                                    <div><p>₹ {parseFloat(Orderpaymentdetails.TOTAL_EXTRA_CHARGES).toFixed(2)}</p></div>
                                </div>
                                {parseFloat(orderHistory.COUPON_DISCOUNTED_AMOUNT) > 0 &&
                                    <div className='d-flex justify-content-between'>
                                        <div><p>Coupon Discount </p></div>
                                        <div><p className='color_red'>(-) ₹ {parseFloat(orderHistory.COUPON_DISCOUNTED_AMOUNT).toFixed(2)}</p></div>
                                    </div>}
                            </div>
                            <Divider />
                        </div>
                    }
                    {/* {Orderdetails &&  */}
                    <div className=''>
                        {/* <Divider/> */}
                        <div className='d-flex justify-content-between container'>
                            <div><p className='m-0'><b>Total Price:</b></p></div>
                            {(orderHistory.COUPON_VALUE == '' || orderHistory.COUPON_VALUE == null) ?
                            <div><p className='color_head m-0'><b>₹ {parseFloat(Total_Price).toFixed(2)}</b></p></div> :
                            <div><p className='color_head m-0'><b>₹ {(parseFloat(Total_Price).toFixed(2)) - parseFloat(orderHistory.COUPON_VALUE).toFixed(2)}</b></p></div>
                            }
                        </div>
                        {/* } */}

                        <Divider />
                        {PayNow_flag &&
                            <div>
                                <Card className='mt-2 card_prebooking'>
                                    {/* <div className="field-radiobutton my-3">
                                    <RadioButton inputId="COD" name="Paymentmethod" value="COD" disabled={true}
                                        onChange={(e) => setpaymentmethod(e.value) && setpayment_status('PENDING')}
                                        checked={Paymentmethod === 'COD'} />
                                    <label htmlFor="COD" className='mx-3'>Cash On Delivery</label>
                                </div> */}
                                    <Row>
                                        <Col sm={8}>
                                            <div className='Paymentmethod_card'>
                                                <div className="field-radiobutton my-3">
                                                    <RadioButton inputId="ATUM_WALLET" name="Paymentmethod" value="ATUM_WALLET"
                                                        onChange={(e) => setpaymentmethod(e.value) && setpayment_status('PENDING')}
                                                        checked={Paymentmethod === 'ATUM_WALLET'} />
                                                    <label htmlFor="ATUM_WALLET" className='mx-3'>Atum Wallet
                                                        <b>({parseFloat(User_Info.TOTAL_WALLET_AMOUNT).toFixed(2)})</b>
                                                    </label>
                                                </div>
                                                <div className="field-radiobutton my-3">
                                                    <RadioButton inputId="ONLINE" name="Paymentmethod" value="ONLINE"
                                                        onChange={(e) => setpaymentmethod(e.value) && setpayment_status('SUCCESS')}
                                                        checked={Paymentmethod === 'ONLINE'} />
                                                    <label htmlFor="ONLINE" className='mx-3'>Pay Now</label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            <Button type='submit' className='px-5 my-2 btn_p text-end' onClick={() => PlaceOrder()} variant="success" disabled={Paymentmethod === 'ATUM_WALLET' && parseFloat(User_Info.TOTAL_WALLET_AMOUNT) < parseFloat(Total_Price)} style={{ borderRadius: '25px' }}>Pay Now</Button>
                                        </Col>
                                    </Row>
                                    {Paymentmethod == 'ATUM_WALLET' && parseInt(User_Info.TOTAL_WALLET_AMOUNT) <= Total_Price ?
                                        <div>
                                            <span className='' style={{ color: 'red' }}><b>{parseFloat(User_Info.TOTAL_WALLET_AMOUNT).toFixed(2)}</b> Please upgrade the amount from <Link to='/wallet'>Wallet</Link></span>
                                        </div> : ''
                                    }
                                </Card>
                            </div>
                        }
                    </div>
                    {/* } */}
                </div>
            </Dialog>
        </section>
    )
}

export default Orderlist; 