import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAF_bsSYp0FdYOr3Vg6irC7I_htXP5liDo",
    authDomain: "atumlifee-15ab1.firebaseapp.com",
    projectId: "atumlifee-15ab1",
    storageBucket: "atumlifee-15ab1.appspot.com",
    messagingSenderId: "549932056453",
    appId: "1:549932056453:web:80dfef44eb10811b00ceec",
    measurementId: "G-F52CX7ZE03"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);