import { PAYTM_SCRIPT_ID, PAYTM_MERCHANT_URL } from '../Config/Config';

const tpi_map = new Map();

export const initTPLset = () => {
    tpi_map.set(PAYTM_SCRIPT_ID,PAYTM_MERCHANT_URL);
}

export const initializeTPL = (scriptID) => {
    const existingScript = document.getElementById(scriptID);

    if (!existingScript) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = scriptID;
        script.setAttribute("src", tpi_map.get(scriptID));
        script.setAttribute("crossOrigin", "anonymous");
        document.head.appendChild(script);
    }
}
