import logo from './logo.svg';
import React, {useEffect } from 'react';
import './App.css';
import routes from './Routes/route';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Home from './Pages/Home/Home';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import Layout from './Components/Layout';
import Login from './Pages/Login/Login';
import Signup from './Pages/Signup/signup';
import Errorpage from './Components/Errorpage';
import ReactGA from 'react-ga';
import { initTPLset } from './Components/Tpl';

function App() {

  // const TRACKING_ID = "UA-230801710-1"; // YOUR_OWN_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    initTPLset();
  }, []);
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path={'/login'} component={Login} />
          <Route path={'/signup'} component={Signup} />
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          {/* <Route path={'/error'} component={Errorpage}/> */}
          <Layout>
            <Route path={'/'} render={(props) => {
              return (
                <Switch>
                  {routes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      component={route.component}
                    />
                  ))}
                  <Redirect from="/" to="/home" />
                </Switch>
              )
            }} />
          </Layout>
        </Switch>
      </Router>
    </div>
  );
}

export default App;