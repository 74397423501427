import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { GLOBAL_DOMAIN, IMG_GLOBAL, Payment_Gateway, Api_Headers } from '../../Config/Config';
import { Card } from 'primereact/card';
import map_line_icon from '../../Assets/Atum_Icons/road-map-line.svg';
import location_icon from '../../Assets/Atum_Icons/map-pin-2-line.svg';
import Phone_Icon from '../../Assets/Atum_Icons/phone-line.svg';
import { GMap } from 'primereact/gmap';
import { Button } from 'primereact/button';
import Atumlife_icon from '../../Assets/logo.svg';
import Map_location_marker_icon from '../../Assets/Atum_Icons/Map_location_marker_icon.svg';
// import { useLoadScript } from "@react-google-maps/api";

const Stores = () => {
    const [StoreData, setStoreData] = useState([]);
    const google = window.google = window.google ? window.google : {}
    const [googleMapsReady, setGoogleMapsReady] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [markerTitle, setMarkerTitle] = useState('');
    const [draggableMarker, setDraggableMarker] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [overlays, setOverlays] = useState([]);
    const toast = useRef(null);
    const infoWindow = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
        getstorepickupdata();
        // console.log(overlays)
    }, []);

    const getstorepickupdata = () => {
        axios({
            method: 'get',
            headers: Api_Headers,
            url: GLOBAL_DOMAIN + '/v1/stores?type=list&limit=20',
        }).then((res) => {
            setStoreData(res.data.rows);
            var tempOverlays = [];
            res.data.rows.map((items, index) => {
                tempOverlays.push(new google.maps.Marker({ position: { lat: parseFloat(items?.LATITUDE), lng: parseFloat(items?.LONGITUDE)}, title: items.CITY, icon:`${Map_location_marker_icon}`}));
            });
            setOverlays((oldTempOverlays)=>{ return tempOverlays; });
            setGoogleMapsReady(true);
        }).catch((err) => { console.log(err); });
    }
   
    const showInMapClicked = (storepickup) => {
        window.open("https://maps.google.com?q=" + storepickup.LATITUDE + "," + storepickup.LONGITUDE);
    };

    const options = {
        center: { lat: 22.47847216729526, lng: 78.10261345243794 },
        zoom: 4.5
    };

    return (
        <section className='container'>
            <div>
                <div>
                    <h4 className='m-0'>Our Stores</h4>
                    <small>Explore our physical store for exclusive, limited-time offers and special discounts.</small>
                </div>
                <div className='my-3'>
                    <Row>
                        <Col xs={12} md={6}>
                            {
                                googleMapsReady && (
                                    <div>
                                        <GMap overlays={overlays} options={options} style={{ borderRadius: '20px', width: '100%', minHeight: '520px' }} />
                                    </div>
                                )
                            }
                            {/* <div>
                                <iframe style={{ borderRadius: '20px' }} src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3806.189214975798!2d78.47738067516639!3d17.450655483447346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDI3JzAyLjQiTiA3OMKwMjgnNDcuOCJF!5e0!3m2!1sen!2sin!4v1704193571560!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div> */}
                        </Col>
                        <Col xs={12} md={6}>
                            {StoreData && StoreData?.map((store, index) => {
                                return (
                                    <Card key={index} className='mb-3 bg-color-grey rounded border border-1 box-shadow-none'>
                                        <Row>
                                            <Col md={4} xs={12}>
                                                <div className='position-relative'>
                                                    <div>
                                                        <img src={Atumlife_icon} className='bg-white' width="100%" height={160} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                                                    </div>
                                                    <div className='position-absolute top-90 start-50 translate-middle w-90 text-center'>
                                                        <div className='bg-light rounded'>
                                                            <small className='m-0 fs-12'>09:00 AM - 10:00 PM</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={8} xs={12} className='position-relative'>
                                                <div style={{ height: '160px' }}>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='lh-1'>
                                                            <h6 className='m-0'>Atum Life</h6>
                                                            <h5>{store.CITY}</h5>
                                                        </div>
                                                        <div className='cursor_poniter' onClick={() => { showInMapClicked(store) }}>
                                                            <img src={map_line_icon} />
                                                            <small className='ms-1 color_blue'>Get Directions</small>
                                                        </div>
                                                    </div>
                                                    <div className='position-absolute top-40'>
                                                        <img src={location_icon} />
                                                        <small className='ms-1 pe-3 lh-1'>
                                                            <span className='lh-1'>{store.ADDRESS_LINE1}, {store.ADDRESS_LINE2}</span>
                                                        </small>
                                                    </div>
                                                    <div className='position-absolute bottom-0'>
                                                        <img src={Phone_Icon} />
                                                        <small className='ms-1'>
                                                            {store.CONTACT_NUMBER}
                                                        </small>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                )
                            })}
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    )
}

export default Stores